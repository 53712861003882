import auth from '../middleware/auth'
import is_module_enabled from '../middleware/is_module_enabled'

export const payment = {
    path: 'payment',
    name: 'payment',
    component: () =>
        import ('@/modules/Payment/Payment.vue'),
    meta: {
        middleware: [auth, is_module_enabled]
    }
}

export const payment_to = {
    path: 'payment-to',
    component: () =>
        import ('@/modules/Payment/PaymentTo.vue'),
    meta: {
        middleware: [auth]
    },
    children: [{
            path: 'orders/:ids',
            name: 'payment-to-orders',
            component: () =>
                import ('@/modules/Payment/PaymentTos/PaymentToOrders.vue')
        },
        {
            path: 'invoice/:id',
            name: 'payment-to-invoice',
            component: () =>
                import ('@/modules/Payment/PaymentTos/PaymentToInvoice.vue')
        },
        {
            path: 'success/:type',
            name: 'payment-to-success',
            component: () =>
                import ('@/modules/Payment/PaymentTos/PaymentSuccess.vue')
        }
    ]
}