export const is_screen_utils = {
    computed: { 
        smAndDown() {
            return this.$vuetify.breakpoint.smAndDown
        },
        mdAndDown() {
            return this.$vuetify.breakpoint.mdAndDown
        },
        mdAndUp() {
            return this.$vuetify.breakpoint.mdAndUp
        },
        lgAndUp() {
            return this.$vuetify.breakpoint.lgAndUp
        },
        lgAndDown() {
            return this.$vuetify.breakpoint.lgAndDown
        }
    }
}
