<template>
  <v-sheet
    tile
    v-if="item"
    :min-height="minHeight"
    :max-height="maxHeight"
    color="transparent"
    class="overflow-visible d-flex align-center fullwidth justify-center"
    v-bind="$attrs"
  >
    <slot name="default"></slot>
    <VideoPlayer
      :style="`height:${
        normalizeHeight(height) || normalizeHeight(minHeight)
      };width:100%;`"
      v-if="is_video(item)"
      :stream-url="item.url_inline"
      :type="item.filetype"
    ></VideoPlayer>
    <AudioPlayer
      :style="`height:${
        normalizeHeight(height) || normalizeHeight(minHeight)
      };`"
      v-else-if="is_audio(item)"
      :stream-url="item.url_inline"
    ></AudioPlayer>
    <v-img
      @click.stop="attachmentIsClicked"
      v-else-if="is_gif(item) && animated"
      :lazy-src="settings.loader"
      :height="height || minHeight"
      contain
      width="auto"
      :src="getImagePreview(item, false)"
    ></v-img>
    <v-img
      @click.stop="attachmentIsClicked"
      v-else-if="is_image(item)"
      :lazy-src="settings.loader"
      :height="height || minHeight"
      contain
      width="auto"
      :src="getImagePreview(item, preferThumb)"
    ></v-img>
    <WebPlayer
      :style="`height:${
        normalizeHeight(height) || normalizeHeight(minHeight)
      };`"
      v-else-if="
        is_link(item) && (isYouTube(item.link_url) || isVimeo(item.link_url))
      "
      :stream-url="item.link_url"
    ></WebPlayer>
    <v-img
      @click.stop="attachmentIsClicked"
      v-else-if="is_link(item)"
      :lazy-src="settings.loader"
      :height="height || minHeight"
      contain
      :src="getLinkPreview(item)"
    ></v-img>
    <v-img
      @click.stop="attachmentIsClicked"
      v-else-if="is_document(item)"
      :lazy-src="settings.loader"
      :height="height || minHeight"
      contain
      :src="getDocumentPreview(item)"
    ></v-img>
    <v-img
      v-else
      @click.stop="attachmentIsClicked"
      :lazy-src="settings.loader"
      :height="height || minHeight"
      contain
      :src="getOthersPreview(item)"
    ></v-img>
  </v-sheet>
</template>

<script>
import VideoPlayer from './Players/VideoPlayer.vue'
import AudioPlayer from './Players/AudioPlayer.vue'
import WebPlayer from './Players/WebPlayer.vue'
export default {
  components: { VideoPlayer, AudioPlayer, WebPlayer },
  inheritAttrs: false,
  name: 'Attachment',
  props: {
    item: Object,
    height: { type: [String, Number], default: null },
    minHeight: { type: [String, Number], default: null },
    maxHeight: { type: [String, Number], default: null },
    preferThumb: { type: Boolean, default: false },
    animated: { type: Boolean, default: false }
  },
  data: () => ({
    retried: 0,
    loaded: false,
    loading: false,
    interval: null
  }),
  methods: {
    attachmentIsClicked() {
      this.$emit('attachment-clicked', this.item)
    },
    normalizeHeight(value) {
      if (!value) return 'auto'
      return value.includes('px') || value.includes('%') || value.includes('vh')
        ? value
        : `${value}px`
    }
  }
}
</script>

<style scoped></style>
