import Vue from 'vue'
import store from '@/store/store'

const permissions = {
    user() {
        return store.getters.user
    },
    get_all() {
        if (this.user() && this.user().hasOwnProperty('permissions')) {
            return this.user().permissions || []
        }
        return []
    },
    get(permission) {
        if (this.user() && this.user().is_admin) {
            return {
                create: true,
                delete: true,
                view: true,
                update: true
            }
        }
        if (this.get_all().hasOwnProperty(permission))
            return this.user().permissions[permission];
        return {
            create: false,
            delete: false,
            view: false,
            update: false
        }
    },
    //permission_privelege could be like 'calendars.view', use to get single permission value
    can(permission_privelege) {
        let xploded = permission_privelege.split('.');
        if (xploded.length < 1) {
            return false
        }
        if (this.user().is_admin) {
            return true
        }
        if (this.get_all().hasOwnProperty(xploded[0])) {
            return this.user().permissions[xploded[0]][xploded[1]]
        }
        return false
    },
    //permission_privelege could be like 'calendars.view|calendars.update' pipe separated, used to get multiple permissions value
    cans(permission_priveleges) {
        let xploded = permission_priveleges.split('|');
        let perQuery = {};
        for (let i = 0; i < xploded.length; i++) {
            perQuery.push(xploded[i], this.can(xploded[i]))
        }
        return perQuery
    }
};

Plugin.install = Vue => {
    Vue.prototype.$_permissions = permissions
};

Vue.use(Plugin);

export default Plugin
