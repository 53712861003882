<template>
  <vue-plyr ref="plyr" v-if="streamUrl">
    <video
      :key="streamUrl"
      controls
      playsinline
      :data-poster="settings.noVideoPreview"
    >
      <source :src="streamUrl" :type="type" />
      <a v-if="downloadUrl" download="" :href="downloadUrl"> Download </a>
    </video>
  </vue-plyr>
  <v-sheet
    color="transparent"
    class="d-flex fullwidth flex-column fill-height align-center justify-center"
    v-else
  >
    <v-img :lazy-src="settings.loader" contain :src="settings.notFound"></v-img>
    <strong class="error--text mt-2">Video url not found!</strong>
  </v-sheet>
</template>

<script>
export default {
  name: 'VideoPlayer',
  props: {
    streamUrl: { type: String },
    type: { type: String, default: 'video/mp4' },
    downloadUrl: { type: String, default: null }
  },
  data() {
    return {
      playerOptions: {
        controls: ['play-large', 'mute', 'fullscreen']
      }
    }
  },
  mounted() {
    // console.log(this.$refs.plyr.player)
  }
}
</script>
