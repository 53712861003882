<template>
  <v-menu
    v-if="user"
    v-model="menu"
    :close-on-content-click="false"
    offset-y
    :max-width="350"
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <div
            style="height: 50px; max-width: 300px"
            class="d-flex"
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
          >
            <v-badge
              bordered
              style="width: 50px"
              bottom
              :color="is_online ? `success` : `grey`"
              dot
              offset-x="18"
              offset-y="13"
            >
              <v-avatar
                size="42"
                class="mt-1 mr-1"
                style="border: 3px solid #223a6b"
              >
                <v-img
                  :lazy-src="settings.loader"
                  :src="user.image_url"
                  class="hover"
                  contain
                ></v-img>
              </v-avatar>
            </v-badge>
            <div style="width: auto" class="d-flex text-left" v-if="!iconOnly">
              <slot name="display">
                <h1
                  class="
                    ml-1
                    subtitle-1
                    align-self-center
                    font-weight-bold
                    primary--text
                  "
                  :class="{ 'align-self-center': !displayJob }"
                >
                  {{ displayName | ucwords | truncate(20) }}
                </h1>
              </slot>
              <slot name="default" />
            </div>
          </div>
        </template>
        <span>{{ user.fullname | ucwords }}</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-row dense align="center">
              <v-col cols="3">
                <v-img :lazy-src="settings.loader" :src="user.image_url" />
              </v-col>
              <v-col cols="9">
                <v-list-item-title :title="user.fullname" class="title">
                  {{ user.fullname | ucwords }}
                </v-list-item-title>
                <v-list-item-subtitle class="caption">
                  <v-icon small left>email</v-icon>
                  {{ user.email }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="caption" v-if="user.telephone">
                  <v-icon small left>phone</v-icon>
                  {{ user.telephone.formatInternational }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="body-2">
                  <v-icon small left>person</v-icon>
                  {{ user.job_title | ucwords }}
                </v-list-item-subtitle>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-card-actions>
        <v-btn
          @click="navigate_to_profile"
          text
          small
          class="caption text-none primary--text font-weight-bold"
        >
          <v-icon small left>person</v-icon>
          {{ !self ? 'View Profile' : 'View Self Profile' }}
        </v-btn>
        <v-btn
          v-if="!self"
          @click="open_mailer"
          text
          small
          class="caption text-none primary--text font-weight-bold"
        >
          <v-icon small left>email</v-icon>
          Email
        </v-btn>
        <v-btn
          v-if="!self"
          @click="navigate_to_chat"
          text
          small
          class="caption text-none primary--text font-weight-bold"
        >
          <v-icon small left>message</v-icon>
          Chat
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    user: { type: Object },
    size: { type: Number, default: 42 },
    iconOnly: { type: Boolean, default: false },
    display: { type: String, default: '' },
    displayJob: { type: Boolean, default: true }
  },
  data: () => ({
    menu: false
  }),
  computed: {
    displayName() {
      if (this.display === '') {
        return `${this.user.first_name} ${this.user.last_name.charAt(0) || ''}.`
      }
      return this.display
    },
    onlineUsers() {
      return this.$store.getters['onlineUsers/all_users']
    },
    is_online() {
      const is_online = this.onlineUsers.findIndex(
        (ou) => ou.id === this.user.id
      )
      return !!~is_online
    },
    self() {
      return this.$store.getters.user.id === this.user.id
    }
  },

  methods: {
    navigate_to_profile() {
      this.$router.push({
        name: 'profile',
        params: { username: this.user.username }
      })
    },
    navigate_to_chat() {
      this.$router.push(`/dashboard/chat/?user=${this.user.username}`)
    },
    open_mailer() {
      if (this.user) this.$event.$emit('open_emailer', this.user)
    }
  }
}
</script>
<style lang="css" scoped>
>>> .hover {
  cursor: pointer;
}

>>> .status {
  position: relative;
  bottom: -15px;
  left: -15px;
  border: 2px solid #fff;
  border-radius: 50%;
  background: #fff;
}
</style>
