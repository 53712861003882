export const expanded_cards = {
    path: 'expanded',
    component: () =>
        import ('@/modules/ExpandedCards/ExpandedCards.vue'),
    children: [{
            path: 'clients',
            name: 'expanded-clients',
            component: () =>
                import ('@/modules/ExpandedCards/pages/ExpandedClients.vue')
        },
        {
            path: 'invoices',
            name: 'expanded-invoices',
            component: () =>
                import ('@/modules/ExpandedCards/pages/ExpandedInvoices.vue')
        },
        {
            path: 'timers',
            name: 'expanded-timers',
            component: () =>
                import ('@/modules/ExpandedCards/pages/ExpandedTimers.vue')
        },
        // {
        //     path: 'payments',
        //     name: 'expanded-payments',
        //     component: () =>
        //         import('@/modules/ExpandedCards/pages/ExpandedPayments.vue')
        // },
        {
            path: 'timeline',
            name: 'expanded-timeline',
            component: () =>
                import ('@/modules/ExpandedCards/pages/ExpandedTimeline.vue')
        },
        {
            path: 'tasks',
            name: 'expanded-tasks',
            component: () =>
                import ('@/modules/ExpandedCards/pages/ExpandedTasks.vue')
        },
        {
            path: 'calendar',
            name: 'expanded-calendar',
            component: () =>
                import ('@/modules/ExpandedCards/pages/ExpandedCalendar.vue')
        },
        {
            path: 'alarms',
            name: 'expanded-alarms',
            component: () =>
                import ('@/modules/ExpandedCards/pages/ExpandedAlarms.vue')
        }
    ]
}