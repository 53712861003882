import request from '@/services/axios_instance'
import httpBuildQuery from 'http-build-query'
import _ from 'lodash'

const state = {
    project_id: null,
    project_tasks: [],
    personal_tasks: [],
    user_id: null,
    requests: [],
    project_tasks_next_page_url: null,
    personal_tasks_next_page_url: null,
    requests_next_page_url: null,
    activeTask: null,
    activeRequest: null,
    task_counter: {
        project_total: 0,
        project_completed: 0,
        project_pending: 0,
        project_behind: 0,
        project_open: 0,
        project_urgent: 0,
        personal_total: 0,
        personal_completed: 0,
        personal_pending: 0,
        personal_behind: 0,
        personal_open: 0,
        personal_urgent: 0
    },
    requests_counter: {
        total: 0,
        completed: 0,
        pending: 0,
        approved: 0,
        archived: 0
    },
    activeFilter: { text: 'All Tasks', value: 'all_tasks' },
    taskFilters: [
        { text: 'All Tasks', value: 'all_tasks' },
        { text: 'All Urgent Tasks', value: 'all_urgent' },
        { text: 'All Completed Tasks', value: 'all_completed' },
        { text: 'All Open Tasks', value: 'all_open' },
        { text: 'All Behind Tasks', value: 'all_behind' },
        { text: 'All Pending Tasks', value: 'all_pending' },
        { text: 'divider', value: null },
        { text: 'My All Tasks', value: 'my_tasks' },
        { text: 'My Urgent Tasks', value: 'my_urgent' },
        { text: 'My Completed Tasks', value: 'my_completed' },
        { text: 'My Open Tasks', value: 'my_open' },
        { text: 'My Behind Tasks', value: 'my_behind' },
        { text: 'My Pending Tasks', value: 'my_pending' }
    ],
    is_fetching: false,
    is_sending: false,
    is_searching: false,
    milestones: [],
    search_results: []
}

const getters = {
    project_id: (state) => state.project_id,
    user_id: (state) => state.user_id,
    project_tasks: (state) => state.project_tasks,
    personal_tasks: (state) => state.personal_tasks,
    requests: (state) => state.requests,
    project_tasks_next_page_url: (state) => state.project_tasks_next_page_url,
    personal_tasks_next_page_url: (state) => state.personal_tasks_next_page_url,
    requests_next_page_url: (state) => state.requests_next_page_url,
    activeTask: (state) => state.activeTask,
    activeRequest: (state) => state.activeRequest,
    task_counter: (state) => state.task_counter,
    requests_counter: (state) => state.requests_counter,
    is_fetching: (state) => state.is_fetching,
    is_sending: (state) => state.is_sending,
    is_searching: (state) => state.is_searching,
    milestones: (state) => state.milestones,
    search_results: (state) => state.search_results,
    activeFilter: (state) => state.activeFilter,
    taskFilters: (state) => state.taskFilters,
    myTaskFilters: (state) =>
        state.taskFilters.filter((i) => i.value && i.value.includes('my_')),
    taskActions: (state) => (task) => {
        let actions = [{
                id: 1,
                text: 'Edit Task',
                value: 'edit',
                icon: 'mdi-file-document-edit-outline'
            },
            {
                id: 2,
                text: 'Delete Task',
                value: 'delete',
                icon: 'mdi-delete-alert-outline'
            }
        ]
        let status = task.status.toLowerCase()
        if (status !== 'completed') {
            actions.push({
                id: 3,
                text: 'Mark as Complete',
                value: 'mark-as-complete',
                icon: 'mdi-clipboard-check'
            })
        }
        if (status !== 'completed' && status !== 'urgent') {
            actions.push({
                id: 4,
                text: 'Mark as Urgent',
                value: 'mark-as-urgent',
                icon: 'mdi-clipboard-alert-outline'
            })
        } else if (status !== 'completed' && status === 'urgent') {
            actions.push({
                id: 4,
                text: 'Mark as Non-urgent',
                value: 'mark-as-urgent',
                icon: 'mdi-clipboard-alert-outline'
            })
        }
        if (!state.project_id && task.project_id) {
            actions.push({
                id: 5,
                text: 'Go to Project',
                value: 'go-to-project',
                icon: 'mdi-clipboard-arrow-right',
                route: {
                    name: 'hq',
                    params: { id: task.project_id, type: 'project' }
                }
            })
        }
        return actions
    }
}

const mutations = {
    set_project_id: (state, payload) => (state.project_id = payload),
    set_user_id: (state, payload) => (state.user_id = payload),
    set_milestones: (state, payload) => (state.milestones = payload),
    add_milestone: (state, payload) => state.milestones.push(payload),
    set_search_results: (state, payload) => (state.search_results = payload),
    set_project_tasks: (state, payload) => (state.project_tasks = payload),
    append_project_tasks: (state, payload) =>
        state.project_tasks.push(...payload),
    add_project_tasks: (state, payload) => state.project_tasks.push(payload),
    update_project_tasks: (state, payload) => {
        let index = state.project_tasks.findIndex((i) => i.id === payload.id)
        if (~index) {
            state.project_tasks.splice(index, 1, payload)
        }
    },
    delete_project_tasks: (state, id) => {
        let index = state.project_tasks.findIndex((i) => i.id === id)
        if (~index) {
            state.project_tasks.splice(index, 1)
        }
    },

    set_personal_tasks: (state, payload) => (state.personal_tasks = payload),
    append_personal_tasks: (state, payload) =>
        state.personal_tasks.push(...payload),
    add_personal_tasks: (state, payload) => state.personal_tasks.push(payload),
    update_personal_tasks: (state, payload) => {
        let index = state.personal_tasks.findIndex((i) => i.id === payload.id)
        if (~index) {
            state.personal_tasks.splice(index, 1, payload)
        }
    },
    delete_personal_tasks: (state, id) => {
        let index = state.personal_tasks.findIndex((i) => i.id === id)
        if (~index) {
            state.personal_tasks.splice(index, 1)
        }
    },

    set_requests: (state, payload) => (state.requests = payload),
    append_requests: (state, payload) => state.requests.push(...payload),
    add_requests: (state, payload) => state.requests.push(payload),
    update_requests: (state, payload) => {
        let index = state.requests.findIndex((i) => i.id === payload.id)
        if (~index) {
            state.requests.splice(index, 1, payload)
        }
    },
    delete_requests: (state, id) => {
        let index = state.requests.findIndex((i) => i.id === id)
        if (~index) {
            state.requests.splice(index, 1)
        }
    },

    set_project_tasks_next_page_url: (state, payload) =>
        (state.project_tasks_next_page_url = payload),
    set_personal_tasks_next_page_url: (state, payload) =>
        (state.personal_tasks_next_page_url = payload),
    set_requests_next_page_url: (state, payload) =>
        (state.requests_next_page_url = payload),
    set_activeTask: (state, payload) => (state.activeTask = payload),
    set_activeTaskById: (state, id) => {
        let index1 = state.project_tasks.findIndex((i) => i.id == id)
        if (~index1) {
            state.activeTask = state.project_tasks[index1]
        } else {
            let index2 = state.personal_tasks.findIndex((i) => i.id == id)
            if (~index2) {
                state.activeTask = state.personal_tasks[index2]
            } else state.activeTask = null
        }
    },
    set_activeRequest: (state, payload) => (state.activeRequest = payload),
    set_activeFilter: (state, payload) => (state.activeFilter = payload),
    set_task_counter: (state, payload) => (state.task_counter = payload),
    set_requests_counter: (state, payload) => (state.requests_counter = payload),
    set_is_fetching: (state, payload) => (state.is_fetching = payload),
    set_is_sending: (state, payload) => (state.is_sending = payload),
    set_is_searching: (state, payload) => (state.is_searching = payload)
}

const actions = {
    get_requests({ commit, state }, filter) {
        commit('set_is_fetching', true)
        let api_url = 'api/requests'
        if (state.project_id) {
            api_url += '/' + state.project_id
        }
        if (filter && typeof filter !== 'undefined') {
            api_url += '?filter=' + filter
        }
        request
            .get(api_url)
            .then(({ data }) => {
                commit('set_requests', data.data)
                commit('set_requests_next_page_url', data.next_page_url)
            })
            .finally(() => commit('set_is_fetching', false))
    },
    load_more_requests({ commit, state }) {
        commit('set_is_fetching', true)
        commit('set_is_sending', true)
        request
            .get(state.requests_next_page_url)
            .then(({ data }) => {
                commit('append_requests', data.data)
                commit('set_requests_next_page_url', data.next_page_url)
            })
            .finally(() => {
                commit('set_is_fetching', false)
                commit('set_is_sending', false)
            })
    },
    get_milestones({ commit, state }, filters) {
        if (state.project_id) {
            let build_query = httpBuildQuery(filters)
            request
                .get(`api/projects/${state.project_id}/milestones?${build_query}`)
                .then(({ data }) => {
                    commit('set_milestones', data)
                })
                .finally(() => commit('set_is_fetching', false))
        }
    },
    get_requests_counter({ commit, state }) {
        let api_url = 'api/requests'
        if (state.project_id) {
            api_url += '/' + state.project_id
        } else {
            api_url += '/all'
        }
        api_url += '/counter'
        request
            .get(api_url)
            .then(({ data }) => {
                commit('set_requests_counter', data)
            })
            .finally(() => commit('set_is_fetching', false))
    },
    get_project_tasks({ commit, state }, filters) {
        commit('set_is_fetching', true)
        let build_query = httpBuildQuery(filters)
        let api_url = `api/projects/${state.project_id}/tasks?${build_query}`
        request
            .get(api_url)
            .then(({ data }) => {
                commit('set_project_tasks', data.data)
                commit('set_project_tasks_next_page_url', data.next_page_url)
            })
            .finally(() => commit('set_is_fetching', false))
    },
    load_more_project_tasks({ commit, state }) {
        commit('set_is_fetching', true)
        commit('set_is_sending', true)
        request
            .get(state.project_tasks_next_page_url)
            .then(({ data }) => {
                commit('append_project_tasks', data.data)
                commit('set_project_tasks_next_page_url', data.next_page_url)
            })
            .finally(() => {
                commit('set_is_fetching', false)
                commit('set_is_sending', false)
            })
    },
    get_personal_tasks({ commit, state }, filters) {
        commit('set_is_fetching', true)
        let build_query = httpBuildQuery(filters)
        let api_url = `api/task/my-tasks?${build_query}`
        request
            .get(api_url)
            .then(({ data }) => {
                commit('set_personal_tasks', data.data)
                commit('set_personal_tasks_next_page_url', data.next_page_url)
            })
            .finally(() => commit('set_is_fetching', false))
    },
    load_more_personal_tasks({ commit, state }) {
        commit('set_is_fetching', true)
        commit('set_is_sending', true)
        request
            .get(state.personal_tasks_next_page_url)
            .then(({ data }) => {
                commit('append_personal_tasks', data.data)
                commit('set_personal_tasks_next_page_url', data.next_page_url)
            })
            .finally(() => {
                commit('set_is_fetching', false)
                commit('set_is_sending', false)
            })
    },
    search_tasks({ commit, state }, { type, search }) {
        if (search && search.trim() !== '') {
            commit('set_is_searching', true)
            let build_query = httpBuildQuery({ search: search, limit: 20 })
            let api_url =
                type === 'project' ?
                `api/projects/${state.project_id}/tasks?${build_query}` :
                `api/task/my-tasks?${build_query}`
            request
                .get(api_url)
                .then(({ data }) => {
                    commit('set_search_results', data)
                })
                .finally(() => commit('set_is_searching', false))
        }
    },
    saveMilestone({ commit, state }, { payload, cb }) {
        commit('set_is_sending', true)
        request
            .post(`api/project/${state.project_id}/milestone`, payload)
            .then(({ data }) => {
                commit('add_milestone', data)
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .finally(() => {
                commit('set_is_sending', false)
            })
    },
    saveTask({ commit, state }, { type, payload, cb }) {
        commit('set_is_sending', true)
        request
            .post(`api/task`, payload)
            .then(({ data }) => {
                if (type === 'project') {
                    commit('add_project_tasks', data)
                } else if (type === 'personal') {
                    commit('add_personal_tasks', data)
                }
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .finally(() => {
                commit('set_is_sending', false)
            })
    },
    updateTask({ commit, state }, { taskId, type, payload, cb }) {
        commit('set_is_sending', true)
        request
            .put(`api/task/${taskId}`, payload)
            .then(({ data }) => {
                if (type === 'project') {
                    commit('update_project_tasks', data)
                } else if (type === 'personal') {
                    commit('update_personal_tasks', data)
                }
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .finally(() => {
                commit('set_is_sending', false)
            })
    },
    deleteTask({ commit, state }, { taskId, cb }) {
        commit('set_is_sending', true)
        request
            .delete(`api/task/${taskId}`)
            .then(({ data }) => {
                commit('delete_project_tasks', taskId)
                commit('delete_personal_tasks', taskId)
                commit('set_activeTask', null)
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .finally(() => {
                commit('set_is_sending', false)
            })
    },
    updateTaskStatus({ commit, state }, { taskId, action, payload, cb }) {
        commit('set_is_sending', true)
        request
            .put(`api/task/${taskId}/${action}`, payload)
            .then(({ data }) => {
                commit('update_project_tasks', data)
                commit('update_personal_tasks', data)
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .finally(() => {
                commit('set_is_sending', false)
            })
    }
}

export { state, getters, mutations, actions }