import request from '@/services/axios_instance'
import Vue from 'vue'

export default {
    appSnackbar: (message, type) => {
        Vue.prototype.$event.$emit(
            'open_snackbar',
            message,
            typeof type === 'undefined' || !type ? 'success' : type
        )
    },
    appConfirmation: (message, yescb, nocb, confirmLabel) => {
        Vue.prototype.$event.$emit(
            'open_confirmation',
            message,
            typeof yescb !== 'function' || !yescb ? undefined : yescb,
            typeof nocb !== 'function' || !nocb ? undefined : nocb,
            typeof confirmLabel == 'undefined' || !confirmLabel ? null : confirmLabel
        )
    },
    verifyProject: (to, from, next) => {
        request
            .get(`api/verify/is-belong-to/project/${to.params.id}`)
            .then(({ data }) => {
                if (data.belong) {
                    next()
                } else {
                    next({
                        path: '/404'
                    })
                }
            })
    },
    verifyService: (to, from, next) => {
        request
            .get(`api/verify/is-belong-to/service/${to.params.id}`)
            .then(({ data }) => {
                if (data.belong) {
                    next()
                } else {
                    next({
                        path: '/404'
                    })
                }
            })
    },
    verifyUser: (to, from, next) => {
        request
            .get(`api/verify/is-belong-to/user/${to.params.user_id}`)
            .then(({ data }) => {
                if (data.belong) {
                    next()
                } else {
                    next({
                        path: '/404'
                    })
                }
            })
    },
    scrollToRef(ref) {
        setTimeout(() => {
            const el = this.$refs[ref]
            if (el) {
                el.scrollIntoView({ behavior: 'smooth' })
            }
        }, 200)
    },
    scrollToElement(className) {
        setTimeout(() => {
            const el = this.$el.getElementsByClassName(className)[0]
            if (el) {
                el.scrollIntoView({ behavior: 'smooth' })
            }
        }, 200)
    },
    scrollToId(idname) {
        setTimeout(() => {
            const el = this.$el.getElementsById(idname)[0]
            if (el) {
                el.scrollIntoView({ behavior: 'smooth' })
            }
        }, 200)
    },
    popupCenter({ url, title, w, h }) {
        const dualScreenLeft =
            window.screenLeft !== undefined ? window.screenLeft : window.screenX
        const dualScreenTop =
            window.screenTop !== undefined ? window.screenTop : window.screenY
        const width = window.innerWidth ?
            window.innerWidth :
            document.documentElement.clientWidth ?
            document.documentElement.clientWidth :
            screen.width
        const height = window.innerHeight ?
            window.innerHeight :
            document.documentElement.clientHeight ?
            document.documentElement.clientHeight :
            screen.height
        const systemZoom = width / window.screen.availWidth
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        const newWindow = window.open(
            url,
            title,
            `scrollbars=yes,width=${w / systemZoom},height=${
        h / systemZoom
      },top=${top},left=${left}`
        )
        if (window.focus) newWindow.focus()
    }
}