import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store'

import { test } from './routes/test'
import { home } from './routes/home'
import { checkout } from './routes/checkout'
import { login } from './routes/authentications/login'
import { signInWith } from './routes/authentications/signInWith'
import { signup } from './routes/authentications/signup'
import { forgotPassword } from './routes/authentications/forgotPassword'
import { setPassword } from './routes/authentications/setPassword'
import { notFound, maintenance_mode } from './routes/notFound'
import { notAvailable } from './routes/notAvailable'
import { dashboard } from './routes/dashboard'
import { clients } from './routes/clients'
import { leads, lead_groups } from '@/router/routes/leads'
import { templates, import_templates } from './routes/templates'
import { milestones } from './routes/milestones'
import { milestoneTasks } from './routes/milestoneTasks'
import { projects } from './routes/projects'
import { profiles } from './routes/profiles'
import {
    campaigns,
    campaign_email_generator,
    campaign_sms_generator,
    campaign_emails,
    campaign_smsmms,
    email_accessories_list,
    campaign_smsmms_settings,
    campaign_email_settings
} from './routes/campaigns'
import { teams } from './routes/teams'
import { settings } from './routes/settings'
import { project_preview } from './routes/projectPreview'
import { taskTimer } from './routes/taskTimer'
import { globalTimer } from './routes/globalTimer'
import { alarm } from './routes/alarm'
import { invoice, invoice_maker, invoice_viewer } from './routes/invoice'
import { formResponses, forms } from './routes/forms'
import { formBuilder, formEdit } from './routes/formBuilder'
import { chat } from './routes/chat'
import { calendar } from './routes/calendar'
import { reports } from './routes/reports'
import { payment, payment_to } from './routes/payment'
import { connects, connectTo } from './routes/connect'
import { support, support_subs } from './routes/support'
import { notes } from './routes/notes'
import { bugs } from './routes/bugs'
import { projectDetails } from './routes/projectDetails'
import { pay_invoice } from './routes/payInvoice'
import { invoice_templates } from './routes/invoiceTemplates'
import { formResponse } from './routes/formResponse'
import { servicesList } from './routes/servicesList'
import { admin_dashboard } from './routes/admins/adminDashboard'
import { admin_subscribers } from './routes/admins/adminSubscribers'
import { admin_apis } from './routes/admins/adminApis'
import { admin_logs } from './routes/admins/adminLogs'
import { admin_database } from './routes/admins/adminDatabase'
import { admin_payments } from './routes/admins/adminPayments'
import {
    admin_templates,
    build_admin_invoice_template
} from './routes/admins/adminTemplates'
import { admin_products } from '@/router/routes/admins/adminProducts'
import { personal_tasks } from './routes/personalTasks'
import { product_builder } from './routes/productBuilder'
import { offers, view_product, manage_categories } from './routes/offersOrders'
import { template_builder } from './routes/templateDesign'
import { orders } from './routes/orders'
import {
    courses,
    courses_maincategory,
    courses_subcategory,
    courses_series,
    series_create_update,
    manage_course_categories
} from './routes/courses'
// import { designs } from './routes/design'
import {
    build_email_template_campaign,
    build_email_signature,
    request_builder,
    build_invoice_template,
    report_builder
} from './routes/builders'
import {
    subscriptions,
    pay,
    summary,
    charge
} from '@/router/routes/subscriptions'
import { expanded_cards } from './routes/expandedCards'
import { wallets } from './routes/wallet'

Vue.use(Router)

const ifAuthenticated = (to, from, next) => {
    if (store.getters.maintenance_mode) {
        next({ name: 'maintenance_mode' })
        return
    }
    if (store.getters.isAuthenticated) {
        next()
        return
    }
    next({ name: 'login' })
}

export default new Router({
    routes: [{
            path: '/dashboard',
            component: () =>
                import ('@/modules/Dashboard/Dashboard.vue'),
            children: [
                test,
                dashboard, // path: '' => info the path is empty because is the default component for the parent
                profiles, //path: /profile/:username
                build_email_template_campaign, //path: /builders/email-template-campaign/:id?
                build_email_signature, //path: /builders/email-signature/:id?
                build_invoice_template, //path: /builders/invoice-template/:id?
                report_builder, //path: /reports/builder/:id?
                request_builder, //path: /dashboard/rbuilder/request/:id?,
                clients, // path: /dashboard/clients
                //designs, // path: /dashboard/offers/all-design
                template_builder, // path /dashboard/template-design
                leads, // path: /dashboard/leads
                lead_groups, //path: /dashboard/lead-groups
                templates, // path: /dashboard/templates
                import_templates, //path: /dashboard/templates/import
                milestones, // path: /dashboard/templates/:id/milestone
                milestoneTasks, // path: /dashboard/templates/:id/milestone/:id2/task
                projects, // path: /dashboard/projects/:type
                campaigns, // path: /dashboard/campaigns
                campaign_email_generator, //path: /dashboard/campaigns/email/:id?
                campaign_sms_generator, //path: /dashboard/campaigns/sms/:id?
                campaign_emails, //path: /dashboard/lead-campaigns/emails/:id
                campaign_smsmms, //path: /dashboard/lead-campaigns/sms/:id
                email_accessories_list, //path: /dashboard/lead-campaigns/email-accessories/:type
                campaign_smsmms_settings, //path: /dashboard/lead-campaigns/sms-mms/setting
                campaign_email_settings, //path: /dashboard/lead-campaigns/email/setting
                teams, // path: /dashboard/team
                settings, // path: /dashboard/settings,
                project_preview, //path: /dashboard/project-preview
                taskTimer, //path: /dashboard/task-timer
                globalTimer, //path: /dashboard/global-timer
                alarm, //path: /dashboard/alarm
                invoice, //path: /dashboard/invoice
                invoice_maker, //path: /dashboard/invoice/generator/:id?
                forms, //path: /dashboard/forms
                formResponses, //path: /dashboard/forms/:id/responses
                formBuilder, //path: /dashboard/forms/form-builder
                formEdit, //path: /dashboard/forms/form/:id/edit
                chat, //path: /dashboard/chat
                calendar, //path: /dashboard/calendar
                reports, //path: /dashboard/reports
                payment, //path: /dashboard/payment
                payment_to, //path: /dashboard/payment-to/:type/:id
                pay_invoice, //path: /dashboard/pay-invoice/:id
                connects, //path: /dashboard/connect
                connectTo, //path: /dashboard/connect-to/:type
                support, //path: /dashboard/support
                support_subs, //path: /dashboard/resources
                wallets, //path: /dashboard/wallet/:any
                // timeline, //path: /dashboard/timeline/:source?/:id?
                notes, //path: /dashboard/notes/
                projectDetails, //path: /dashboard/forms/project-details
                expanded_cards, //path: /dashboard/expanded
                bugs, // path: /dashboard/bugs
                invoice_templates, //path: dashboard/invoice-templates
                servicesList, //path: /dashboard/services,
                personal_tasks, //path: /dashboard/my-tasks,
                offers, //path: /dashboard/offers/:type *,
                view_product, //path: /dashboard/product/:id *,
                manage_categories, //path: /dashboard/categories,
                product_builder, //path: /dashboard/products/:id? *,
                orders, //path: /dashboard/orders/:id
                subscriptions, //path: /dashboard/subscriptions
                pay, //path: /dashboard/subscriptions/pay
                summary, //path: /dashboard/subscriptions/summary
                charge, //path: /dashboard/subscriptions/summary
                courses, //path: /dashboard/course
                courses_maincategory, //path: /dashboard/course/:slug
                courses_subcategory, //path: /dashboard/course/:slug/:subslug
                courses_series, //path: /dashboard/view-course/:slug
                series_create_update, //path: /dashboard/course/series/:id?
                manage_course_categories //path: /dashboard/course/categories
            ],
            beforeEnter: ifAuthenticated
        },
        setPassword, // path: /set-password
        home, // path: /
        checkout, // path: /checkout
        login, // path: /login
        signup, // path: /signup
        forgotPassword, // path: /forgot-password
        maintenance_mode, //Path: /503-MAINTENANCE
        notAvailable, //path: /feature-unavailable
        formResponse, //path: /form/:slug
        {
            path: '/admin-dashboard',
            component: () =>
                import ('@/modules/AdminArea/AdminDashboard.vue'),
            children: [
                admin_dashboard, //path: admin-dashboard
                admin_subscribers, //path: admin-dashboard/subscribers
                admin_apis, //path: admin-dashboard/apis
                admin_logs, //path: admin-dashboard/logs
                admin_database, //path: admin-dashboard/database
                admin_payments, //path: admin-dashboard/payments
                admin_templates, //path: admin-dashboard/templates
                build_admin_invoice_template, //path: builders/:type/invoice-template/:id?
                admin_products //path: admin-dashboard/products
            ]
        },
        invoice_viewer, //path: /invoices/viewer/:id
        signInWith, //path: /sign-in-with/:source
        notFound // path: /404
    ],
    mode: 'history'
})