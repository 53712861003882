import request from '@/services/axios_instance'
import _ from 'lodash'

const getRoles = function(user) {
    let roles = []
    if (user) {
        if (user.is_company_owner) roles.push('owner')
        if (user.is_client) roles.push('client')
        else roles.push('member')
        if (user.is_admin) roles.push('admin')
        if (user.is_manager) roles.push('manager')
        if (user.is_super_admin) roles.push('super-admin')
    }
    return roles
}

const state = {
    servicesModule: {
        name: 'servicesModule',
        activeItem: null,
        items: [],
        listApi: 'api/services-list/list',
        createApi: 'api/services-list',
        add_dialog: false
    },
    clientsModule: {
        name: 'clientsModule',
        activeItem: null,
        items: [],
        listApi: 'api/clients/per-company',
        createApi: 'api/clients',
        add_dialog: false
    },
    usersModule: {
        name: 'usersModule',
        activeItem: null,
        items: [],
        listApi: 'api/company/members?for=store',
        createApi: 'api/company/teams',
        add_dialog: false
    },
    groupsModule: {
        name: 'groupsModule',
        activeItem: null,
        items: [],
        listApi: 'api/groups/company?with_admin=true',
        createApi: 'api/groups',
        add_dialog: false
    }
}

const getters = {
    servicesModule: (state) => state.servicesModule,
    clientsModule: (state) => state.clientsModule,
    usersModule: (state) => state.usersModule,
    groupsModule: (state) => state.groupsModule,

    managers: (state) => {
        return _.cloneDeep(state.usersModule.items).filter((user) =>
            getRoles(user).includes('manager')
        )
    },
    members: (state) => {
        return _.cloneDeep(state.usersModule.items).filter((user) => {
            let roles = getRoles(user)
            let intersect = _.intersection(roles, ['manager', 'admin', 'client'])
            return roles.includes('member') && intersect.length === 0
        })
    },
    admins: (state) => {
        return _.cloneDeep(state.usersModule.items).filter((user) =>
            getRoles(user).includes('admin')
        )
    },
    adminsAndMembers: (state) => {
        return _.cloneDeep(state.usersModule.items).filter((user) => {
            let roles = getRoles(user)
            return (
                roles.includes('admin') ||
                (roles.includes('member') && !roles.includes('manager'))
            )
        })
    },
    adminsAndManagers: (state) => {
        return _.cloneDeep(state.usersModule.items).filter((user) => {
            let roles = getRoles(user)
            return roles.includes('admin') || roles.includes('manager')
        })
    },
    clients: (state) => {
        return _.cloneDeep(state.clientsModule.items)
    }
}

const mutations = {
    set_module_items: (state, { module, data }) => {
        state[module].items = data
    },
    append_module_item: (state, { module, data }) => {
        state[module].items.push(data)
    },
    append_module_items: (state, { module, data }) => {
        state[module].items.push(...data)
    },
    set_module_dialog: (state, { module, value }) => {
        state[module].add_dialog = value
    }
}

const actions = {
    get_service: ({ state }, id) => {
        let index = state.servicesModule.items.findIndex((i) => i.id === id)
        return ~index ? _.cloneDeep(state.servicesModule.items)[index] : null
    },
    get_user: ({ state }, id) => {
        let index = state.usersModule.items.findIndex((i) => i.id === id)
        return ~index ? _.cloneDeep(state.usersModule.items)[index] : null
    },
    get_users: ({ state }, ids) => {
        return _.cloneDeep(state.usersModule.items).filter((i) =>
            ids.includes(i.id)
        )
    },
    fetchModule({ commit, state }, module) {
        if (state[module].listApi) {
            request.get(state[module].listApi).then(({ data }) => {
                commit('set_module_items', { module, data })
            })
        }
    },
    createModule({ commit, state }, { module, payload, cb }) {
        if (state[module].createApi) {
            request.post(state[module].createApi, payload).then(({ data }) => {
                commit('append_module_item', { module, data })
                commit('set_module_dialog', { module, value: false })
                if (typeof cb === 'function') cb(data)
            })
        }
    },
    createModules({ commit, state }, { module, payload, cb }) {
        if (state[module].createApi) {
            request.post(state[module].createApi, payload).then(({ data }) => {
                commit('append_module_items', { module, data })
                commit('set_module_dialog', { module, value: false })
                if (typeof cb === 'function') cb(data)
            })
        }
    }
}

export { state, getters, mutations, actions }