import request from '@/services/axios_instance'

export const actions = {
    // fetchTilesDataBySlugs({ commit }, slugs) {
    //     request.post(`api/dashboard/dashtiles/statistic`, { slugs: slugs })
    //         .then(({ data }) => {
    //             Object.keys(data).forEach(e => {
    //                 commit('set_tile', { slug: e, value: data[e] })
    //             });
    //         })
    // },
    // fetchCardDataBySlug({ commit }, slug) {
    //     request.get(`api/dashboard/dashcards/${slug}`)
    //         .then(({ data }) => {
    //             commit('set_cardData', { slug: slug, data: data })
    //         })
    // },
}