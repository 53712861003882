import auth from '../middleware/auth'
import is_module_enabled from '../middleware/is_module_enabled'

export const connects = {
    path: 'connect',
    name: 'connect',
    component: () =>
        import ('@/modules/Connect/Connect.vue'),
    meta: {
        middleware: [auth, is_module_enabled]
    }
}

export const connectTo = {
    path: 'connect-to',
    component: () =>
        import ('@/modules/Connect/ConnectPage.vue'),
    meta: {
        middleware: [auth]
    },
    children: [{
            path: 'google-drive',
            name: 'connect-google_drive',
            component: () =>
                import ('@/modules/Connect/pages/GoogleDrive.vue')
        },
        {
            path: 'stripe',
            name: 'connect-stripe',
            component: () =>
                import ('@/modules/Connect/pages/Stripe.vue')
        },
        {
            path: 'mobilemonkey',
            name: 'connect-mobilemonkey',
            component: () =>
                import ('@/modules/Connect/pages/MobileMonkey.vue')
        },
        {
            path: 'livechat',
            name: 'connect-livechat',
            component: () =>
                import ('@/modules/Connect/pages/LiveChat.vue')
        },
        {
            path: 'canva',
            name: 'connect-canva',
            component: () =>
                import ('@/modules/Connect/pages/Canva.vue')
        },
        {
            path: 'google-calendar',
            name: 'connect-google_calendar',
            component: () =>
                import ('@/modules/Connect/pages/GoogleCalendar.vue')
        },
        {
            path: 'twilio',
            name: 'connect-twilio',
            component: () =>
                import ('@/modules/Connect/pages/Twilio.vue')
        },
        {
            path: 'mailers/:mailer',
            name: 'connect-mailers',
            component: () =>
                import ('@/modules/Connect/pages/Mailers.vue')
        }
    ]
}