import guest from '../../middleware/guest'

export const forgotPassword = {
  path: '/forgot-password',
  name: 'forgot-password',
  component: () => import('@/modules/Authentication/ForgotPassword/ForgotPasswordV2.vue'),
  meta: {
    middleware: [guest]
  }
}
