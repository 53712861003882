import _ from 'lodash'
import moment from 'moment'

export const getters = {
    createMode: (state) => state.createMode,
    id: (state) => {
        if (state.hasOwnProperty('invoice')) return state.invoice.id
        return null
    },
    title: (state) => {
        if (state.hasOwnProperty('invoice')) return state.invoice.title
        return null
    },
    billable_to_type: (state) => {
        if (state.hasOwnProperty('invoice')) return state.invoice.billable_to_type
        return null
    },
    billable_to_id: (state) => {
        if (state.hasOwnProperty('invoice')) return state.invoice.billable_to_id
        return null
    },
    billable_to: (state, getters, rootState) => {
        if (!state.hasOwnProperty('invoice') || !state.invoice.billable_to_id)
            return null
        return rootState.users.find(
            (i) => parseInt(i.id) === parseInt(state.invoice.billable_to_id)
        )
    },
    billable_from_type: (state) => {
        if (state.hasOwnProperty('invoice')) return state.invoice.billable_from_type
        return null
    },
    billable_from_id: (state) => {
        if (state.hasOwnProperty('invoice')) return state.invoice.billable_from_id
        return null
    },
    billable_from: (state, getters, rootState) => {
        if (!state.hasOwnProperty('invoice') || !state.invoice.billable_from_id)
            return null
        return rootState.users.find(
            (i) => parseInt(i.id) === parseInt(state.invoice.billable_from_id)
        )
    },
    project_id: (state) => {
        if (state.hasOwnProperty('invoice')) return state.invoice.project_id
        return null
    },
    order_id: (state) => {
        if (state.hasOwnProperty('invoice')) return state.invoice.order_id
        return null
    },
    service_id: (state) => {
        if (state.hasOwnProperty('invoice')) return state.invoice.service_id
        return null
    },
    template_id: (state) => {
        if (state.hasOwnProperty('invoice')) return state.invoice.template_id
        return null
    },
    selectedTemplate: (state, getters) => {
        if (!getters.template_id) return null
        return state.templates.find((i) => i.id === getters.template_id)
    },
    type: (state) => {
        if (state.hasOwnProperty('invoice')) return state.invoice.type
        return 'one-time'
    },
    due_date: (state) => {
        if (state.hasOwnProperty('invoice')) return state.invoice.due_date
        return moment().format('YYYY-MM-DD')
    },
    due_date_recurring: (state) => {
        if (state.hasOwnProperty('invoice')) return state.invoice.due_date_recurring
        return null
    },
    due_date_normalize: (state) => {
        if (state.hasOwnProperty('invoice') && !state.createMode)
            return state.invoice.due_date_normalize
        return state.invoice.due_date ?
            moment(state.invoice.due_date).format('LL') :
            moment().endOf('month').format('LL')
    },
    billing_date: (state) => {
        if (state.hasOwnProperty('invoice')) return state.invoice.billing_date
        return moment().format('YYYY-MM-DD')
    },
    billing_date_recurring: (state) => {
        if (state.hasOwnProperty('invoice'))
            return state.invoice.billing_date_recurring
        return null
    },
    billing_date_normalize: (state) => {
        if (state.hasOwnProperty('invoice') && !state.createMode)
            return state.invoice.billing_date_normalize
        return state.invoice.billing_date ?
            moment(state.invoice.billing_date).format('LL') :
            moment().startOf('month').format('LL')
    },
    notes: (state) => {
        if (state.hasOwnProperty('invoice')) return state.invoice.notes
        return null
    },
    terms: (state) => {
        if (state.hasOwnProperty('invoice')) return state.invoice.terms
        return null
    },
    tax: (state) => {
        if (state.hasOwnProperty('invoice')) return state.invoice.tax
        return { value: null, show: false }
    },
    tax_amount: (state, getters) => {
        if (!state.hasOwnProperty('invoice') || !state.invoice.items.length)
            return 0.0
        return getters.sub_total_tax
    },
    discount: (state) => {
        if (state.hasOwnProperty('invoice')) return state.invoice.discount
        return { value: null, show: false }
    },
    discount_amount: (state, getters) => {
        if (!state.hasOwnProperty('invoice') || !state.invoice) return 0.0
        let discount = state.invoice.discount
        return !discount.show ?
            0.0 :
            discount.type !== 'fixed' ?
            (getters.sub_total * Number(discount.value)) / 100 :
            Number(discount.value)
    },
    shipping_fee: (state) => {
        if (state.hasOwnProperty('invoice')) return state.invoice.shipping_fee
        return { value: null, show: false }
    },
    shipping_amount: (state, getters) => {
        if (!state.hasOwnProperty('invoice') || !state.invoice) return 0.0
        let shipping = state.invoice.shipping_fee
        return !shipping.show ?
            0.0 :
            shipping.type !== 'fixed' ?
            (getters.sub_total * Number(shipping.value)) / 100 :
            Number(shipping.value)
    },
    props: (state) => {
        if (state.hasOwnProperty('invoice'))
            return typeof state.invoice.props === 'string' ?
                JSON.parse(state.invoice.props) :
                state.invoice.props
        return { send_email: 'no' }
    },
    parent_id: (state) => {
        if (state.hasOwnProperty('invoice')) return state.invoice.parent_id
        return null
    },
    is_recurring: (state) => {
        if (state.hasOwnProperty('invoice')) return state.invoice.is_recurring
        return 0
    },
    sub_total_tax: (state) => {
        if (state.hasOwnProperty('invoice')) {
            let each_item_total = state.invoice.items.map((i) => {
                let subtotal = Number(i.quantity) * Number(i.price_per_unit)
                return {
                    total: i.tax && typeof i.tax !== 'undefined' && i.tax.type !== 'fixed' ?
                        (subtotal * Number(i.tax.value)) / 100 :
                        Number(i.tax.value)
                }
            })
            return _.sumBy(each_item_total, 'total')
        }
        return 0.0
    },
    sub_total: (state) => {
        if (state.hasOwnProperty('invoice')) {
            let each_item_total = state.invoice.items.map((i) => {
                return {
                    total: Number(i.quantity) * Number(i.price_per_unit)
                }
            })
            return _.sumBy(each_item_total, 'total')
        }
        return 0.0
    },
    total_after_addons: (state, getters) => {
        if (state.hasOwnProperty('invoice')) {
            //@todo when to apply calculation
            return (
                getters.sub_total -
                Math.abs(getters.discount_amount) +
                getters.tax_amount +
                getters.shipping_amount
            )
        }
        return 0.0
    },
    items: (state) => state.invoice.items,
    status: (state) => state.invoice.status,
    currency: (state) => state.invoice.currency,
    currency_symbol: (state) => state.invoice.currency_symbol,
    projects: (state) => state.projects,
    services: (state) => state.services,
    orders: (state) => state.orders,
    tasks: (state) => state.tasks,
    tasks_next_url: (state) => state.tasks_next_url,
    templates: (state) => state.templates,
    leads: (state) => state.leads,
    users: (state) => state.users,
    currencies: (state) => state.currencies,
    unit_list: (state) => state.unit_list,
    preview: (state) => state.preview,
    colors: (state) => state.invoice.colors,
    save_colors: (state) => state.invoice.save_colors,
    preview_state: (state) => state.preview_state,
    invoice: (state) => state.invoice,
    is_editting: (state) => state.is_editting,
    invoice_view: (state) => state.invoice_view,
    invoice_edit: (state) => state.invoice_edit,
    step1_passed: (state) => {
        let inv = state.invoice
        let stage1 =
            inv.title &&
            inv.type &&
            inv.currency &&
            inv.billable_from_id &&
            inv.billable_to_id ?
            true :
            false
        if (inv.type !== 'one-time') {
            return stage1 && inv.billing_date_recurring && inv.due_date_recurring ?
                true :
                false
        } else return stage1 && inv.billing_date && inv.due_date ? true : false
    },
    step2_passed: (state) => {
        let inv = state.invoice
        let errors = []
        inv.items.forEach(function(item, index) {
            if (!item.description || item.quantity < 0 || item.price_per_unit < 0.0) {
                errors.push(`Item ${index + 1}`)
            }
        }, inv.items)
        return inv.items.length > 0 && errors.length <= 0 ? true : false
    },
    step3_passed: (state, getters) => {
        let inv = state.invoice
        return inv.template_id > 0 && getters.step2_passed ? true : false
    },
    weekly_list: (state) => state.weekly_list,
    monthly_list: (state) => {
        const getOrdinalNum = function(n) {
            return (
                n +
                (n > 0 ?
                    ['th', 'st', 'nd', 'rd'][
                        (n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10
                    ] :
                    '')
            )
        }
        let dates = Array.from({ length: 30 }, (v, k) => k + 1).map((i) => {
            return { name: `Every ${getOrdinalNum(i)} of month`, value: `${i}` }
        })
        dates.push({ name: 'Every last day of month', value: '31' })
        return dates
    },
    invoice_save_taxes: (state) => state.invoice_save_taxes,
    invoice_logo: (state) => state.invoice_logo,
    invoice_company: (state, getters) => {
        return state.invoice_company ?
            state.invoice_company :
            getters.billable_from ?
            getters.billable_from.company :
            null
    }
}