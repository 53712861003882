<template>
  <v-card
    flat
    v-bind="$attrs"
    class="d-flex align-center flex-column rounded-5 justify-center"
  >
    <v-progress-circular color="primary" indeterminate size="100">
      <v-avatar tile size="50">
        <v-img
          :lazy-src="settings.loader"
          contain
          :src="settings.squareLogo"
        ></v-img>
      </v-avatar>
    </v-progress-circular>
    <slot><strong class="primary--text">Loading...</strong></slot>
  </v-card>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'PageLoader'
}
</script>

<style></style>
