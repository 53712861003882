import request from "@/services/axios_instance";

export const actions = {
    getPlans({commit}) {
        request.get(`api/upgrade/plans/apps`)
            .then(({data}) => {
                commit('updatePlans', data)
            })
            .catch((error) => console.error(error))
    },
    getCurrentSubscriptions({commit}) {
        request.get(`api/subscriptions/info`)
            .then(({data}) => {
                commit('setPaymentMethod', data.payment_method);
                commit('setMainSubscription', data.main);
                commit('setAddonsSubscriptions', data.addons);
                commit('setCompanyIsSubscribed', data.is_company_subscribed);
                commit('setCompanyIsExempted', data.is_company_exempted);
                commit('setCompanyIsOnTrial', data.is_company_on_trial);
            })
            .catch((error) => console.error(error))
    },
    getSmsMmsCredits({commit}) {
        request.get(`api/credits/smsmms`)
            .then(({data}) => {
                commit('setSmsMmsCredits', data);
            })
            .catch((error) => console.error(error))
    },
    getEmailCredits({commit}) {
        request.get(`api/credits/email`)
            .then(({data}) => {
                commit('setEmailCredits', data);
            })
            .catch((error) => console.error(error))
    }
}
