import auth from '../middleware/auth'

export const profiles = {
    path: 'profile/:username',
    component: () => import('@/modules/Profiles/Profile.vue'),
    meta: {
        middleware: [auth]
    },
    children: [
        {
            path: 'companies',
            name: 'profile',
            component: () => import('@/modules/Profiles/subpages/UserCompanies.vue'),
            props: true
        },
        {
            path: 'tasks',
            name: 'profile-tasks',
            component: () => import('@/modules/Profiles/subpages/UserTasks.vue'),
            props: true
        }, 
        {
            path: 'projects',
            name: 'profile-projects',
            component: () => import('@/modules/Profiles/subpages/UserProjects.vue'),
            props: true
        },
        {
            path: 'invoices',
            name: 'profile-invoices',
            component: () => import('@/modules/Profiles/subpages/UserInvoices.vue'),
            props: true
        }
    ]
}
