import request from '@/services/axios_instance'
export default {
    isImageOrVideo(attachment) {
        return attachment.category === 'images' || attachment.category === 'videos'
    },
    is_gif(attachment) {
        return !!(this.is_image(attachment) && attachment.filetype.includes('gif'))
    },
    is_video(attachment) {
        return attachment && attachment.category === 'videos' ? true : false
    },
    is_audio(attachment) {
        return !!(attachment && attachment.filetype.includes('audio'))
    },
    is_image(attachment) {
        return attachment && attachment.category === 'images' ? true : false
    },
    is_link(attachment) {
        return attachment && attachment.category === 'links' ? true : false
    },
    is_document(attachment) {
        return attachment && attachment.category === 'documents' ? true : false
    },
    is_others(attachment) {
        return attachment && attachment.category === 'others' ? true : false
    },
    getImagePreview(attachment, preferThumb = false) {
        if (preferThumb && !attachment.filetype.includes('svg')) {
            return this.getAttachmentThumbnail(attachment)
        }
        return attachment.url_inline
    },
    getVideoPreview(attachment) {
        return this.mediaIcon(attachment.file_ext, 'videos')
    },
    isFromOurs(url) {
        return !!(
            url.includes(this.settings.BaseURL) ||
            url.includes(this.settings.apiHostBaseURL)
        )
    },
    attachmentHasThumbnail(attachment) {
        return this.is_image(attachment)
    },
    getAttachmentThumbnail(attachment) {
        if (this.attachmentHasThumbnail(attachment)) {
            return attachment.thumbnail
        }
        return this.mediaReplacement(attachment.category)
    },
    getAttachmentIcon(attachment) {
        if (attachment.category === 'images')
            return this.getAttachmentThumbnail(attachment)
        else if (attachment.category === 'videos') {
            return this.getVideoPreview(attachment)
        } else if (attachment.category === 'documents') {
            return this.getDocumentPreview(attachment)
        } else if (attachment.category === 'others') {
            return this.getOthersPreview(attachment)
        }
        if (attachment.category === 'links') {
            return this.getLinkPreview(attachment)
        }
        return this.mediaReplacement(attachment.category)
    },
    getDocumentPreview(attachment) {
        try {
            return require(`@/assets/file-icons/${attachment.file_ext.toLowerCase()}.svg`)
                .default
        } catch (e) {
            return this.mediaReplacement('documents')
        }
    },
    getOthersPreview(attachment) {
        try {
            return require(`@/assets/file-icons/${attachment.file_ext.toLowerCase()}.svg`)
                .default
        } catch (e) {
            return this.mediaReplacement('others')
        }
    },
    getLinkPreview(attachment) {
        if (attachment.link_url && this.isGoogleProductUrl(attachment.link_url)) {
            let ext = this.getGoogleType(attachment.link_url)
            return this.mediaIcon(ext, 'links')
        }
        if (attachment.has_preview) return attachment.preview_image
        return this.mediaIcon(null, 'links')
    },
    getURLFileExtension(url) {
        return url.split(/[#?]/)[0].split('.').pop().trim()
    },
    doesFileExist(file_url) {
        var xhr = new XMLHttpRequest()
        xhr.open('HEAD', file_url, false)
        xhr.send()
        return xhr.status !== 404 ? true : false
    },
    mediaReplacement(type) {
        if (type === 'images') {
            return require('@/assets/file-icons/defaults/images.png')
        } else if (type === 'videos') {
            return require('@/assets/file-icons/defaults/videos.png')
        } else if (type === 'documents') {
            return require('@/assets/file-icons/defaults/documents.png')
        } else if (type === 'links') {
            return require('@/assets/file-icons/url.svg').default
        } //else others
        return require('@/assets/file-icons/defaults/others.png')
    },
    mediaGoogleProducts(name) {
        try {
            return require(`@/assets/file-icons/google/${name.toLowerCase()}.png`)
        } catch (e) {
            return require(`@/assets/file-icons/google/google.png`)
        }
    },
    isGoogleProductUrl(url) {
        return url.includes('google.com')
    },
    getGoogleType(url) {
        if (url.includes('https://docs.google.com/spreadsheets'))
            return 'google-spreadsheets'
        if (url.includes('https://drive.google.com')) return 'google-drive'
        if (url.includes('https://docs.google.com/presentation'))
            return 'google-slides'
        if (url.includes('https://docs.google.com/forms')) return 'google-forms'
        if (url.includes('https://docs.google.com/document')) return 'google-docs'
        if (url.includes('https://google.com')) return 'google'
        else return null
    },
    isYouTube(url) {
        const regex =
            /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/watch\?v=([^&]+)/m
        return regex.test(url)
    },
    isVimeo(url) {
        return url.includes('//vimeo.com/')
    },
    mediaIcon(ext, replacement_type = 'others') {
        if (!ext) {
            return this.mediaReplacement(replacement_type)
        }
        if (
            [
                'google',
                'google-drive',
                'google-docs',
                'google-slides',
                'google-forms',
                'google-spreadsheets'
            ].includes(ext)
        ) {
            return this.mediaGoogleProducts(ext)
        }
        try {
            return require(`@/assets/file-icons/${ext.toLowerCase()}.svg`).default
        } catch (e) {
            return this.mediaReplacement(replacement_type)
        }
    },
    testImage(url, timeoutT) {
        return new Promise(function(resolve, reject) {
            var timeout = timeoutT || 5000
            var timer,
                img = new Image()
            img.onerror = img.onabort = function() {
                clearTimeout(timer)
                reject('error')
            }
            img.onload = function() {
                clearTimeout(timer)
                resolve('success')
            }
            timer = setTimeout(function() {
                // reset .src to invalid URL so it stops previous
                // loading, but doesn't trigger new load
                img.src = '//!!!!/test.jpg'
                reject('timeout')
            }, timeout)
            img.src = url
        })
    }
}