import request from '@/services/axios_instance'
import _ from 'lodash'

const state = {
    add_picture_dialog: false,
    update_profile_dialog: false,
    update_password_dialog: false,
    profile_dialog: false,
    username: null,
    view_user: null,
    task_timers: [],
    company_timers: [],
    projects: [],
    loading: false,
    companies: [],
    selectionTab: 'profile'
}

const getters = {
    selectionTab: (state) => state.selectionTab,
    username: (state) => state.username,
    add_picture_dialog: (state) => state.add_picture_dialog,
    update_profile_dialog: (state) => state.update_profile_dialog,
    update_password_dialog: (state) => state.update_password_dialog,
    profile_dialog: (state) => state.profile_dialog,
    view_user: (state) => state.view_user,
    location: (state) => state.view_user.location || 'Unknown',
    rate: (state) => state.view_user.rate || '0.00',
    task_timers: (state) => state.task_timers,
    company_timers: (state) => state.company_timers,
    projects: (state) => state.projects,
    loading: (state) => state.loading,
    companies: (state) => state.companies,
    company: (state) => state.view_user.company,
    view_user_roles: (state) => {
        let roles = []
        if (state.view_user) {
            if (state.view_user.is_company_owner) roles.push('owner')
            if (state.view_user.is_client) roles.push('client')
            else roles.push('member')
            if (state.view_user.is_admin) roles.push('admin')
            if (state.view_user.is_manager) roles.push('manager')
            if (state.view_user.is_super_admin) roles.push('super-admin')
        }
        return roles
    },
    view_user_has_role: (state) => (role) => {
        let roles = []
        if (state.view_user) {
            if (state.view_user.is_company_owner) roles.push('owner')
            if (state.view_user.is_client) roles.push('client')
            else roles.push('member')
            if (state.view_user.is_admin) roles.push('admin')
            if (state.view_user.is_manager) roles.push('manager')
            if (state.view_user.is_super_admin) roles.push('super-admin')
        }
        return roles.includes(role)
    }
}

const mutations = {
    set_add_picture_dialog: (state, payload) =>
        (state.add_picture_dialog = payload),
    set_update_password_dialog: (state, payload) =>
        (state.update_password_dialog = payload),
    set_update_profile_dialog: (state, payload) =>
        (state.update_profile_dialog = payload),
    set_profile_dialog: (state, payload) => (state.profile_dialog = payload),
    set_view_user: (state, payload) => (state.view_user = payload),
    set_user_avatar: (state, url) => {
        let clo = _.cloneDeep(state.view_user)
        clo.image_url = url
        state.view_user = clo
    },
    set_task_timers: (state, payload) => (state.task_timers = payload),
    set_company_timers: (state, payload) => (state.company_timers = payload),
    set_projects: (state, payload) => (state.projects = payload),
    set_loading: (state, payload) => (state.loading = payload),
    set_companies: (state, payload) => (state.companies = payload),
    add_company: (state, payload) => state.companies.push(payload),
    update_company: (state, payload) => {
        let index = state.companies.findIndex((i) => i.id === payload.id)
        if (~index) {
            state.companies.splice(index, 1, payload)
        }
    },
    set_username: (state, payload) => (state.username = payload),
    set_selectionTab: (state, payload) => (state.selectionTab = payload)
}

const actions = {
    fetchUser({ commit }, username) {
        commit('set_loading', true)
        request
            .get(`api/user/${username}`)
            .then(({ data }) => {
                commit('set_view_user', data)
                commit('set_companies', data.companies)
            })
            .finally(() => commit('set_loading', false))
    },
    updateUser({ state, commit }, payload) {
        commit('set_loading', true)
        request
            .put(`api/user/${state.view_user.id}`)
            .then(({ data }) => {
                commit('set_user', data)
            })
            .finally(() => commit('set_loading', false))
    },
    createTeamUser({ commit }, { payload, cb, err_cb }) {
        commit('set_loading', true)
        request
            .post(`api/company/teams`, payload)
            .then(({ data }) => {
                commit('add_user', data, { root: true })
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .catch((err) => {
                if (typeof err_cb === 'function') {
                    err_cb(err)
                }
                throw err
            })
            .finally(() => commit('set_loading', false))
    },
    updateTeamUser({ commit }, { userId, payload, cb, err_cb }) {
        commit('set_loading', true)
        request
            .put(`api/company/teams/${userId}`, payload)
            .then(({ data }) => {
                commit('update_in_users', data, { root: true })
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .catch((err) => {
                if (typeof err_cb === 'function') {
                    err_cb(err)
                }
                throw err
            })
            .finally(() => commit('set_loading', false))
    },
    deleteTeamUser({ commit }, { userId, cb, err_cb }) {
        commit('set_loading', true)
        request
            .delete(`api/company/teams/${userId}`)
            .then(({ data }) => {
                commit('remove_from_users', userId, { root: true })
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .catch((err) => {
                if (typeof err_cb === 'function') {
                    err_cb(err)
                }
                throw err
            })
            .finally(() => commit('set_loading', false))
    },
    changeUserAvatar({ state, commit }, { attachment, cb }) {
        commit('set_loading', true)
        request
            .post(`api/user/${state.view_user.id}/update-avatar`, attachment)
            .then(({ data }) => {
                commit('set_user_avatar', data.image_url)
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .finally(() => commit('set_loading', false))
    },
    changeUserPassword({ commit }, { payload, cb, err_cb }) {
        commit('set_loading', true)
        request
            .post(`api/user/update-password`, payload)
            .then(({ data }) => {
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .catch((err) => {
                if (typeof err_cb === 'function') {
                    err_cb(err)
                }
                throw err
            })
            .finally(() => commit('set_loading', false))
    },
    createCompany({ commit }, { payload, cb, err_cb }) {
        commit('set_loading', true)
        request
            .post(`api/company/private`, payload)
            .then(({ data }) => {
                commit('add_company', data)
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .catch((err) => {
                if (typeof err_cb === 'function') {
                    err_cb(err)
                }
                throw err
            })
            .finally(() => commit('set_loading', false))
    },
    updateCompany({ commit }, { companyId, payload, cb, err_cb }) {
        commit('set_loading', true)
        request
            .put(`api/company/${companyId}/info`, payload)
            .then(({ data }) => {
                commit('update_company', data)
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .catch((err) => {
                if (typeof err_cb === 'function') {
                    err_cb(err)
                }
                throw err
            })
            .finally(() => commit('set_loading', false))
    }
}

export { state, getters, mutations, actions }