export const initial_state = () => ({
    project: {
        id: null,
        project_name: null,
        title: null,
        description: null,
        type: 'personal',
        created_at: null,
        started_at: null,
        end_at: null,
        company_id: null,
        company: null,
        creator_id: null,
        creator: null,
        client_company_id: null,
        client_company: null,
        props: [],
        service_id: null,
        service: null,
        managers: [],
        members: [],
        client: null,
        team_chat_room: null,
        client_chat_room: null,
        tasks: [],
        milestones: [],
        timelines: []
    },
    defaultState: {
        id: null,
        title: null,
        description: null,
        type: 'personal',
        created_at: null,
        started_at: null,
        end_at: null,
        company_id: null,
        company: null,
        creator_id: null,
        creator: null,
        client_company_id: null,
        client_company: null,
        props: [],
        service_id: null,
        service: null,
        managers: [],
        members: [],
        client: null,
        team_chat_room: null,
        client_chat_room: null,
        tasks: [],
        milestones: [],
        timelines: []
    },
    loading: false,
    tasks_statistic: {
        all_completed: 0,
        all_open: 0,
        all_urgent: 0,
        all_pending: 0,
        all_behind: 0,
        all_count: 0,
        my_count: 0,
        my_open: 0,
        my_completed: 0,
        my_urgent: 0,
        my_pending: 0,
        my_behind: 0
    },
    events_statistic: {
        all_upcoming_today: 0,
        all_upcoming_week: 0,
        all_upcoming_month: 0,
        all_upcoming_today_till_end_month_count: 0,
        all_upcoming_today_till_end_month_list: []
    },
    timeline_statistic: {
        today: 0,
        week: 0,
        month: 0
    },
    tasks_next_url: null,
    milestones_next_url: null,
    timelines_next_url: null,
    members_next_url: null,
    cards: [{
            title: 'Project Overview',
            slug: 'overviews',
            component: 'ProjectOverviewCard',
            column: 6,
            access: ['owner', 'admin', 'manager', 'client', 'member'],
            image: require('@/assets/svgs/images/overviews.svg').default,
            expand: false,
            active: true,
            color: 'rgba(66, 45, 133, 0.18)'
        },
        {
            title: 'Project Timeline',
            slug: 'timelines',
            component: 'ProjectTimelineCard',
            column: 6,
            access: ['owner', 'admin', 'manager', 'client', 'member'],
            image: require('@/assets/svgs/images/timelines.svg').default,
            expand: false,
            active: true,
            color: 'rgba(66, 45, 133, 0.18)'
        },
        {
            title: 'Project Tasks',
            slug: 'tasks',
            component: 'ProjectTaskCard',
            column: 6,
            order: 2,
            access: ['owner', 'admin', 'client', 'manager', 'member'],
            image: require('@/assets/svgs/images/tasks.svg').default,
            expand: false,
            active: true,
            color: 'rgba(66, 45, 133, 0.18)'
        },
        {
            title: 'Project Events',
            slug: 'events',
            component: 'ProjectEventCard',
            column: 6,
            order: 2,
            access: ['owner', 'admin', 'client', 'manager', 'member'],
            image: require('@/assets/svgs/images/alarm.svg').default,
            expand: false,
            active: true,
            color: 'rgba(66, 45, 133, 0.18)'
        }
    ]
})