import auth from "@/router/middleware/auth";

//builder for email templates
export const build_email_template_campaign = {
    path: 'builders/email-template-campaign/:id?',
    name: 'builders-email--template-campaign',
    component: () => import('@/modules/Builders/EmailCampaignTemplateBuilder.vue'),
    meta: {
        middleware: [auth]
    }
}
//builder for email signatures
export const build_email_signature = {
    path: 'builders/email-signature/:id?',
    name: 'builders-email--signature',
    component: () => import('@/modules/Builders/SignatureMaker.vue'),
    meta: {
        middleware: [auth]
    }
}

//builder for invoice templates
export const build_invoice_template = {
    path: 'builders/invoice-template/:id?',
    name: 'builders-invoice--template',
    component: () => import('@/modules/Builders/InvoiceTemplateBuilder.vue'),
    meta: {
        middleware: [auth]
    }
}

export const report_builder = {
    path: 'reports/builder/:id?',
    name: 'builders-report--template',
    component: () => import('@/modules/Builders/ReportTemplateBuilder.vue'),
    meta: {
        middleware: [auth]
    }
}
 
export const request_builder = {
    path: 'requests/builder/:id?',
    name: 'builders-request--template',
    component: () => import('@/modules/Builders/RequestTemplateBuilder.vue'),
    meta: {
        middleware: [auth]
    }
}
 