<template>
  <v-card
    class="empty"
    :id="slug"
    flat
    :min-height="minHeightNormalize"
    v-bind="$attrs"
  >
    <v-card-text class="empty-content">
      <div class="center-wrapper">
        <div class="empty-svg mb-2">
          <v-icon v-if="icon" color="primary" :size="100">{{ icon }}</v-icon>
          <v-avatar v-else tile size="100" width="200">
            <v-img
              :lazy-src="settings.loader"
              contain
              :src="settings.empty"
            ></v-img>
          </v-avatar>
        </div>
        <div class="empty-text primary--text">
          <p v-html="headline" class="fw-700"></p>
          <slot name="extra" />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  inheritAttrs: false,
  name: 'Empty',
  props: {
    icon: { type: String, default: null },
    slug: { type: String, default: 'empty-sample' },
    minHeight: { type: [Number, String], default: 100 },
    headline: { type: String, default: 'No data yet' }
  },
  computed: {
    minHeightNormalize() {
      return ['vh', '%', 'px'].some((v) => `${this.minHeight}`.includes(v))
        ? this.minHeight
        : `${this.minHeight}px`
    }
  }
}
</script>
<style lang="scss" scoped>
@include emptyTableInner('.empty');

.justify-content-center {
  display: flex;
  justify-content: center;
}

.empty {
  height: auto;
  background-color: transparent !important;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.empty-div {
  margin: 5px;
}

.empty-svg .v-icon {
  color: $btnGray;
}

.empty-content {
  display: grid;
  height: 100%;
}

.center-wrapper {
  margin: auto;
}
</style>
<style lang="scss" scoped>
@include emptyTableInner('.empty');
</style>
