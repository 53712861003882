import auth from '../middleware/auth'

export const orders = {
    path: 'orders/:id',
    name: 'view-order',
    component: () => import('@/modules/OffersOrders/subpages/ViewOrderPage.vue'),
    meta: {
        middleware: [auth]
    }
}
