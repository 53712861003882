import Vue from 'vue'
import VueTelInputVuetify from 'vue-tel-input-vuetify'
import VueGoogleApi from 'vue-google-api'
import './plugins/auth'
import './plugins/pusher'
import './plugins/eventbus'
import './plugins/vuelidate'
import './plugins/permissions'
import './plugins/subscription'
import './plugins/datetimepickers'
import './plugins/vue-components'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import router from './router/router'
import store from './store/store'
import middlewarePipeline from './router/middlewarePipeline'
import methods from '@/global_utils/global_mixin'
import file_mixin from './global_utils/file_mixin'
import { global_utils } from './global_utils/global_utils'
import { global_filters } from '@/global_utils/global_filters'
import { global_rules } from '@/global_utils/global_rules'
import { is_screen_utils } from '@/global_utils/is_screen_utils'
import { settings } from '@/variables'

Vue.use(VueTelInputVuetify, { vuetify })

Vue.use(VueGoogleApi, {
    apiKey: settings.googleApi.developerKey,
    clientId: settings.googleApi.clientId,
    scope: settings.googleApi.scope.join(' '),
    discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest']
})

// Vue.http.headers.common['Access-Control-Allow-Origin'] = '*'
Vue.config.productionTip = false

const ignoredMessage =
    'The .native modifier for v-on is only valid on components but it was used on <div>.'
Vue.config.warnHandler = (message, vm, componentTrace) => {
    if (message !== ignoredMessage) {
        console.error(message + componentTrace)
    }
}

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) return next()
    const middleware = to.meta.middleware
    const context = { to, from, next, store }
    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
})

Vue.mixin({
    data: () => ({ settings: settings }),
    computed: is_screen_utils.computed,
    methods: {...methods, ...file_mixin, ...global_utils.methods },
    filters: global_filters
})

Vue.mixin(global_rules)

store.dispatch('fetchConfigurations').then(() => {
    new Vue({
        vuetify: vuetify,
        router: router,
        store: store,
        render: (h) => h(App)
    }).$mount('#app')
})