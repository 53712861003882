import auth from '../middleware/auth'
import is_module_enabled from "../middleware/is_module_enabled";

export const globalTimer = {
    path: 'global-timer',
    name: 'globalTimer',
    component: () => import('@/modules/Timer/subpages/globalTimer/globalTimer.vue'),
    meta: {
        middleware: [auth, is_module_enabled]
    }
}
