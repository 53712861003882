import _ from "lodash";

export const getters = {
    is_fetching: state => state.is_fetching,
    grid_count: state => state.grid_count,
    dashtiles: state => state.dashtiles,
    user_cards: state => state.user_cards,
    tilesByAccess: state => (roles) => {
        if (roles.length < 1) return [];
        return state.dashtiles.filter(tile => _.intersection(roles, tile.access).length > 0);
    },
    dashcards: state => _.cloneDeep(state.dashcards).map(item => {
        item.column = Math.floor(12 / state.grid_count);
        return item;
    }),
    cardsByAccess: state => (roles) => {
        if (roles.length < 1) return [];
        return state.dashcards.filter(tile => _.intersection(roles, tile.access).length > 0);
    },
    cardsForSelection: state => {
        return _.cloneDeep(state.for_selections).filter(i => state.allowed_dashcards.includes(i.slug));
    },
    userCards: state => {
        let cards = _.cloneDeep(state.dashcards)
            .filter(item => state.user_cards.includes(item.slug) && state.allowed_dashcards.includes(item.slug))
            .map(item => {
                item.column = Math.floor(12 / state.grid_count);
                return item;
            })
        let priority = state.user_cards;
        return _.sortBy(cards, function (item) {
            return priority.indexOf(item.slug)
        });
    },
    hasMoreDashcard: state => {
        return state.for_selections.length !== state.user_cards.length ? true : false;
    },
    allowedDashcards: state => state.allowed_dashcards,
    allowedDashtiles: state => state.allowed_dashtiles
}