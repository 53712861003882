import guest from '../../middleware/guest'

export const setPassword = {
  path: '/set-password/:email/:code',
  name: 'set-password',
  component: () => import('@/modules/Authentication/SetPassword/SetPasswordV2.vue'),
  meta: {
    middleware: [guest]
  }
}
