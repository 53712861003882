import request from '@/services/axios_instance'
import moment from 'moment-timezone'

const state = {
    globalTimerIsRunning: false,
    globalTimerLoading: false,
    globalTimerRequesting: false,
    todayTimers: '00:00:00',
    weekTimers: '00:00:00',
    monthTimers: '00:00:00'
}

const getters = {
    globalTimerIsRunning: (state) => state.globalTimerIsRunning,
    globalTimerLoading: (state) => state.globalTimerLoading,
    globalTimerRequesting: (state) => state.globalTimerRequesting,
    todayTimers: (state) => state.todayTimers,
    weekTimers: (state) => state.weekTimers,
    monthTimers: (state) => state.monthTimers
}

const mutations = {
    setGlobalTimerIsRunning: (state, payload) =>
        (state.globalTimerIsRunning = payload),
    setGlobalTimerLoading: (state, payload) =>
        (state.globalTimerLoading = payload),
    setGlobalTimerRequesting: (state, payload) =>
        (state.globalTimerRequesting = payload),
    setTodayTimers: (state, payload) => (state.todayTimers = payload),
    setWeekTimers: (state, payload) => (state.weekTimers = payload),
    setMonthTimers: (state, payload) => (state.monthTimers = payload)
}

const actions = {
    toggleGlobalTimer({ commit, state }, { cb, err_cb }) {
        commit('setGlobalTimerLoading', true)
        commit('setGlobalTimerRequesting', true)
        let type = state.globalTimerIsRunning ? 'stop' : 'start'
        request
            .post(`api/company-timers/${type}`)
            .then(({ data }) => {
                commit('setGlobalTimerIsRunning', !state.globalTimerIsRunning)
                if (typeof cb === 'function') cb(data)
            })
            .catch((err) => {
                if (typeof err_cb === 'function') err_cb(data)
                throw err
            })
            .finally(() => {
                commit('setGlobalTimerLoading', false)
                commit('setGlobalTimerRequesting', false)
            })
    },
    getGlobalTimerStatus({ commit }, { userid, cb }) {
        commit('setGlobalTimerRequesting', true)
        commit('setGlobalTimerLoading', true)
        request
            .get(`api/company-timers/status/${userid}`)
            .then(({ data }) => {
                if (data) {
                    commit('setGlobalTimerIsRunning', data.action === 'start')
                    if (typeof cb === 'function') cb(data)
                }
            })
            .finally(() => {
                commit('setGlobalTimerLoading', false)
                commit('setGlobalTimerRequesting', false)
            })
    },
    getUserTimerStats({ commit }, cb) {
        commit('setGlobalTimerRequesting', true)
        commit('setGlobalTimerLoading', true)
        let tz = moment.tz.guess()
        request
            .get(`api/dashboard-contents/timers?tz=${tz}`)
            .then(({ data }) => {
                commit('setTodayTimers', data.today)
                commit('setWeekTimers', data.week)
                commit('setMonthTimers', data.month)
                commit('setGlobalTimerIsRunning', data.isRunning)
                if (typeof cb === 'function') cb(data)
            })
            .finally(() => {
                commit('setGlobalTimerLoading', false)
                commit('setGlobalTimerRequesting', false)
            })
    }
}

export { state, getters, mutations, actions }