import is_module_enabled from "../../router/middleware/is_module_enabled";

export default {
    path: 'timer',
    name: 'project-preview-timer',
    component: () => import('./Timer.vue'),
    props: true,
    meta: {
        middleware: [is_module_enabled]
    }
}
