import auth from '../middleware/auth'
export const wallets = {
    path: 'wallet',
    component: () =>
        import ('@/modules/Wallet/Wallet.vue'),
    meta: {
        middleware: [auth]
    },
    children: [{
            path: 'history',
            name: 'wallet-history',
            component: () =>
                import ('@/modules/Wallet/pages/History.vue')
        },
        {
            path: 'withdraw',
            name: 'wallet-withdraw',
            component: () =>
                import ('@/modules/Wallet/pages/Withdraw.vue')
        },
        {
            path: 'top-up',
            name: 'wallet-top-up',
            component: () =>
                import ('@/modules/Wallet/pages/TopUp.vue')
        }
    ]
}