<template>
  <v-menu
    :close-on-content-click="closeOnContentClick"
    transition="slide-x-transition"
    bottom
    right
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :style="btnStyle"
        :class="[{ error: !valid }, btnClass]"
        class="text-none"
        dark
        v-bind="$attrs"
        v-on="on"
      >
        <v-icon left v-if="leftIcon !== ''">{{ leftIcon }}</v-icon>
        <span class="light--text"> {{ label }} </span>
        <v-spacer></v-spacer>
        <v-icon right title="Invalid value" v-if="!valid">
          mdi-alert-circle
        </v-icon>
        <v-icon right>{{ rightIcon }}</v-icon>
      </v-btn>
    </template>
    <slot name="default">
      <v-list dense>
        <v-list-item>
          <v-list-item-title>No data</v-list-item-title>
        </v-list-item>
      </v-list>
    </slot>
  </v-menu>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'Menu',
  props: {
    leftIcon: { type: String, default: 'mdi-plus-circle-outline' },
    rightIcon: { type: String, default: 'mdi-menu-swap' },
    btnClass: { type: String, default: '' },
    btnStyle: { type: String, default: '' },
    hasPrepend: { type: Boolean, default: false },
    valid: { type: Boolean, default: true },
    label: { type: String, default: 'Button' },
    value: { type: [Array, Number, String, Object] },
    closeOnContentClick: { type: Boolean, default: false }
  }
}
</script>

<style></style>
