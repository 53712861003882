import _ from 'lodash'

export const getters = {
    sidebars: (state) => {
        return state.sidebars
    },
    activeSidebar: (state) => state.activeSidebar,
    userNavigations: (state) => (roles) => {
        return state.user_navigations.filter(
            (tile) => _.intersection(roles, tile.access).length > 0
        )
    },
    rightSidebar: (state) => state.rightSidebar,
    leftSidebar: (state) => state.leftSidebar,
    cardSidebar: (state) => state.cardSidebar,
    connectSidebar: (state) => state.connectSidebar,
    messageSidebar: (state) => state.messageSidebar,
    notificationSidebar: (state) => state.notificationSidebar,
    collapseWidth: (state) => state.collapseWidth,
    miniWidth: (state) => state.miniWidth,
    type: (state) => state.type,
    clipped: (state) => state.clipped,
    floating: (state) => state.floating,
    mini: (state) => state.mini,
    hasFooter: (state) => state.hasFooter,
    connects: (state) => {
        return _.cloneDeep(state.connects).sort((x) => (x.available ? -1 : 1))
    },
    allowedConnects: (state) => state.allowed_connects,
    allowedModules: (state) => state.allowed_modules,
    sidebars_for_modules: (state, getters, rootState) => {
        return []
    }
}