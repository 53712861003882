<template>
  <v-snackbar v-model="snackbar" :color="color" :timeout="timeout" multi-line>
    <template v-slot:default>
      <v-row
        dense
        align="center"
        justify="space-between"
        class="flex-nowrap px-2"
      >
        <v-icon color="white" large left>{{ icon }}</v-icon>
        <div
          class="
            pa-1
            d-flex
            mx-auto
            fw-700
            fs-16
            flex-wrap flex-column
            text-center
          "
          v-html="text"
        ></div>
      </v-row>
    </template>
    <template v-slot:action="{ attrs }">
      <v-btn icon @click="snackbar = false" v-bind="attrs">
        <v-icon color="white" large> mdi-close-circle-outline </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  data() {
    return {
      snackbar: false,
      text: null,
      color: null,
      timeout: null,
      icon: null
    }
  },

  created() {
    this.$event.$on(
      'open_snackbar',
      (text, type = 'success', timeout = 4000) => {
        if (!text) return
        this.snackbar = true
        this.text = text
        this.color = this.get_color(type)
        this.timeout = timeout
        this.icon = this.get_icon(type)
      }
    )
  },

  computed: {
    snackbar_store() {
      return this.$store.getters.snackbar
    }
  },

  watch: {
    snackbar_store(snackbar) {
      snackbar.status &&
        this.$event.$emit('open_snackbar', snackbar.message, 'error')
    }
  },

  methods: {
    get_color(type) {
      const colors = {
        error: 'red',
        notification: 'orange',
        success: 'green',
        info: 'primary'
      }
      return colors[type]
    },

    get_icon(type) {
      const icons = {
        error: 'mdi-alert-box',
        notification: 'mdi-bell-outline',
        success: 'mdi-check-circle',
        info: 'mdi-information-outline'
      }
      return icons[type]
    }
  }
}
</script>
<style lang="scss">
.v-snack:not(.v-snack--absolute) {
  z-index: 1002 !important;
}
</style>
