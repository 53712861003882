import _ from 'lodash'
import request from '@/services/axios_instance'

const state = {
    brightlocal: null,
    tool_id: null,
    api_key: null,
    api_secret: null,
    clients: [],
    client: null,
    project_id: null,
    processing: false,
    cards: [{
            title: 'Reputation Manager',
            slug: 'reputation_manager',
            component: 'ReputationManagerCard',
            column: Math.floor(12 / 6),
            order: 0,
            access: ['owner', 'admin', 'manager', 'member', 'client'],
            icon: 'mdi-timeline',
            image: require('@/assets/svgs/images/card-timeline.svg?internal').default,
            data: {},
            selected: false,
            active: true,
            color: 'rgba(3, 252, 82, 0.14)'
        },
        {
            title: 'Citations',
            slug: 'citations_tracker',
            component: 'CitationTrackerCard',
            column: Math.floor(12 / 6),
            order: 0,
            access: ['owner', 'admin', 'manager', 'member', 'client'],
            icon: 'mdi-timeline',
            image: require('@/assets/svgs/images/card-timeline.svg?internal').default,
            data: {},
            selected: false,
            active: true,
            color: 'rgba(3, 252, 82, 0.14)'
        },
        {
            title: 'Google Ranking',
            slug: 'google_ranking',
            component: 'GoogleRankingCard',
            column: Math.floor(12 / 6),
            order: 0,
            access: ['owner', 'admin', 'manager', 'member', 'client'],
            icon: 'mdi-timeline',
            image: require('@/assets/svgs/images/card-timeline.svg?internal').default,
            data: {},
            selected: false,
            active: true,
            color: 'rgba(3, 252, 82, 0.14)'
        },
        {
            title: 'Google My Business',
            slug: 'google_my_business',
            component: 'GoogleMyBusinessCard',
            column: Math.floor(12 / 6),
            order: 0,
            access: ['owner', 'admin', 'manager', 'member', 'client'],
            icon: 'mdi-timeline',
            image: require('@/assets/svgs/images/card-timeline.svg?internal').default,
            data: {},
            selected: false,
            active: true,
            color: 'rgba(3, 252, 82, 0.14)'
        },
        {
            title: 'Google Analytics',
            slug: 'google_analytics',
            component: 'GoogleAnalyticsCard',
            column: Math.floor(12 / 6),
            order: 0,
            access: ['owner', 'admin', 'manager', 'member', 'client'],
            icon: 'mdi-timeline',
            image: require('@/assets/svgs/images/card-timeline.svg?internal').default,
            data: {},
            selected: false,
            active: true,
            color: 'rgba(3, 252, 82, 0.14)'
        }
    ]
}

const getters = {
    cards: (state) => state.cards,
    brightlocal: (state) => state.brightlocal,
    tool_id: (state) => state.tool_id,
    clients: (state) => _.uniqBy(state.clients, 'client-id'),
    client: (state) => state.client,
    api_key: (state) => state.api_key,
    api_secret: (state) => state.api_secret,
    project_id: (state) => state.project_id,
    processing: (state) => state.processing
}

const mutations = {
    set_brightlocal: (state, payload) => (state.brightlocal = payload),
    set_tool_id: (state, payload) => (state.tool_id = payload),
    set_clients: (state, payload) => (state.clients = payload),
    set_client: (state, payload) => (state.client = payload),
    add_client: (state, payload) => {
        state.clients.unshift(payload)
    },
    set_api_key: (state, payload) => (state.api_key = payload),
    set_api_secret: (state, payload) => (state.api_secret = payload),
    set_project_id: (state, payload) => (state.project_id = payload),
    set_processing: (state, payload) => (state.processing = payload)
}

const actions = {
    fetchBrightLocal({ commit }, project_id) {
        commit('set_processing', true)
        request
            .get(`api/brightlocal/${project_id}`)
            .then(({ data }) => {
                commit('set_brightlocal', data.tool)
                commit('set_client', data.client)
            })
            .finally(() => commit('set_processing', false))
    },
    createApi({ commit }, { payload, cb }) {
        commit('set_processing', true)
        request
            .post(`api/brightlocal/apis`, payload)
            .then(({ data }) => {
                commit('set_tool_id', data.id)
                if (typeof cb === 'function') cb(data)
            })
            .finally(() => commit('set_processing', false))
    },
    createClient({ commit, state }, { payload, cb }) {
        commit('set_processing', true)
        request
            .post(`api/brightlocal/${state.tool_id}/clients`, payload)
            .then(({ data }) => {
                commit('add_client', data)
                if (typeof cb === 'function') cb(data)
            })
            .finally(() => commit('set_processing', false))
    },
    searchClients({ commit, state }, query) {
        commit('set_processing', true)
        request
            .get(`api/brightlocal/${state.tool_id}/clients?keyword=${query}`)
            .then(({ data }) => {
                if (data.success) {
                    commit('set_clients', data.clients)
                    if (!data.clients.length) {
                        commit(
                            'open_snackbar', {
                                status: true,
                                type: 'error',
                                message: 'No result found for ' + query
                            }, { root: true }
                        )
                    }
                } else {
                    commit(
                        'open_snackbar', { status: true, type: 'error', message: 'Error on searching..' }, { root: true }
                    )
                }
            })
            .finally(() => commit('set_processing', false))
    },
    attachClient({ commit }, { client_id, cb }) {
        commit('set_processing', true)
        request
            .post(`api/brightlocal/${state.tool_id}/clients/attach`, {
                client_id
            })
            .then(({ data }) => {
                commit('set_client', data)
                if (typeof cb === 'function') cb(data)
            })
            .finally(() => commit('set_processing', false))
    }
}

export { state, getters, mutations, actions }