import auth from "../middleware/auth";
import is_module_enabled from "../middleware/is_module_enabled";

export const calendar = {
  path: "calendar",
  name: "calendar",
  component: () => import("@/modules/CalendarV2/Calendar.vue"),
  meta: {
    middleware: [auth],
  },
};
