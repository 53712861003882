import request from "@/services/axios_instance";
import { settings } from '@/variables'
import _ from 'lodash'

const state = {
    smsmms_credits: {
        balance: 0.00,
        last_recharge_date: null,
        last_recharge_amount: null
    },
    email_credits: {
        balance: 0.00,
        last_recharge_date: null,
        last_recharge_amount: null
    },
    is_company_subscribed: false,
    is_company_exempted: false,
    is_company_on_trial: false,
    payment_method: null,
    others: {
        name: 'Direct Access to Incoming Features ',
        slug: 'others',
        description: 'Direct access to incoming features',
        active: 1,
        isAddon: false,
        price_id: null,
        product_id: null,
        plan_amount: 0.00,
        access_for_personal: false,
        access_for_small_business: false,
        access_for_agency: true,
        addon_for_personal: false,
        addon_for_small_business: false,
        addon_for_agency: false
    },
    main_modules: [],
    main_plans: [],
    purchasable_addons: [],
    main_selected: null,
    addons_selected: [],
    current_main: null,
    current_addons: [],//ids
    current_main_subscription: null,
    current_addons_subscriptions: [],
}

const getters = {
    smsmms_credits: state => state.smsmms_credits,
    email_credits: state => state.email_credits,
    paymentMethod: state => state.payment_method,
    is_company_subscribed: state => state.is_company_subscribed,
    is_company_exempted: state => state.is_company_exempted,
    is_subscribed_or_exempted: state => !!(state.is_company_exempted || state.is_company_subscribed),
    is_company_on_trial: state => state.is_company_on_trial,
    mainSelected: state => state.main_selected,
    addonsSelected: state => state.addons_selected,
    allModules: state => {
        let all = _.cloneDeep(state.main_modules);
        all.push(state.others)
        return all.sort((a, b) => (b.access_for_personal - a.access_for_personal))
    },
    subscribleMainPlans: state => {
        return _.cloneDeep(state.main_plans).map(item => {
            item.subscribed = state.current_main ? item.slug === state.current_main.slug : false;
            item.selected = state.main_selected ? item.slug === state.main_selected.slug : false;
            return item;
        })
    },
    subscribleAddons: state => {
        if (!state.main_selected) return [];
        return _.cloneDeep(state.main_modules).filter(i => i[`addon_for_${state.main_selected.slug}`] && i.is_addon).map(item => {
            item.selected = state.addons_selected.includes(item.slug);
            return item;
        });
    },
    totals: state => state.main_selected ? state.main_selected.plan_amount : 0.00,
}

const mutations = {
    set_MainPlans: (state, payload) => (state.main_plans = payload),
    set_MainModules: (state, payload) => (state.main_modules = payload),
    setPaymentMethod: (state, data) => (state.payment_method = data),
    setCompanyIsSubscribed: (state, value) => (state.is_company_subscribed = value),
    setCompanyIsExempted: (state, value) => (state.is_company_exempted = value),
    setCompanyIsOnTrial: (state, value) => (state.is_company_on_trial = value),
    selectMainSelected: (state, plan) => {
        if (state.main_selected && state.main_selected.slug === plan.slug) {
            state.main_selected = null; //deselect
        } else
            state.main_selected = plan;
    },
    clearMainSelected: (state) => (state.main_selected = null),
    clearAllSelected: (state) => {
        state.addons_selected = []
        state.main_selected = null
    },
    toggleAddonsSelected: (state, plan) => {
        let index = state.addons_selected.findIndex(item => item.slug === plan.slug)
        if (~index) {
            state.addons_selected.splice(index, 1);
        } else {
            state.addons_selected.push(plan.id);
        }
    },
    setSmsMmsCredits(state, credits) {
        state.smsmms_credits = credits
    },
    setEmailCredits(state, credits) {
        state.email_credits = credits
    },
    setMainSubscription: (state, data) => (state.current_main_subscription = data),
    setAddonsSubscriptions: (state, data) => (state.current_addons_subscriptions = data),
    setPurchasableAddons: (state, data) => (state.purchasable_addons = data),
}

const actions = {
    getCurrentSubscriptions({ commit }) {
        request.get(`api/subscriptions/info`)
            .then(({ data }) => {
                commit('setPaymentMethod', data.payment_method);
                commit('setPurchasableAddons', data.purchasable_addons);
                commit('setMainSubscription', data.main);
                commit('setAddonsSubscriptions', data.addons);
                commit('setCompanyIsSubscribed', data.is_company_subscribed);
                commit('setCompanyIsExempted', data.is_company_exempted);
                commit('setCompanyIsOnTrial', data.is_company_on_trial);
            })
            .catch((error) => console.error(error))
    },
    getSmsMmsCredits({ commit }) {
        request.get(`api/credits/smsmms`)
            .then(({ data }) => {
                commit('setSmsMmsCredits', data);
            })
            .catch((error) => console.error(error))
    },
    getEmailCredits({ commit }) {
        request.get(`api/credits/email`)
            .then(({ data }) => {
                commit('setEmailCredits', data);
            })
            .catch((error) => console.error(error))
    }
}

export { state, getters, mutations, actions }