import moment from 'moment/moment'
import _cloneDeep from 'lodash/cloneDeep'

export const mutations = {
    set_create_mode: (state, payload) => (state.createMode = payload),
    set_id: (state, payload) => (state.invoice.id = payload),
    add_new_item: (state, payload) => state.invoice.items.push(payload),
    add_items: (state, payload) => state.invoice.items.push(...payload),
    delete_item: (state, index) => state.invoice.items.splice(index, 1),
    update_item: (state, { item, index }) => state.invoice.items.splice(index, 1, item),
    clear_items: state => (state.invoice.items = []),
    set_items: (state, payload) => (state.invoice.items = payload),
    set_project: (state, payload) => (state.invoice.project_id = payload && payload.hasOwnProperty('id') ? payload.id : payload),
    set_service: (state, payload) => (state.invoice.service_id = payload && payload.hasOwnProperty('id') ? payload.id : payload),
    set_order: (state, payload) => (state.invoice.order_id = payload && payload.hasOwnProperty('id') ? payload.id : payload),
    set_template: (state, payload) => (state.invoice.template_id = payload && payload.hasOwnProperty('id') ? payload.id : payload),
    set_parent: (state, payload) => (state.invoice.parent_id = payload && payload.hasOwnProperty('id') ? payload.id : payload),
    set_type: (state, payload) => (state.invoice.type = payload),
    set_terms: (state, payload) => (state.invoice.terms = payload),
    set_notes: (state, payload) => (state.invoice.notes = payload),
    set_title: (state, newtitle) => (state.invoice.title = newtitle),
    set_billable_to_type: (state, payload) => (state.invoice.billable_to_type = payload),
    set_billable_to_id: (state, payload) => (state.invoice.billable_to_id = payload && payload.hasOwnProperty('id') ? payload.id : payload),
    set_billable_from_type: (state, payload) => (state.invoice.billable_from_type = payload),
    set_billable_from_id: (state, payload) => (state.invoice.billable_from_id = payload && payload.hasOwnProperty('id') ? payload.id : payload),
    set_props: (state, payload) => (state.invoice.props = payload),
    set_is_recurring: (state, payload) => (state.invoice.is_recurring = payload),
    toggle_visibility: (state, { field, value }) => (state.invoice[field].show = value),
    set_tax: (state, payload) => (state.invoice.tax = payload),
    set_discount: (state, payload) => (state.invoice.discount = payload),
    set_shipping_fee: (state, payload) => (state.invoice.shipping_fee = payload),
    set_field: (state, { new_val, field }) => (state.invoice[field].value = Number(new_val)),
    set_billing_date: (state, payload) => (state.invoice.billing_date = payload),
    set_billing_date_recurring: (state, payload) => (state.invoice.billing_date_recurring = payload),
    set_billing_date_normalize: (state, payload) => (state.invoice.billing_date_normalize = payload),
    set_due_date: (state, payload) => (state.invoice.due_date = payload),
    set_due_date_recurring: (state, payload) => (state.invoice.due_date_recurring = payload),
    set_due_date_normalize: (state, payload) => (state.invoice.due_date_normalize = payload),
    set_projects: (state, payload) => (state.projects = payload),
    set_services: (state, payload) => (state.services = payload),
    set_orders: (state, payload) => (state.orders = payload),
    set_tasks: (state, payload) => (state.tasks = payload),
    append_tasks: (state, payload) => state.tasks.push(...payload),
    set_tasks_next_url: (state, payload) => (state.tasks_next_url = payload),
    set_templates: (state, payload) => (state.templates = payload),
    set_users: (state, payload) => (state.users = payload),
    set_leads: (state, payload) => (state.leads = payload),
    set_currencies: (state, payload) => (state.currencies = payload),
    set_currency: (state, payload) => (state.invoice.currency = payload),
    set_currency_symbol: (state, payload) => (state.invoice.currency_symbol = payload),
    set_props_key: (state, { key, value }) => (state.invoice.props[key] = value),
    set_colors: (state, payload) => (state.invoice.colors = payload),
    set_save_colors: (state, payload) => (state.invoice.save_colors = payload),
    set_preview: (state, payload) => {
        state.preview = payload;
        state.preview_state = moment().unix();
    },
    rollback_invoice: (state) => (state.invoice = _cloneDeep(state.invoice_copy)),
    set_invoice: (state, payload) => (state.invoice = payload),
    set_is_editting: (state, payload) => (state.is_editting = payload),
    set_invoice_view: (state, payload) => (state.invoice_view = payload),
    editting_invoice: (state, payload) => {
        state.invoice_copy = _cloneDeep(payload);
        state.invoice.id = payload.id;
        state.invoice.billable_from_type = payload.billable_from_type;
        state.invoice.billable_from_id = payload.billable_from_id;
        state.invoice.billable_to_type = payload.billable_to_type;
        state.invoice.billable_to_id = payload.billable_to_id;
        state.invoice.billing_date = payload.billing_date;
        state.invoice.billing_date_recurring = payload.billing_date_recurring;
        state.invoice.due_date = payload.due_date;
        state.invoice.due_date_recurring = payload.due_date_recurring;
        state.invoice.items = payload.items || [{ description: null, quantity: 1, units: 'hour', price_per_unit: 0, total: 0, props: null }];
        state.invoice.project_id = payload.project_id || null;
        state.invoice.service_id = payload.service_id || null;
        state.invoice.template_id = payload.template_id || null;
        state.invoice.order_id = payload.order_id || null;
        state.invoice.parent_id = payload.parent_id || null;
        state.invoice.creator_id = payload.creator_id || null;
        state.invoice.title = payload.title;
        state.invoice.type = payload.type;
        state.invoice.notes = payload.notes;
        state.invoice.terms = payload.terms;
        state.invoice.currency = payload.currency;
        state.invoice.currency_symbol = payload.currency_symbol;
        state.invoice.is_recurring = payload.is_recurring;
        state.invoice.props = payload.props || { send_email: 'no' };
        state.invoice.tax = payload.tax || { type: 'fixed', value: 0.00, show: false };
        state.invoice.discount = payload.discount || { type: 'fixed', value: 0.00, show: false };
        state.invoice.shipping_fee = payload.shipping_fee || { type: 'fixed', value: 0.00, show: false };
        state.invoice_edit = _cloneDeep(state.invoice)
        state.is_editting = true;
    },
    clear_invoice: (state) => {
        state.invoice.id = null;
        state.invoice.billable_from_type = 'App\\User';
        state.invoice.billable_from_id = null;
        state.invoice.billable_to_type = 'App\\User';
        state.invoice.billable_to_id = null;
        state.invoice.billing_date = moment().startOf('month').format('YYYY-MM-DD');
        state.invoice.billing_date_recurring = null;
        state.invoice.due_date = moment().endOf('month').format('YYYY-MM-DD');
        state.invoice.due_date_recurring = null;
        state.invoice.items = [{ description: null, quantity: 1, units: 'hour', price_per_unit: 0, total: 0, props: null }]
        state.invoice.project_id = null;
        state.invoice.template_id = null;
        state.invoice.service_id = null;
        state.invoice.creator_id = null;
        state.invoice.order_id = null;
        state.invoice.parent_id = null;
        state.invoice.title = null;
        state.invoice.type = 'monthly';
        state.invoice.status = 'pending';
        state.invoice.notes = null;
        state.invoice.terms = null;
        state.invoice.is_recurring = 0;
        state.invoice.props = { send_email: 'no' };
        state.invoice.tax = { type: 'fixed', value: null, show: false };
        state.invoice.discount = { type: 'fixed', value: null, show: false };
        state.invoice.shipping_fee = { type: 'fixed', value: null, show: false };
        state.invoice_edit = null;
        state.invoice_copy = null;
        state.invoice.currency = 'USD';
        state.invoice.currency_symbol = '$';
        state.is_editting = false;
    },
    set_invoice_logo: (state, payload) => {
        state.invoice_logo = payload
    },
    set_invoice_company: (state, payload) => {
        state.invoice_company = payload
    },
    set_save_taxes: (state, payload) => {
        state.invoice_save_taxes = payload
    },
    add_save_taxes: (state, payload) => {
        state.invoice_save_taxes.push(payload)
    },
    delete_save_taxes: (state, id) => {
        let index = state.invoice_save_taxes.findIndex(i => i.id === id);
        if (~index) {
            state.invoice_save_taxes.splice(index, 1)
        }
    }
}