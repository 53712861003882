<template>
  <CustomDialog
    :open.sync="open"
    :title="title"
    :max-width="1366"
    @button1="close_dialog"
    :has-footer="false"
  >
    <template v-slot:content>
      <v-card flat v-if="activeItem">
        <v-card-text class="pt-0 pb-2">
          <v-row dense class="fill-height">
            <v-col cols="8">
              <v-row dense class="fill-height">
                <v-col style="min-height: 450px" cols="12">
                  <div
                    class="fill-height border-1 border-color--grey rounded-10"
                    align="center"
                    justify="center"
                  >
                    <pinch-zoom
                      v-if="activeItem && is_image(activeItem)"
                      style="height: 100%; width: 100%"
                    >
                      <Attachment :item="activeItem" :key="ckey"></Attachment>
                    </pinch-zoom>
                    <Attachment
                      v-else
                      :item="activeItem"
                      :class="`mx-auto`"
                      :key="ckey"
                    ></Attachment>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4" class="rounded-10">
              <div class="fill-height border-1 border-color--grey rounded-10">
                <v-card flat class="rounded-10">
                  <v-toolbar elevation="0">
                    <v-toolbar-title class="title text--grey">
                      Attachment
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-list dense v-if="activeItem">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="subtitle-2">
                          {{ activeItem.filename }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="overline">
                          {{ activeItem.filetype }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="primary"
                                class="text-none"
                                v-bind="attrs"
                                v-on="on"
                                :href="activeItem.url"
                              >
                                <v-icon left>mdi-cloud-download</v-icon>
                                Download File
                              </v-btn>
                            </template>
                            <span>Download File</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                text
                                outlined
                                class="text-none ml-1"
                                v-bind="attrs"
                                v-on="on"
                                disabled
                              >
                                <v-icon left>mdi-share</v-icon>
                                Share
                              </v-btn>
                            </template>
                            <span>Share</span>
                          </v-tooltip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'

export default {
  name: 'AttachmentSoloViewer',
  components: {
    CustomDialog
  },
  props: {
    attachment: { type: Object },
    dialog: { type: Boolean, default: false },
    value: { type: Boolean, default: false }
  },
  data: () => ({
    open: false,
    ckey: 0,
    activeItem: null
  }),
  computed: {
    title() {
      return this.activeItem ? this.activeItem.filename : 'Attachment'
    }
  },
  watch: {
    dialog(val) {
      this.open = val
    },
    value(val) {
      this.open = val
    },
    open(val) {
      this.$emit('update:dialog', val)
      this.$emit('input', val)
    },
    attachment: {
      handler(val) {
        this.activeItem = val
        this.ckey++
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    close_dialog() {
      this.open = false
    },
    is_image(attachment) {
      return attachment && attachment.filetype.includes('image')
    },
    is_video(attachment) {
      return attachment && attachment.filetype.includes('video')
    }
  }
}
</script>

<style scoped lang="scss"></style>
