import axios from 'axios'
import store from '@/store/store'
import { settings } from '@/variables'

const request = axios.create({
    baseURL: settings.apiHostBaseURL,
    timeout: 500000,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json'
    },
    withCredentials: false
})

request.interceptors.request.use(
    (request) => {
        request.headers.Authorization = 'Bearer ' + settings.token()
        return request
    },
    (error) => {
        return Promise.reject(error)
    }
)

request.interceptors.response.use((response) => {
    return response
}, handleAxiosError)

function handleAxiosError(err) {
    if (err.response.status === 401) {
        store.commit('open_snackbar', {
            status: true,
            message: 'Unauthenticated. Redirecting to login.'
        })
        setTimeout(() => {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            window.location = settings.BaseURL + '/login'
        }, 200)
        return Promise.reject(err)
    }
    if (err.response) {
        let message = err.response.data.message
        if (typeof err.response.data.errors != 'undefined') {
            let errors = Object.values(err.response.data.errors)
            errors = errors.flat()
            for (let index = errors.length - 1; index >= 0; index--) {
                message = message + `<br>` + errors[index]
            }
        }
        store.commit('open_snackbar', {
            status: true,
            message: message
        })
    }
    // this will trigger the catch function in the promise chain
    return Promise.reject(err)
}
export default request