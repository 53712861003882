import request from '@/services/axios_instance'

const state = {
    campaign: null,
    mailers: [],
    leads: [],
    selected_leads: [],
    lead_groups: [],
    signatures: [],
    targets: [],
    templates: [],
    email_mapping: {
        id: null,
        name: null,
        attachments: [], //id of attachment table
        subject: null,
        cc: null,
        from_email: null,
        reply_to: null,
        email_response_to: null,
        email_signature: null,
        groups: [], //id of lead_groups
        leads: [] //id of leads
    },
    smsmms_mapping: {
        name: null,
        message_type: 'sms',
        groups: [], //id of lead_groups
        leads: [], //id of leads
        reply_to: null,
        email_response_to: null,
        op_hours_from: '00:01',
        op_hours_to: '23:59'
    },
    recipient_type: 'groups',
    iteration_items: [],
    placeholders: [
        'target_first_name',
        'target_last_name',
        'target_fullname',
        'target_organization',
        'target_email',
        'from_website',
        'from_organization'
    ],
    twilio_numbers: []
}

const getters = {
    campaign: (state) => state.campaign,
    mailers: (state) => state.mailers,
    leads: (state) => state.leads,
    selected_leads: (state) => state.selected_leads,
    lead_groups: (state) => state.lead_groups,
    signatures: (state) => state.signatures,
    targets: (state) => state.targets,
    templates: (state) => state.templates,
    email_mapping: (state) => state.email_mapping,
    recipient_type: (state) => state.recipient_type,
    iteration_items: (state) => state.iteration_items,
    placeholders: (state) => state.placeholders,
    twilio_numbers: (state) => state.twilio_numbers
}

const mutations = {
    set_campaign: (state, payload) => (state.campaign = payload),
    set_leads: (state, payload) => (state.leads = payload),
    set_selected_leads: (state, payload) => (state.selected_leads = payload),
    set_lead_groups: (state, payload) => (state.lead_groups = payload),
    set_signatures: (state, payload) => (state.signatures = payload),
    set_targets: (state, payload) => (state.targets = payload),
    set_templates: (state, payload) => (state.templates = payload),
    set_email_mapping: (state, payload) => (state.email_mapping = payload),
    set_recipient_type: (state, payload) => (state.recipient_type = payload),
    set_iteration_items: (state, payload) => (state.iteration_items = payload),
    set_placeholders: (state, payload) => (state.placeholders = payload),
    set_twilio_numbers: (state, payload) => (state.twilio_numbers = payload),
    set_mailers: (state, payload) => (state.mailers = payload)
}

const actions = {
    getSelectedLeads: ({ commit }, ids) => {
        request.post(`api/leads/selected`, { ids: ids }).then(({ data }) => {
            commit('set_selected_leads', data)
        })
    },
    getGroups: ({ commit }) => {
        request.get(`api/leads/groups?all=true`).then(({ data }) => {
            commit('set_lead_groups', data)
        })
    },
    fetchEmailTemplates: ({ commit }) => {
        request.get(`api/emails/accessories/template`).then(({ data }) => {
            commit('set_templates', data)
        })
    },
    fetchEmailSignatures: ({ commit }) => {
        request.get(`api/emails/accessories/signature`).then(({ data }) => {
            commit('set_signatures', data)
        })
    },
    fetchCampaign: ({ commit }, { id, cb }) => {
        request.get(`api/lead-campaign/${id}`).then(({ data }) => {
            commit('set_campaign', data)
            if (typeof cb === 'function') cb(data)
        })
    },
    fetchTwilioNumbers: ({ commit }, cb) => {
        request.get(`api/twilio/our-numbers`).then(({ data }) => {
            commit('set_twilio_numbers', data)
            if (typeof cb === 'function') cb(data)
        })
    },
    fetchMailers: ({ commit }, cb) => {
        request.get(`api/mailers`).then(({ data }) => {
            commit('set_mailers', data)
            if (typeof cb === 'function') cb(data)
        })
    }
}

export { state, getters, mutations, actions }