export default function is_module_enabled({ next, store, to }) {
    if (store.getters.maintenance_mode) {
        next({ name: 'maintenance_mode' })
        return
    }
    let modules = store.getters['configs/modules'] || null
    let name = to.name || false
    const excepts = ['leads', 'lead_groups']

    if (name && modules) {
        if (name === 'team') name = 'teams'
        else if (name === 'services-list') name = 'services'
        else if (name === 'campaigns') name = 'campaign'
        else if (name === 'invoice') name = 'invoices'
        else if (name === 'calendar') name = 'calendars'
        else if (name === 'forms/responses') name = 'forms'
        else if (
            ['globalTimer', 'taskTimer', 'project-preview-timer'].includes(name)
        )
            name = 'timers'
        else if (['project_view', 'preview', 'hq'].includes(name)) name = 'projects'
        else if (
            [
                'templates',
                'builders-report--template',
                'report-builder-edit',
                'request-builder-create',
                'request-builder-edit'
            ].includes(name)
        )
            name = 'templates'
        else if (name === 'project-preview-reports') name = 'reports'
        else if (name === 'project-chats') name = 'chat'
        else if (['offers', 'category', 'manage-categories'].includes(name))
            name = 'offers'
        else if (
            ['support', 'support-updates-and-fixes', 'support-faqs'].includes(name)
        )
            name = 'support'

        if ((modules && modules[name]) || excepts.includes(name)) {
            return next()
        } else return next({ name: 'not_available' }) //go back to dashboard
    } else {
        return next()
    }
}