export const mutations = {
    set_appModules: (state, payload) => (state.app_modules = payload),
    setCompanyIsSubscribed(state, value) {
        state.is_company_subscribed = value;
    },
    setCompanyIsExempted(state, value) {
        state.is_company_exempted = value;
    },
    setCompanyIsOnTrial(state, value) {
        state.is_company_on_trial = value;
    },
    setMainSubscription(state, data) {
        state.current_main_subscription = data;
    },
    setAddonsSubscriptions(state, data) {
        state.current_addons_subscriptions = data;
    },
    setPaymentMethod(state, data) {
        state.payment_method = data;
    },
    setSmsMmsCredits(state, credits) {
        state.smsmms_credits = credits
    },
    setEmailCredits(state, credits) {
        state.email_credits = credits
    },
    updatePlans(state, plans) {
        state.plans = plans;
    },
    selectMainSelected(state, plan) {
        if (state.main_selected && state.main_selected.id === plan.id) {
            state.main_selected = null; //deselect
        } else
            state.main_selected = plan;
    },
    clearMainSelected(state) {
        state.main_selected = null;
    },
    addToAddonsSelected(state, plan) {
        let index = state.addons_selected.findIndex(item => item.id === plan.id)
        if (~index) {
            state.addons_selected.splice(index, 1);
        } else {
            plan.quantity = 1;
            state.addons_selected.push(plan);
        }
    },
    removeFromAddonsSelected(state, index) {
        state.addons_selected.splice(index, 1);
    },
    updateAddonsSelected(state, plans) {
        state.addons_selected = plans;
    },
    clearAllSelected(state) {
        state.addons_selected = []
        state.main_selected = null
    }
}