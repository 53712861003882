import on_maintenance_mode from '../middleware/mentainance_mode'
export const notFound = {
    path: '/404',
    alias: '*',
    name: 'not_found',
    component: () =>
        import ('@/modules/NotFound/NotFound.vue'),
    meta: { middleware: [on_maintenance_mode] }
}

export const maintenance_mode = {
    path: '/503-MAINTENANCE',
    name: 'maintenance_mode',
    component: () =>
        import ('@/modules/NotFound/MaintenanceMode.vue')
}