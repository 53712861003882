import _ from 'lodash'
export const mutations = {
    set_id: (state, payload) => (state.project.id = payload),
    set_title: (state, payload) => (state.project.title = payload),
    set_project_name: (state, payload) => (state.project.project_name = payload),
    set_description: (state, payload) => (state.project.description = payload),
    set_type: (state, payload) => (state.project.type = payload),
    set_created_at: (state, payload) => (state.project.created_at = payload),
    set_started_at: (state, payload) => (state.project.started_at = payload),
    set_end_at: (state, payload) => (state.project.end_at = payload),
    set_company_id: (state, payload) => (state.project.company_id = payload),
    set_company: (state, payload) => (state.project.company = payload),
    set_creator_id: (state, payload) => (state.project.creator_id = payload),
    set_creator: (state, payload) => (state.project.creator = payload),
    set_client_company_id: (state, payload) =>
        (state.project.client_company_id = payload),
    set_client_company: (state, payload) =>
        (state.project.client_company = payload),
    set_props: (state, payload) => (state.project.props = payload),
    set_service_id: (state, payload) => (state.project.service_id = payload),
    set_service: (state, payload) => (state.project.service = payload),
    set_managers: (state, payload) => (state.project.managers = payload),
    add_managers: (state, payload) => state.project.managers.push(...payload),
    remove_managers: (state, id) => {
        let index = state.project.managers.findIndex((i) => i.id === id)
        if (~index) state.project.managers.splice(index, 1)
    },
    set_members: (state, payload) => (state.project.members = payload),
    add_members: (state, payload) => state.project.members.push(...payload),
    remove_members: (state, id) => {
        let index = state.project.members.findIndex((i) => i.id === id)
        if (~index) state.project.members.splice(index, 1)
    },
    set_client: (state, payload) => (state.project.client = payload),
    set_team_chat_room: (state, payload) =>
        (state.project.team_chat_room = payload),
    set_client_chat_room: (state, payload) =>
        (state.project.client_chat_room = payload),
    reset_project: (state) => (state.project = _.cloneDeep(state.defaultState)),
    set_tasks: (state, payload) => (state.project.tasks = payload),
    set_milestones: (state, payload) => (state.project.milestones = payload),
    set_timelines: (state, payload) => (state.project.timelines = payload),
    append_tasks: (state, payload) => state.project.tasks.push(...payload),
    append_milestones: (state, payload) =>
        state.project.milestones.push(...payload),
    append_timelines: (state, payload) =>
        state.project.timelines.push(...payload),
    set_tasks_next_url: (state, payload) => (state.tasks_next_url = payload),
    set_milestones_next_url: (state, payload) =>
        (state.milestones_next_url = payload),
    set_timelines_next_url: (state, payload) =>
        (state.timelines_next_url = payload),
    set_tasks_statistic: (state, payload) => (state.tasks_statistic = payload),
    set_events_statistic: (state, payload) => (state.events_statistic = payload),
    set_timeline_statistic: (state, payload) =>
        (state.timeline_statistic = payload),
    toggle_card_expand: (state, slug) => {
        let index = state.cards.findIndex((i) => i.slug === slug)
        if (~index) {
            let expanded = state.cards[index].column === 12 ? true : false
            state.cards[index].column = expanded ? 6 : 12
            state.cards[index].expand = !expanded
        }
    },
    set_loading: (state, payload) => (state.loading = payload)
}