import auth from '../middleware/auth'
import is_module_enabled from '../middleware/is_module_enabled'

export const offers = {
    path: 'offers',
    props: true,
    component: () =>
        import ('@/modules/OffersOrders/OffersOrders.vue'),
    meta: {
        middleware: [auth, is_module_enabled]
    },
    children: [{
            path: 'home/:id?',
            name: 'offers-home',
            component: () =>
                import ('@/modules/OffersOrders/tabs/Products.vue')
        },
        {
            path: 'board',
            name: 'offers-dashboard',
            component: () =>
                import ('@/modules/OffersOrders/tabs/Dashboard.vue')
        },
        {
            path: 'checkout/:id?',
            name: 'offers-checkout',
            component: () =>
                import ('@/modules/OffersOrders/tabs/Checkout.vue')
        },
        {
            path: 'likes/:id?',
            name: 'offers-likes',
            component: () =>
                import ('@/modules/OffersOrders/tabs/Likes.vue')
        },
        {
            path: 'incoming-orders/:id?',
            name: 'offers-incoming-orders',
            component: () =>
                import ('@/modules/OffersOrders/tabs/IncomingOrders.vue')
        },
        {
            path: 'my-orders/:id?',
            name: 'offers-my-orders',
            component: () =>
                import ('@/modules/OffersOrders/tabs/Orders.vue')
        },
        {
            path: 'designs/:id?',
            name: 'offers-by-category',
            component: () =>
                import ('@/modules/OffersOrders/tabs/DesignsByMainCategory.vue')
        },
        {
            path: 'graphics/:id?',
            name: 'offers-by-subcategory',
            component: () =>
                import ('@/modules/OffersOrders/tabs/DesignsBySubCategory.vue')
        },
        {
            path: 'products/:id?',
            name: 'offers-by-product',
            component: () =>
                import ('@/modules/OffersOrders/tabs/ProductViewPage.vue')
        }
    ]
}

export const manage_categories = {
    path: 'manage-categories',
    name: 'manage-categories',
    props: true,
    component: () =>
        import ('@/modules/OffersOrders/subpages/ManageCategories.vue'),
    meta: {
        middleware: [auth]
    }
}

export const view_product = {
    path: 'product/:id',
    name: 'view-product',
    props: true,
    component: () =>
        import ('@/modules/OffersOrders/tabs/ProductViewPage.vue'),
    meta: {
        middleware: [auth]
    }
}

// export const product_by_category = {
//     path: 'categories/:id',
//     name: 'product-by-category',
//     props: true,
//     component: () => import('@/modules/OffersOrders/tabs/DesignsByCategory.vue'),
//     meta: {
//         middleware: [auth, is_module_enabled]
//     }
// }