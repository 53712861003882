import auth from '../middleware/auth'
import is_module_enabled from '../middleware/is_module_enabled'

export const templates = {
    path: 'templates',
    name: 'templates',
    component: () =>
        import ('@/modules/Templates/Templates.vue'),
    meta: {
        middleware: [auth, is_module_enabled]
    }
}

export const import_templates = {
    path: 'templates/import/:tab',
    name: 'templates-import',
    component: () =>
        import ('@/modules/Templates/subpages/ImportTemplates.vue'),
    meta: {
        middleware: [auth]
    }
}