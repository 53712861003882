import auth from '../middleware/auth'
import is_module_enabled from "../middleware/is_module_enabled";

export const invoice = {
    path: 'invoices',
    name: 'invoice',
    component: () => import('@/modules/Invoice/Invoice.vue'),
    meta: {
        middleware: [auth, is_module_enabled]
    }
}

export const invoice_maker = {
    path: 'invoices/generator/:id?',
    name: 'invoice-maker',
    component: () => import('@/modules/Invoice/sub-pages/InvoiceMaker.vue'),
    meta: {
        middleware: [auth]
    }
}

export const invoice_viewer = {
    path: '/invoices/viewer/:id',
    name: 'invoice-viewer',
    component: () => import('@/modules/Invoice/sub-pages/InvoiceViewer.vue'),
}
