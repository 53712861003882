import auth from '../middleware/auth'
export const subscriptions = {
    path: 'subscriptions',
    name: 'subscriptions',
    component: () => import('@/modules/Subscriptions/Subscriptionsv2.vue'),
    meta: {
        middleware: [auth]
    }
}

export const pay = {
    path: 'subscriptions/pay',
    name: 'subscriptions_pay',
    component: () => import('@/modules/Subscriptions/Pay.vue'),
    meta: {
        middleware: [auth]
    }
}

export const summary = {
    path: 'subscriptions/summary',
    name: 'subscriptions_summary',
    component: () => import('@/modules/Subscriptions/Summary.vue'),
    meta: {
        middleware: [auth]
    }
}

export const charge = {
    path: 'subscriptions/charge/:type?',
    name: 'subscriptions_charge',
    component: () => import('@/modules/Subscriptions/ChargeCredit.vue'),
    meta: {
        middleware: [auth]
    }
}
