// import http_build_query from 'js-http-build-query'
// import request from '@/services/axios_instance'
import jsonLogic from 'json-logic-js'
import _ from 'lodash'

const recursivelySeekId = function(parent) {
    return _.flatten(
        parent.children.map((i) => {
            if (i.hasOwnProperty('children')) {
                return recursivelySeekId(i)
            } else return i.variable
        })
    )
}

const isConditionApplicable = function(conditions, data) {
    if (!conditions) return jsonLogic.apply(true, {})
    return jsonLogic.apply({
            [conditions.connector]: _.cloneDeep(conditions.children).map((item) => {
                return {
                    [item['operator']]: [{ var: item['variable'] }, item['compared']]
                }
            })
        },
        data
    )
}

const state = {
    theme_color: '#223a6b',
    label_color: '#223a6b',
    form: null,
    structures: []
}

const getters = {
    theme_color: (state) => (state.theme_color ? state.theme_color : '#223a6b'),
    label_color: (state) => (state.label_color ? state.label_color : '#223a6b'),
    form: (state) => state.form,
    structures: (state) => state.structures,
    get_sections: (state) => {
        return state.structures.filter((i) => i.tag === 'FormSection')
    },
    get_section_by_index: (state, getters) => (index) => {
        return getters.get_sections[index]
    },
    get_section_with_rules: (state, getters) => {
        return getters.get_sections.filter((i) => i.conditions)
    },
    get_showable_sections: (state, getters) => {
        return getters.get_sections.filter((i) => {
            return isConditionApplicable(i.conditions, getters.get_watch_values)
        })
    },
    get_section_items: (state) => (index) => {
        return state.structures[index] ? state.structures[index].value : []
    },
    get_section_items_by_indexes: (state) => (sIndex, iIndex) => {
        return state.structures[sIndex] ?
            state.structures[sIndex].value[iIndex] :
            []
    },
    get_section_fields: (state) => (index) => {
        return state.structures[index].value.filter(
            (i) => !i.tag.includes('Display')
        )
    },
    get_section_displays: (state) => (index) => {
        return state.structures[index].value.filter((i) =>
            i.tag.includes('Display')
        )
    },
    has_structures: (state) => state.structures.length > 0,
    get_all_fields: (state, getters) => {
        return _.flatten(
                _.cloneDeep(getters.get_sections)
                .filter((j) => j.value.length) //remove empty sections
                .map((i) => i.value) // get only sections value
            ).filter((k) => k.tag.includes('Form')) //remove non forms
    },
    get_all_displays: (state, getters) => {
        return _.flatten(
                _.cloneDeep(getters.get_sections)
                .filter((j) => j.value.length) //remove empty sections
                .map((i) => i.value) // get only sections value
            ).filter((k) => k.tag.includes('Display')) //remove forms
    },
    get_field_by_id: (state, getters) => (id) => {
        return getters.get_all_fields.find((i) => (i.id = id))
    },
    get_ids_to_watch: (state, getters) => {
        return _.uniq(
            _.flatten(
                getters.get_section_with_rules.map((section) => {
                    return recursivelySeekId(section.conditions)
                })
            )
        )
    },
    get_watch_values: (state, getters) => {
        return getters.get_ids_to_watch.reduce(
            (a, v) => ({...a, [v]: getters.get_field_by_id(v).value }), {}
        )
    }
}

const mutations = {
    set_theme_color: (state, payload) => (state.theme_color = payload),
    set_label_color: (state, payload) => (state.label_color = payload),
    set_form: (state, payload) => {
        state.form = payload
        state.structures = payload.hasOwnProperty('questions') ?
            payload.questions :
            []
    },
    set_structures: (state, payload) => (state.structures = payload),
    add_section: (state, payload) => state.structures.push(payload),
    replace_section: (state, { index, payload }) =>
        state.structures.splice(index, 1, payload),
    delete_section: (state, index) => {
        state.structures.splice(index, 1)
    },
    add_section_item: (state, { index, payload }) => {
        state.structures[index].value.push(payload)
    },
    replace_section_item: (state, { sIndex, iIndex, payload }) => {
        state.structures[sIndex].value.splice(iIndex, 1, payload)
    },
    duplicate_section_item: (state, { sIndex, iIndex, payload }) => {
        state.structures[sIndex].value.splice(iIndex, 0, payload)
    },
    delete_section_item: (state, { sIndex, iIndex }) => {
        state.structures[sIndex].value.splice(iIndex, 1)
    },
    update_section_item_prop: (state, { sx, ix, prp, val }) => {
        let copy = state.structures[sx].value[ix]
        copy[prp] = val
        state.structures[sx].value.splice(ix, 1, copy)
    },
    update_section_item_prop_item: (
        state, { sectionIndex, fieldIndex, itemIndex, item }
    ) => {
        let copy = state.structures[sectionIndex].value[fieldIndex]
        copy.items[itemIndex] = item
        state.structures[sectionIndex].value.splice(fieldIndex, 1, copy)
    },
    add_section_item_prop_item: (state, { sectionIndex, fieldIndex, item }) => {
        let copy = state.structures[sectionIndex].value[fieldIndex]
        copy.items.push(item)
        state.structures[sectionIndex].value.splice(fieldIndex, 1, copy)
    },
    delete_section_item_prop_item: (
        state, { sectionIndex, fieldIndex, itemIndex }
    ) => {
        state.structures[sectionIndex].value[fieldIndex].items.splice(itemIndex, 1)
    }
}

const actions = {
    //
}
export { state, getters, mutations, actions }