import auth from '../middleware/auth'
import is_module_enabled from "../middleware/is_module_enabled";

export const teams = {
    path: 'team',
    name: 'team',
    component: () => import('@/modules/Teams/Teams.vue'),
    meta: {
        middleware: [auth, is_module_enabled]
    }
}
