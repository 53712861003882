import Vue from 'vue'
import store from '@/store/store' 

Vue.prototype.$_subscriptions = {
    subscription() {
        return store.getters['config/companySubscriptions'];
    },
    allowedModules() {
        return store.getters['config/accessibleModules'];
    },
    company() {
        return store.getters.user.company;
    },
};