export default function guest({ next, store }) {
    if (store.getters.maintenance_mode) {
        next({ name: 'maintenance_mode' })
        return
    }
    if (store.getters.isAuthenticated) {
        return next({
            path: '/dashboard'
        })
    }

    return next()
}