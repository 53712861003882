export const initial_state = () => ({
    grid_count: 3,
    dashtiles: [{
            title: 'Projects',
            slug: 'projects',
            icon: 'mdi-briefcase',
            value: 0,
            access: ['owner', 'admin', 'manager', 'member', 'client'],
            route: { name: 'projects', params: { type: 'business' }, query: {} }
        },
        {
            title: 'Tasks',
            slug: 'tasks',
            icon: 'mdi-clipboard-list',
            value: 0,
            access: ['owner', 'admin', 'manager', 'member'],
            route: { name: 'my-tasks', params: {}, query: {} }
        },
        {
            title: 'Campaigns',
            slug: 'campaigns',
            icon: 'mdi-google-classroom',
            value: 0,
            access: ['owner', 'admin', 'manager', 'client'],
            route: { name: 'campaigns', params: { type: 'emails' }, query: {} }
        },
        {
            title: 'Invoices',
            slug: 'invoices',
            icon: 'mdi-cash-register',
            value: 0,
            access: ['owner', 'admin', 'manager', 'client'],
            route: { name: 'invoice', params: {}, query: {} }
        },
        {
            title: 'Events',
            slug: 'events',
            icon: 'mdi-calendar-month',
            value: 0,
            access: ['owner', 'admin', 'manager', 'member', 'client'],
            route: { name: 'calendar', params: {}, query: {} }
        },
        {
            title: 'Inbounds',
            slug: 'inbounds',
            icon: 'mdi-email-receive',
            value: 0,
            access: ['owner', 'admin', 'manager', 'client'],
            route: { name: 'forms', params: {}, query: {} }
        },
        {
            title: 'Outbounds',
            slug: 'outbounds',
            icon: 'mdi-email-send',
            value: 0,
            access: ['owner', 'admin', 'manager', 'client'],
            route: { name: 'forms', params: {}, query: {} }
        }
    ],
    allowed_dashtiles: [],
    is_fetching: false,
    user_cards: [],
    for_selections: [],
    dashcards: [{
            title: 'Timeline',
            slug: 'timelines',
            component: 'TimelineCard',
            column: Math.floor(12 / 3),
            order: 0,
            access: ['owner', 'admin', 'manager', 'member', 'client'],
            icon: 'mdi-timeline',
            image: require('@/assets/svgs/images/card-timeline.svg?internal').default,
            data: {},
            selected: false,
            active: true,
            color: 'rgba(3, 252, 82, 0.14)'
        },
        {
            title: 'Invoices',
            slug: 'invoices',
            component: 'InvoiceCard',
            column: Math.floor(12 / 3),
            order: 1,
            access: ['owner', 'admin', 'manager', 'member', 'client'],
            icon: 'mdi-cash-register',
            image: require('@/assets/svgs/images/card-invoice.svg?internal').default,
            data: {},
            selected: false,
            active: true,
            color: 'rgba(81, 153, 140, 0.16)'
        },
        {
            title: 'Clients',
            slug: 'clients',
            component: 'ClientCard',
            column: Math.floor(12 / 3),
            order: 2,
            access: ['owner', 'admin', 'manager'],
            icon: 'mdi-account-group',
            image: require('@/assets/svgs/images/card-client.svg?internal').default,
            data: {},
            selected: false,
            active: true,
            color: 'rgba(66, 45, 133, 0.18)'
        },
        {
            title: 'Calendar',
            slug: 'calendars',
            component: 'CalendarCard',
            column: Math.floor(12 / 3),
            order: 3,
            access: ['owner', 'admin', 'manager', 'member', 'client'],
            icon: 'mdi-calendar-month',
            image: require('@/assets/svgs/images/card-calendar.svg?internal').default,
            data: {},
            selected: false,
            active: true,
            color: 'rgba(189, 150, 102, 0.24)'
        },
        {
            title: 'Timers',
            slug: 'timers',
            component: 'TimerCard',
            column: Math.floor(12 / 3),
            order: 4,
            access: ['owner', 'admin', 'manager', 'member'],
            icon: 'mdi-timer',
            image: require('@/assets/svgs/images/card-timer.svg?internal').default,
            data: {},
            selected: false,
            active: true,
            color: 'rgba(186, 54, 124, 0.13)'
        },
        {
            title: 'Alarms',
            slug: 'alarms',
            component: 'AlarmCard',
            column: Math.floor(12 / 3),
            order: 5,
            access: ['owner', 'admin', 'manager', 'member', 'client'],
            icon: 'mdi-alarm',
            image: require('@/assets/svgs/images/card-alarm.svg?internal').default,
            data: {},
            selected: false,
            active: true,
            color: 'rgba(230, 97, 87, 0.24)'
        },
        {
            title: 'Tasks',
            slug: 'tasks',
            component: 'TaskCard',
            column: Math.floor(12 / 3),
            order: 6,
            access: ['owner', 'admin', 'manager', 'member'],
            icon: 'mdi-clipboard-account-outline',
            image: require('@/assets/svgs/images/card-task.svg?internal').default,
            data: {},
            selected: false,
            active: true,
            color: 'rgba(38, 57, 166, 0.07)'
        }
    ],
    allowed_dashcards: []
})