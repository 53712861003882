import auth from '../middleware/auth'

export const personal_tasks = {
    path: 'my-tasks/:taskId?',
    name: 'my-tasks',
    component: () => import('@/modules/PersonalTasks/PersonalTasksV2.vue'),
    meta: {
        middleware: [auth]
    }
}
