import auth from '../middleware/auth'
import is_module_enabled from '../middleware/is_module_enabled'

export const projects = {
    path: 'projects/:type?',
    name: 'projects',
    component: () =>
        import ('@/modules/Projects/Projects.vue'),
    meta: {
        middleware: [auth, is_module_enabled]
    }
    // children: [
    //     {
    //         path: 'business',
    //         name: 'project-business',
    //         component: () => import('@/modules/Project-Business/Projects.vue'),
    //     },
    //     {
    //         path: 'personal',
    //         name: 'project-personal',
    //         component: () => import('@/modules/Project-Personal/Projects.vue'),
    //     }
    // ]
}