import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'
import Placeholder from '@tiptap/extension-placeholder'
import ExternalVideo from './extension/ExternalVideoExtension'

import ImgModal from './components/ImgModal.vue'

export default {
    inheritAttrs: false,
    name: 'Editor',
    components: {
        EditorContent,
        ImgModal
    },
    props: {
        value: { type: String, default: null },
        required: { type: Boolean, default: false },
        placeholder: { type: String, default: 'Write something …' },
        editable: { type: Boolean, default: true },
        disabledVideo: { type: Boolean, default: false },
        disabledImage: { type: Boolean, default: false },
        disabledTable: { type: Boolean, default: false }
    },
    data() {
        const vm = this
        return {
            emitAfterOnUpdate: false,
            image_modal: false,
            editor: null,
            toolPosition: 'top',
            toolPositions: [
                { text: 'Top', value: 'top', icon: 'mdi-dock-top' },
                { text: 'Bottom', value: 'bottom', icon: 'mdi-dock-bottom' },
                { text: 'Both', value: 'both', icon: 'mdi-dns' }
            ],
            fontSizes: [{
                    level: 1,
                    label: 'Heading 1',
                    icon: 'mdi-format-header-1',
                    fSize: '2em'
                },
                {
                    level: 2,
                    label: 'Heading 2',
                    icon: 'mdi-format-header-2',
                    fSize: '1.5em'
                },
                {
                    level: 3,
                    label: 'Heading 3',
                    icon: 'mdi-format-header-3',
                    fSize: '1.3em'
                },
                {
                    level: 4,
                    label: 'Heading 4',
                    icon: 'mdi-format-header-4',
                    fSize: '1em'
                },
                {
                    level: 5,
                    label: 'Heading 5',
                    icon: 'mdi-format-header-5',
                    fSize: '0.8em'
                },
                {
                    level: 6,
                    label: 'Heading 6',
                    icon: 'mdi-format-header-6',
                    fSize: '0.7em'
                }
            ],
            commandList: [{
                    label: 'Clear All',
                    icon: 'mdi-eraser',
                    method: () => {
                        vm.editor.commands.clearContent(true)
                        vm.editor.chain().focus()
                    },
                    active: () => {
                        return false
                    },
                    disabled: () => {
                        return false
                    }
                },
                {
                    label: 'Undo',
                    icon: 'mdi-undo-variant',
                    method: () => {
                        vm.editor.chain().focus().undo().run()
                    },
                    active: () => {
                        return false
                    },
                    disabled: () => {
                        return false
                    }
                },
                {
                    label: 'Redo',
                    icon: 'mdi-redo-variant',
                    method: () => {
                        vm.editor.chain().focus().redo().run()
                    },
                    active: () => {
                        return false
                    },
                    disabled: () => {
                        return false
                    }
                },
                {
                    label: 'Bold',
                    icon: 'mdi-format-bold',
                    method: () => {
                        vm.editor.chain().focus().toggleBold().run()
                    },
                    active: () => {
                        return vm.editor.isActive('bold')
                    },
                    disabled: () => {
                        return false
                    }
                },
                {
                    label: 'Italic',
                    icon: 'mdi-format-italic',
                    method: () => {
                        vm.editor.chain().focus().toggleStrike().run()
                    },
                    active: () => {
                        return vm.editor.isActive('italic')
                    },
                    disabled: () => {
                        return false
                    }
                },
                {
                    label: 'Strike',
                    icon: 'mdi-format-strikethrough-variant',
                    method: () => {
                        vm.editor.chain().focus().toggleStrike().run()
                    },
                    active: () => {
                        return vm.editor.isActive('strike')
                    },
                    disabled: () => {
                        return false
                    }
                },
                {
                    label: 'Code',
                    icon: 'mdi-code-braces',
                    method: () => {
                        vm.editor.chain().focus().toggleCode().run()
                    },
                    active: () => {
                        return vm.editor.isActive('code')
                    },
                    disabled: () => {
                        return false
                    }
                },
                {
                    label: 'Paragraph',
                    icon: 'mdi-format-paragraph',
                    method: () => {
                        vm.editor.chain().focus().setParagraph().run()
                    },
                    active: () => {
                        return vm.editor.isActive('paragraph')
                    },
                    disabled: () => {
                        return false
                    }
                },
                {
                    label: 'Bulleted List',
                    icon: 'mdi-format-list-bulleted',
                    method: () => {
                        vm.editor.chain().focus().toggleBulletList().run()
                    },
                    active: () => {
                        return vm.editor.isActive('bulletList')
                    }
                },
                {
                    label: 'Ordered List',
                    icon: 'mdi-format-list-numbered',
                    method: () => {
                        vm.editor.chain().focus().toggleOrderedList().run()
                    },
                    active: () => {
                        return vm.editor.isActive('orderedList')
                    },
                    disabled: () => {
                        return false
                    }
                },
                {
                    label: 'Blockquote',
                    icon: 'mdi-format-quote-close',
                    method: () => {
                        vm.editor.chain().focus().toggleBlockquote().run()
                    },
                    active: () => {
                        return vm.editor.isActive('blockquote')
                    },
                    disabled: () => {
                        return false
                    }
                },
                {
                    label: 'Add Link',
                    icon: 'mdi-link-plus',
                    method: () => {
                        const previousUrl = vm.editor.getAttributes('link').href
                        const url = window.prompt('Paste URL', previousUrl)
                        if (url === null) return // cancelled
                        vm.editor // update link
                            .chain()
                            .focus()
                            .extendMarkRange('link')
                            .toggleLink({ href: url, target: '_blank' })
                            .run()
                    },
                    active: () => {
                        return vm.editor.isActive('link')
                    },
                    disabled: () => {
                        return false
                    }
                },
                {
                    label: 'Unset Link',
                    icon: 'mdi-link-off',
                    method: () => {
                        vm.editor.chain().focus().unsetLink().run()
                    },
                    active: () => {
                        return false
                    },
                    disabled: () => {
                        return !vm.editor.isActive('link')
                    },
                    disabled: () => {
                        return false
                    }
                },
                {
                    label: 'Codeblock',
                    icon: 'mdi-alpha-c-box-outline',
                    method: () => {
                        vm.editor.chain().focus().toggleCodeBlock().run()
                    },
                    active: () => {
                        return vm.editor.isActive('codeBlock')
                    },
                    disabled: () => {
                        return false
                    }
                },
                {
                    label: 'Horizontal Line',
                    icon: 'mdi-minus',
                    method: () => {
                        vm.editor.chain().focus().setHorizontalRule().run()
                    },
                    active: () => {
                        return false
                    },
                    disabled: () => {
                        return false
                    }
                },
                {
                    label: 'Text Align Left',
                    icon: 'mdi-format-align-left',
                    method: () => {
                        vm.editor.chain().focus().setTextAlign('left').run()
                    },
                    active: () => {
                        return vm.editor.isActive({ textAlign: 'left' })
                    },
                    disabled: () => {
                        return false
                    }
                },
                {
                    label: 'Text Align Center',
                    icon: 'mdi-format-align-center',
                    method: () => {
                        vm.editor.chain().focus().setTextAlign('center').run()
                    },
                    active: () => {
                        return vm.editor.isActive({ textAlign: 'center' })
                    },
                    disabled: () => {
                        return false
                    }
                },
                {
                    label: 'Text Align Right',
                    icon: 'mdi-format-align-right',
                    method: () => {
                        vm.editor.chain().focus().setTextAlign('right').run()
                    },
                    active: () => {
                        return vm.editor.isActive({ textAlign: 'right' })
                    },
                    disabled: () => {
                        return false
                    }
                },
                {
                    label: 'Insert Image',
                    icon: 'mdi-image',
                    method: () => {
                        vm.image_modal = true
                    },
                    active: () => {
                        return false
                    },
                    disabled: () => {
                        return vm.disabledImage
                    }
                },
                {
                    label: 'Insert YouTube or Vimeo Video',
                    icon: 'mdi-video',
                    method: () => {
                        vm.handleAddVideo()
                    },
                    active: () => {
                        return false
                    },
                    disabled: () => {
                        return vm.disabledVideo
                    }
                }
            ],
            tableList: [{
                    label: 'Insert Table',
                    icon: 'mdi-table-large-plus',
                    method: () => {
                        vm.editor
                            .chain()
                            .focus()
                            .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                            .run()
                    },
                    active: () => {
                        return true
                    }
                },
                {
                    label: 'Add Column Before',
                    icon: 'mdi-table-column-plus-before',
                    method: () => {
                        vm.editor.chain().focus().addColumnBefore().run()
                    },
                    active: () => {
                        return vm.editor.can().addColumnBefore()
                    }
                },
                {
                    label: 'Add Column After',
                    icon: 'mdi-table-column-plus-after',
                    method: () => {
                        vm.editor.chain().focus().addColumnAfter().run()
                    },
                    active: () => {
                        return vm.editor.can().addColumnAfter()
                    }
                },
                {
                    label: 'Delete Column',
                    icon: 'mdi-table-column-remove',
                    method: () => {
                        vm.editor.chain().focus().deleteColumn().run()
                    },
                    active: () => {
                        return vm.editor.can().deleteColumn()
                    }
                },
                {
                    label: 'Add Row Before',
                    icon: 'mdi-table-row-plus-before',
                    method: () => {
                        vm.editor.chain().focus().addRowBefore().run()
                    },
                    active: () => {
                        return vm.editor.can().addRowBefore()
                    }
                },
                {
                    label: 'Add Row After',
                    icon: 'mdi-table-row-plus-after',
                    method: () => {
                        vm.editor.chain().focus().addRowAfter().run()
                    },
                    active: () => {
                        return vm.editor.can().addRowAfter()
                    }
                },
                {
                    label: 'Delete Row',
                    icon: 'mdi-table-row-remove',
                    method: () => {
                        vm.editor.chain().focus().deleteRow().run()
                    },
                    active: () => {
                        return vm.editor.can().deleteRow()
                    }
                },
                {
                    label: 'Delete Table',
                    icon: 'mdi-table-remove',
                    method: () => {
                        vm.editor.chain().focus().deleteTable().run()
                    },
                    active: () => {
                        return vm.editor.can().deleteTable()
                    }
                },
                {
                    label: 'Merge Cells',
                    icon: 'mdi-table-merge-cells',
                    method: () => {
                        vm.editor.chain().focus().mergeCells().run()
                    },
                    active: () => {
                        return vm.editor.can().mergeCells()
                    }
                },
                {
                    label: 'Split Cell',
                    icon: 'mdi-table-split-cell',
                    method: () => {
                        vm.editor.chain().focus().splitCell().run()
                    },
                    active: () => {
                        return vm.editor.can().splitCell()
                    }
                },
                {
                    label: 'Toggle Header Column',
                    icon: 'mdi-table-headers-eye-off',
                    method: () => {
                        vm.editor.chain().focus().toggleHeaderColumn().run()
                    },
                    active: () => {
                        return vm.editor.can().toggleHeaderColumn()
                    }
                },
                {
                    label: 'Toggle Header Row',
                    icon: 'mdi-table-border',
                    method: () => {
                        vm.editor.chain().focus().toggleHeaderRow().run()
                    },
                    active: () => {
                        return vm.editor.can().toggleHeaderRow()
                    }
                },
                {
                    label: 'Toggle Header Cell',
                    icon: 'mdi-table-arrow-up',
                    method: () => {
                        vm.editor.chain().focus().toggleHeaderCell().run()
                    },
                    active: () => {
                        return vm.editor.can().toggleHeaderCell()
                    }
                },
                {
                    label: 'Merge Or Split',
                    icon: 'mdi-table-network',
                    method: () => {
                        vm.editor.chain().focus().mergeOrSplit().run()
                    },
                    active: () => {
                        return vm.editor.can().mergeOrSplit()
                    }
                },
                {
                    label: 'Set Cell Attribute',
                    icon: 'mdi-table-cog',
                    method: () => {
                        vm.editor
                            .chain()
                            .focus()
                            .setCellAttribute('backgroundColor', '#FAF594')
                            .run()
                    },
                    active: () => {
                        return vm.editor
                            .can()
                            .setCellAttribute('backgroundColor', '#FAF594')
                    }
                },
                {
                    label: 'Go To Next Cell',
                    icon: 'mdi-table-arrow-right',
                    method: () => {
                        vm.editor.chain().focus().goToNextCell().run()
                    },
                    active: () => {
                        return vm.editor.can().goToNextCell()
                    }
                },
                {
                    label: 'Go To Previous Cell',
                    icon: 'mdi-table-arrow-left',
                    method: () => {
                        vm.editor.chain().focus().goToPreviousCell().run()
                    },
                    active: () => {
                        return vm.editor.can().goToPreviousCell()
                    }
                }
            ]
        }
    },
    computed: {
        activeTool() {
            return this.editor ? this.editor : false
        },
        minHeight() {
            return this.$attrs.hasOwnProperty('min-height') ?
                ['px', 'vh', 'px', '%', 'em'].includes(this.$attrs['min-height']) ?
                this.$attrs['min-height'] :
                this.$attrs['min-height'] + 'px' :
                '100%'
        },
        hasError() {
            if (!this.editor) return false
            return this.required && this.editable && this.editor.isEmpty ?
                true :
                false
        }
    },
    watch: {
        value: {
            handler: function(val) {
                if (this.editor) {
                    this.editor.commands.setContent(val)
                }
            },
            deep: true,
            immediate: true
        },
        hasError: {
            handler: function(val) {
                return this.$emit('is-valid', val)
            },
            immediate: true
        },
        'editor.isEmpty': {
            handler: function(val) {
                if (this.editor) {
                    this.$emit('input', val ? null : this.editor.getHTML())
                }
                return this.$emit('is-empty', val)
            },
            immediate: true
        }
    },
    mounted() {
        const vm = this
        this.editor = new Editor({
            content: vm.value,
            editable: vm.editable,
            parseOptions: {
                preserveWhitespace: 'full'
            },
            extensions: [
                StarterKit,
                Table.configure({
                    resizable: true
                }),
                TableRow,
                TableHeader,
                TableCell,
                Image.configure({
                    HTMLAttributes: {
                        class: 'd-inline-block ma-2 text-center',
                        style: `max-height:400px`
                    }
                }),
                TextAlign.configure({
                    types: ['heading', 'paragraph', 'images'],
                    alignments: ['left', 'right', 'center'],
                    defaultAlignment: 'left'
                }),
                Link.configure({
                    HTMLAttributes: {
                        class: 'link'
                    },
                    openOnClick: !vm.editable,
                    linkOnPaste: true
                }),
                Placeholder.configure({
                    placeholder: vm.placeholder,
                    emptyEditorClass: 'is-editor-empty',
                    emptyNodeClass: 'my-custom-is-empty-class'
                }),
                ExternalVideo
            ],
            onUpdate({ editor }) {},
            onBlur({ editor, event }) {
                if (vm.editable) {
                    if (vm.hasError) {
                        vm.$emit('input', null)
                    } else vm.$emit('input', editor.getHTML())
                }
            },
            onDestroy() {
                // The editor is being destroyed.
            }
        })
    },

    beforeDestroy() {
        this.editor.destroy()
    },
    methods: {
        handleImageUploaded(url) {
            if (this.editor) {
                this.image_modal = false
                this.editor.chain().focus().setImage({ src: url, height: 'auto' }).run()
            }
        },
        handleAddVideo() {
            let url = window.prompt('Video URL')
            if (!url || url === '') return
            if (url && this.isYouTube(url)) {
                let youtubeId = this.youtubeParser(url)
                url = `https://www.youtube.com/embed/${youtubeId}`
            } else if (url && this.isVimeo(url)) {
                let vimeoId = this.vimeoParser(url)
                url = `https://player.vimeo.com/video/${vimeoId}?loop=false&autoplay=false&muted=false&gesture=media&playsinline=true&byline=false&portrait=false&title=false&speed=true&transparent=false`
            } else {
                url = null
                this.appSnackbar('URL is not a valid YouTube or Vimeo URL', 'error')
            }
            if (url) {
                this.editor
                    .chain()
                    .focus()
                    .setExternalVideo({ src: url, width: 700, height: 400 })
                    .run()
            }
        }
    }
}