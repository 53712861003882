import request from '@/services/axios_instance';

export const actions = {
    fetchTaxes({ commit }) {
        request.get(`api/invoice-taxes`)
            .then(({ data }) => {
                commit('set_save_taxes', data)
            })
    },
    saveTaxes({ commit }, { payload, cb }) {
        request.post(`api/invoice-taxes`, payload)
            .then(({ data }) => {
                commit('add_save_taxes', data)
                if (typeof cb === 'function') cb(data)
            })
    },
    deleteTax({ commit }, id) {
        request.delete(`api/invoice-taxes/${id}`)
            .then(({ data }) => {
                commit('delete_save_taxes', data.id)
            })
    },
    fetchUsers({ commit }) {
        request.get(`api/company/members?for=store`)
            .then(({ data }) => {
                commit('set_users', data)
            })
    },

    fetchLeads({ commit }) {
        commit('set_leads', [])
        //for future updates where allow to send invoice to leads
    },

    fetchProjects({ commit }) {
        request.get(`api/projects?all=true&list=true`)
            .then(({ data }) => {
                commit('set_projects', data)
            })
    },

    fetchTasks({ commit }, { id, status }) {
        request.get(`api/projects/${id}/project-tasks-with-timers-summary?per_page=25&status=${status}`)
            .then(({ data }) => {
                commit('set_tasks', data.data)
                commit('set_tasks_next_url', data.next_page_url)
            })
    },

    fetchMoreTasks({ commit, state }) {
        request.get(state.tasks_next_url)
            .then(({ data }) => {
                commit('append_tasks', data.data)
                commit('set_tasks_next_url', data.next_page_url)
            })
    },

    fetchOrders({ commit }, { user_id }) {
        let api = `api/orders/company-incoming-orders?all=true&list=true`;
        if (user_id) {
            api += `&billable_to_id=${user_id}`
        }
        request.get(api)
            .then(({ data }) => {
                commit('set_orders', data)
                if (!data.length) {
                    commit('set_order', null)
                }
            })
    },

    fetchServices({ commit }) {
        request.get(`api/services-list/list`)
            .then(({ data }) => {
                commit('set_services', data)
            })
    },

    fetchTemplates({ commit }) {
        request.get(`api/template/invoices?defaults=true&colors_saved=true`)
            .then(({ data }) => {
                commit('set_templates', data)
            })
    },

    fetchPreview({ commit }, payload) {
        request.post(`api/invoice/preview`, payload)
            .then(({ data }) => {
                commit('set_preview', data.hasOwnProperty('html') ? data.html : data)
            })
    },
    getInvoiceLogo({ commit }) {
        request.get(`api/invoice/config/logo`)
            .then(({ data }) => {
                commit('set_invoice_logo', data.url)
            })
    },
    setInvoiceLogo({ commit }, { payload, cb }) {
        request.post(`api/invoice/config/logo`, payload)
            .then(({ data }) => {
                commit('set_invoice_logo', data.url)
                if (typeof cb === 'function') cb(data)
            })
    },
    fetchInvoice({ commit }, { id, cb }) {
        request.get(`api/invoice/${id}`)
            .then(({ data }) => {
                commit('set_invoice_view', data)
                commit('set_invoice', data)
                commit('set_invoice_logo', data.logo)
                if (typeof cb === 'function') cb(data)
            })
    }
}