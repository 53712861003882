import guest from "../../middleware/guest";
import is_module_enabled from "../../middleware/is_module_enabled";
import store from '../../../store/store';

export const signup = {
    path: '/signup',
    name: 'signup',
    component: () => import('@/modules/Authentication/Signup/SignupV2.vue'),
    meta: {
        middleware: [guest, is_module_enabled]
    },
    beforeEnter: (to, from, next) => {
        if (!store.getters.isAuthenticated) {
            next()
            return
        }
        next({ name: 'default-content' })
    }
}