import Vue from 'vue'
import VueCryptojs from 'vue-cryptojs'
Vue.use(VueCryptojs)

let siteConfig = {
    SiteName: process.env.VUE_APP_TITLE,
    SiteLogoLong: require('@/assets/logo/buzzooka-blue.png'),
    SiteLogoSquare: require('@/assets/logo/mini-blue.png'),
    SiteLogoWhite: require('@/assets/logo/buzzooka-white.png')
}
const developmentApp = {
    localhost: {
        developer_mode: true,
        wp_site: '',
        BaseURL: `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_HOST}:${process.env.VUE_APP_PORT}`,
        apiHostBaseURL: `${process.env.VUE_APP_API_HOST}`,
        pusher: {
            key: process.env.VUE_APP_PUSHER_KEY,
            cluster: 'ap1', // process.env.VUE_APP_PUSHER_CLUSTER, //pusher cluster
            forceTLS: true,
            logging: false
        },
        stripe_redirect: `${window.location.origin}/dashboard/connect-to/stripe`,
        form_reports_allowed: [3],
        appsHostURL: `${process.env.VUE_APP_APPS_HOST}`.trim()
    },
    staging: {
        wp_site: '',
        developer_mode: true,
        BaseURL: 'https://crm.bizzooka.com',
        apiHostBaseURL: 'https://api.bizzooka.com',
        pusher: {
            key: '67f7c7c9ef03e67434e7',
            cluster: 'ap1', //pusher cluster
            forceTLS: true,
            logging: false
        },
        stripe_redirect: `${window.location.origin}/dashboard/connect-to/stripe`,
        form_reports_allowed: [1],
        appsHostURL: `https://apps.dashcards.com`
    },
    live: {
        wp_site: '',
        developer_mode: false,
        BaseURL: 'https://crm.dashcards.com',
        apiHostBaseURL: 'https://api.dashcards.com',
        pusher: {
            key: '9faf3281e9c67cce3e70',
            cluster: 'ap1', //pusher cluster
            forceTLS: true,
            logging: false
        },
        stripe_redirect: `${window.location.origin}/dashboard/connect-to/stripe`,
        form_reports_allowed: [1, 3, 70, 80],
        offers_main_company: [1, 3],
        appsHostURL: `https://apps.dashcards.com`
    },
    whitelist: {
        developer_mode: false,
        BaseURL: window.location.origin,
        apiHostBaseURL: 'https://api.dashcards.com',
        pusher: {
            key: process.env.VUE_APP_PUSHER_KEY,
            cluster: process.env.VUE_APP_PUSHER_CLUSTER, //pusher cluster
            forceTLS: true,
            logging: false
        },
        stripe_redirect: window.location.origin + '/dashboard/connect-to/stripe',
        form_reports_allowed: [1, 3, 70, 80],
        appsHostURL: `https://apps.dashcards.com`
    }
}
let dev = developmentApp.localhost
if (window.location.host.includes('crm.bizzooka')) {
    dev = developmentApp.staging
} else if (window.location.host.includes('dashcards')) {
    dev = developmentApp.live
} else if (!window.location.host.includes('local')) {
    if (window.location.host.includes('drcolinhong')) {
        siteConfig = {
            wp_site: '',
            SiteName: 'Dr Colin Hong | App',
            SiteLogoLong: require('@/assets/logo/buzzooka-blue.png'),
            SiteLogoSquare: require('@/assets/logo/mini-blue.png'),
            SiteLogoWhite: require('@/assets/logo/buzzooka-white.png')
        }
    }
    if (window.location.host.includes('virtualhealth.bizzooka')) {
        siteConfig = {
            wp_site: '',
            SiteName: 'VirtualHealth | App',
            SiteLogoLong: require('@/assets/logo/buzzooka-blue.png'),
            SiteLogoSquare: require('@/assets/logo/mini-blue.png'),
            SiteLogoWhite: require('@/assets/logo/buzzooka-white.png')
        }
    }
    dev = developmentApp.whitelist
}

const variables = {
    realtime_enabled: true,
    freeTrialDays: 15,
    appsHostURL: dev.appsHostURL,
    developer_mode: dev.developer_mode || false,
    allowedImageType: ['image/jpg', 'image/png', 'image/jpeg', 'image/gif'],
    offers_files: 'image/*,video/*',
    fileSizeMaxUploadLimit: Math.floor(1000000 * 19.5), //10mb
    fileSizeMaxUploadInMB: 19.5,
    defaultCurrency: {
        name: 'US Dollar',
        code: 'USD',
        symbol: '$'
    },
    googleApi: {
        developerKey: process.env.VUE_APP_GOOGLE_DEV_KEY,
        clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        clientSecret: process.env.VUE_APP_GOOGLE_SECRET,
        scope: [
            'email',
            'https://www.googleapis.com/auth/drive',
            'https://www.googleapis.com/auth/drive.file'
        ],
        redirectUris: [
            window.location.origin + '/dashboard/connect-to/google-drive'
        ]
    },
    stripe: {
        pub_key: process.env.VUE_APP_STRIPE_PUBKEY,
        secret_key: process.env.VUE_APP_STRIPE_SECRETKEY,
        client_id: process.env.VUE_APP_STRIPE_CLIENT_ID,
        redirect_uri: dev.stripe_redirect
    },
    paypal: {
        client_id: process.env.VUE_APP_PAYPAL_CLIENTID
    },
    livechat: {
        client_id: process.env.VUE_APP_LIVECHAT_CLIENT_ID,
        redirect_uri: `${window.location.origin}/livechat/settings`
    },
    paraphrase: process.env.VUE_APP_PARAPHRASE,
    on_development: [],
    support_number: process.env.VUE_APP_SUPPORT_NUMBER || 'TBA',
    support_email: process.env.VUE_APP_SUPPORT_EMAIL || 'support@dashcard.com',
    encrypt: function(value) {
        return Vue.CryptoJS.AES.encrypt(
            JSON.stringify(value),
            process.env.VUE_APP_PARAPHRASE
        ).toString()
    },
    decrypt: function(value) {
        return JSON.parse(
            Vue.CryptoJS.AES.decrypt(value, process.env.VUE_APP_PARAPHRASE).toString(
                Vue.CryptoJS.enc.Utf8
            )
        )
    },
    token() {
        return localStorage.getItem('token')
    }
}

const logos = {
    squareLogo: require('@/assets/logos/square-logo.png'),
    squareLogoBlack: require('@/assets/logos/square-logo-black.png'),
    squareLogoWhite: require('@/assets/logos/square-logo-white.png'),
    longLogo: require('@/assets/logos/long-logo.png'),
    longLogoBlack: require('@/assets/logos/long-logo-black.png'),
    longLogoBlack2: require('@/assets/logos/long-logo-black-v2.png'),
    longLogoWhite: require('@/assets/logos/long-logo-white.png'),
    longLogoWhite2: require('@/assets/logos/long-logo-white-v2.png')
}
const placeholders = {
    noCompanyLogo: require('@/assets/file-icons/defaults/company.png'),
    noImagePreview: require('@/assets/file-icons/defaults/images.png'),
    noVideoPreview: require('@/assets/file-icons/defaults/videos.png'),
    noDocsPreview: require('@/assets/file-icons/defaults/documents.png'),
    noOtherPreview: require('@/assets/file-icons/defaults/others.png'),
    notFound: require('@/assets/file-icons/defaults/404.png'),
    loader: require('@/assets/file-icons/defaults/loader.svg').default,
    empty: require('@/assets/file-icons/defaults/empty.svg').default
}

export const settings = Object.assign({},
    variables,
    dev,
    siteConfig,
    logos,
    placeholders
)