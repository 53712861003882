<template>
  <CustomDialog
    :title="title"
    :content="textContent"
    :button1-text="cancelButtonText"
    :button2-text="deleteButtonText"
    :open.sync="open"
    @button1="cancel_clicked"
    @button2="delete_clicked"
  >
    <template v-slot:content>
      <slot name="content">
        <div class="d-block text-center">
          <v-icon slot="icon" size="100" color="red accent-4">
            mdi-alert
          </v-icon>
          <div
            class="fw-500 text-center primary--text my-1 fs-20"
            v-html="textContent"
          ></div>
        </div>
      </slot>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'

export default {
  components: {
    CustomDialog
  },

  props: {
    title: { type: String, default: 'Delete confirmation' },
    textContent: { type: String, default: 'Delete this item?' },
    cancelButtonText: { type: String, default: 'Cancel' },
    deleteButtonText: { type: String, default: 'Yes, Delete' },
    openDialog: Boolean,
    value: { type: Boolean, default: false }
  },

  data: () => ({
    open: false
  }),

  watch: {
    openDialog(val) {
      this.open = val
    },
    value(val) {
      this.open = val
    },
    open(val) {
      this.$emit('update:openDialog', val)
      this.$emit('input', val)
    }
  },

  methods: {
    cancel_clicked() {
      this.open = false
    },
    delete_clicked() {
      this.$emit('delete')
    },
    showDialog() {
      this.open = true
    },
    closeDialog() {
      this.open = false
    }
  }
}
</script>
