import auth from '../middleware/auth'
import is_module_enabled from "../middleware/is_module_enabled";

export const servicesList = {
    path: 'services',
    name: 'services-list',
    component: () => import('@/modules/Services-List/Services-List.vue'),
    meta: {
        middleware: [auth, is_module_enabled]
    }
}
/*Project-Business ==  Campaign, Old services = service List*/