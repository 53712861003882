import request from '@/services/axios_instance'

export const actions = {
    removeSelectedCard: ({ commit }, slug) => {
        commit('removeCard', slug)
    },
    fetchTilesDataBySlugs: ({ commit }, slugs) => {
        commit('set_fetching', true)
        request
            .post(`api/dashboard/dashtiles/statistic`, { slugs: slugs })
            .then(({ data }) => {
                Object.keys(data).forEach((e) => {
                    commit('set_tile', { slug: e, value: data[e] })
                })
            })
            .finally(() => commit('set_fetching', false))
    },
    fetchAvailableCards() {
        commit('set_fetching', true)
        request
            .get(`api/dashboard/dashcards?active_only=true`)
            .then(({ data }) => {
                commit('set_cardData', { slug: slug, data: data })
            })
            .finally(() => commit('set_fetching', false))
    },
    fetchCardDataBySlug({ commit }, slug) {
        commit('set_fetching', true)
        request
            .get(`api/dashboard/dashcards/${slug}`)
            .then(({ data }) => {
                commit('set_cardData', { slug: slug, data: data })
            })
            .finally(() => commit('set_fetching', false))
    }
}