import auth from '../middleware/auth'
import is_module_enabled from "../middleware/is_module_enabled";

export const notes = {
    path: 'notes',
    name: 'notes',
    component: () => import('@/modules/Notes/Notes.vue'),
    meta: {
        middleware: [auth, is_module_enabled]
    }
}
