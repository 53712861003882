import makeRequestTo from '@/services/makeRequestTo'

const state = {
    alarm_audio: null,
    alarm_repeat: 'once',
    chat: [],
    chat_counts: 0,
    notification: []
}

const getters = {
    alarm_audio: (state) => state.alarm_audio,
    alarm_repeat: (state) => state.alarm_repeat,
    chat: (state) => state.chat,
    notification: (state) => state.notification,
    chat_counts: (state) => state.chat_counts
}

const mutations = {
    init_alarm_config: (state) => {
        state.alarm_audio = localStorage.getItem('alarm-audio') || 'none'
        state.alarm_repeat =
            localStorage.getItem('alarm-repeat') == 'true' ? true : false
    },
    set_alarm_repeat: (state, payload) => {
        state.alarm_repeat = payload
        localStorage.setItem('alarm-repeat', payload)
    },
    set_alarm_audio: (state, payload) => {
        state.alarm_audio = payload
        localStorage.setItem('alarm-audio', payload)
    },
    setChat: (state, payload) => {
        state.chat = payload
        state.chat_counts = payload.length
    },
    add_to_chat: (state, payload) => {
        let index = state.chat.findIndex(
            (i) => i.conversation_id === payload.conversation_id
        )
        if (~index) {
            state.chat.splice(index, 1)
        }
        state.chat.unshift(payload)
        state.chat_counts = state.chat.length
    },
    setNotification: (state, payload) => (state.notification = payload),
    addMoreNotification: (state, payload) => {
        Array.prototype.push.apply(state.notification, payload)
    },
    addNotification: (state, payload) => {
        state.notification.unshift(payload)
    },
    removeChat: (state, user) => {
        if (state.chat.length > 0) {
            const index = state.chat.findIndex((n) => n.sender.id === user.id)
            if (~index) {
                state.chat.splice(index, 1)
                state.chat_counts = state.chat.length
            }
        }
    }
}

const actions = {
    fetch_chat({ commit }) {
        makeRequestTo
            .get_unread_messages()
            .then(({ data }) => commit('setChat', data))
    },
    markAllAsRead({ commit }) {
        makeRequestTo
            .mark_all_as_read()
            .then(({ data }) => commit('setNotification', []))
    }
}

export { state, getters, mutations, actions }