import _sumBy from 'lodash/sumBy';
import _sortBy from "lodash/sortBy";
import _map from 'lodash/map';
import { settings } from "@/variables";

export const getters = {
    appModules: state => state.app_modules,
    smsmms_credits: state => state.smsmms_credits,
    email_credits: state => state.email_credits,
    is_company_subscribed: state => false,// state.is_company_subscribed,
    is_company_exempted: state => state.is_company_exempted,
    is_subscribed_or_exempted: state => !!(state.is_company_exempted || state.is_company_subscribed),
    is_company_on_trial: state => state.is_company_on_trial,
    plans: state => state.plans,
    main_selected: state => state.main_selected,
    addons_selected: state => state.addons_selected,
    payment_method: state => state.payment_method,
    current_main_subscription: state => state.current_main_subscription,
    current_addons_subscriptions: state => state.current_addons_subscriptions,
    totalAddons: state => _sumBy(state.addons_selected, 'plan_amount'),
    totals: state => {
        let addons = _sumBy(state.addons_selected, 'plan_amount');
        let main = state.main_selected ? state.main_selected.plan_amount : 0;
        return main + addons;
    },
    main_plans: state => {
        let current_main = state.current_main_subscription;
        let current_selected = state.main_selected;
        let sorted = _sortBy(state.plans.filter(i => i.feature_type === 'main' && i.live_mode === (!settings.developer_mode)), 'plan_amount');
        return sorted.map(item => {
            item.is_subscribed = current_main ? item.id === current_main.id : false;
            item.is_selected = current_selected ? item.id === current_selected.id : false;
            return item;
        })
    },
    addons_plans: state => {
        let current_addons = _map(state.current_addons_subscriptions, 'id');
        let current_addons_selected = _map(state.addons_selected, 'id');
        let current_addons_subscriptions = _map(state.current_addons_subscriptions, 'id'); //excludes those already subscribe
        let sorted = _sortBy(state.plans.filter(i => i.feature_type === 'module' && i.live_mode === (!settings.developer_mode) && !current_addons_subscriptions.includes(i.id)), 'name');
        return sorted.map(item => {
            item.is_subscribed = current_addons.includes(item.id);
            item.is_selected = current_addons_selected.includes(item.id);
            return item;
        })
    },
    is_subscribed_to_addon: (state) => (identifier) => {
        let index = state.current_addons_subscriptions.findIndex(item => item.name === identifier || item.id === identifier || item.price_id === identifier);
        return !!(~index);
    }
}