import request from "@/services/axios_instance";
import _ from 'lodash';

const state = {
    mainStoreCompanies: [],
    loading: false,
    order_dialog: false,
    categories: [],
    subcategories: [],
    selected_category: null,
    selected_subcategory: null,
    selected_product: null,
    products: [],
    cart: [],
    cart_counts: 0,
    order: {},
    categories_next_url: null,
    order_handlers: [],
    company_categories: [],
    settings_categories: [],
    company_categories_next_url: null,
    active_product: null,
}

const getters = {
    mainStoreCompanies: state => state.mainStoreCompanies,
    loading: state => state.loading,
    cart_counts: state => state.cart_counts,
    order_dialog: state => state.order_dialog,
    orderHandlers: state => state.order_handlers,
    cart: state => state.cart,
    products: state => state.products,
    order: state => state.order,
    categories: state => state.categories,
    categorySubs: (state) => {
        return state.selected_category ? _.cloneDeep(state.selected_category.graphic_types) : [];
    },
    subcategories: state => _.cloneDeep(state.subcategories),
    selectedcategory: state => _.cloneDeep(state.selected_category),
    selectedsubcategory: state => _.cloneDeep(state.selected_subcategory),
    selectedproduct: state => _.cloneDeep(state.selected_product),
    company_categories: state => _.cloneDeep(state.company_categories),
    settings_categories: state => _.cloneDeep(state.settings_categories),
    active_product: state => _.cloneDeep(state.active_product),
}

const mutations = {
    set_main_store_companies: (state, value) => (state.mainStoreCompanies = value),
    set_cart_counts: (state, value) => (state.cart_counts = value),
    set_loading: (state, value) => (state.loading = value),
    set_order_dialog: (state, open) => (state.order_dialog = open),
    set_order_handlers: (state, payload) => (state.order_handlers = payload),
    set_categories_next_url: (state, payload) => (state.categories_next_url = payload),
    set_categories: (state, payload) => (state.categories = payload),
    add_category: (state, payload) => (state.categories.push(payload)),
    add_categories: (state, payload) => (state.categories.push(...payload)),
    set_subcategories: (state, payload) => (state.subcategories = payload),
    set_selectedcategory: (state, payload) => (state.selected_category = payload),
    set_selectedsubcategory: (state, payload) => (state.selected_subcategory = payload),
    set_selectedproduct: (state, payload) => (state.selected_product = payload),
    updateProducts: (state, products) => (state.products = products),
    addToCart(state, product) {
        let index = state.cart.findIndex(item => item.id === product.id)
        if (~index) {
            state.cart[index].quantity++;
            return;
        }
        product.quantity = 1;
        state.cart.push(product);
    },
    removeFromCart: (state, index) => { state.cart.splice(index, 1) },
    updateOrder: (state, order) => { state.order = order },
    updateCart: (state, cart) => { state.cart = cart },
    set_active_product: (state, payload) => (state.active_product = payload),
    set_company_categories: (state, payload) => (state.company_categories = payload),
    set_company_categories_next_url: (state, payload) => (state.company_categories_next_url = payload),
    set_settings_categories: (state, payload) => (state.settings_categories = payload),
    likeProduct: (state, { id, is_liked, likes_count }) => {
        let index = state.products.findIndex(i => parseInt(i.id) === parseInt(id))
        if (~index) {
            state.products[index].is_liked = is_liked;
            state.products[index].likes_count = likes_count;
        }
    }
}

const actions = {
    getCartCounts({ commit }, cb) {
        request.get(`api/orders/cart/counts`)
            .then(({ data }) => {
                commit('set_cart_counts', data.cart_counts)
            })
            .catch((error) => console.error(error))
    },
    getCategories({ commit }, cb) {
        request.get(`api/categories/displayable`)
            .then(({ data }) => {
                commit('set_categories', data.data)
                commit('set_categories_next_url', data.next_page_url)
                if (typeof cb === 'function') cb(data.data)
            })
            .catch((error) => console.error(error))
    },
    getMoreCategories({ commit, state }) {
        if (state.categories_next_url) {
            request.get(state.categories_next_url)
                .then(({ data }) => {
                    commit('add_categories', data.data)
                    commit('set_categories_next_url', data.next_page_url)
                })
                .catch((error) => console.error(error))
        }
    },
    getOrderHandlers({ commit }, company_id) {
        request
            .get(`api/companies/${company_id}/order-handlers`)
            .then(({ data }) => {
                commit('set_order_handlers', data)
            });
    },
    clearCart({ commit }) {
        commit('updateCart', []);
    },
    fetchCompanyCategories({ commit }) {
        request.get('api/categories/company')
            .then(({ data }) => {
                commit('set_company_categories', data.data)
                commit('set_company_categories_next_url', data.next_page_url)
            })
    },
    fetchSettingsCategories({ commit }, cb) {
        request.get('api/categories/settings')
            .then(({ data }) => {
                commit('set_settings_categories', data)
                if (typeof cb === 'function') cb(data)
            })
    },
    saveSelectedDisplayCategories({ commit }, { payload, cb }) {
        request.post('api/categories/settings/selected-mains', payload)
            .then(({ data }) => {
                commit('set_settings_categories', data)
                if (typeof cb === 'function') cb(data)
            })
    },
    saveSelectedDisplaySubCategories({ commit }, { main, payload, cb }) {
        request.post(`api/categories/settings/${main}/selected-subs`, payload)
            .then(({ data }) => {
                commit('set_settings_categories', data)
                if (typeof cb === 'function') cb(data)
            })
    },
    createNewCategory({ commit }, { payload, cb }) {
        request.post(`api/categories/settings/new`, payload)
            .then(({ data }) => {
                commit('set_settings_categories', data)
                if (typeof cb === 'function') cb(data)
            })
    },
    createNewGraphicType({ commit }, { payload, cb }) {
        request.post(`api/graphic-types`, payload)
            .then(({ data }) => {
                commit('set_settings_categories', data)
                if (typeof cb === 'function') cb(data)
            })
    },
    updateCategory({ commit }, { id, payload, cb }) {
        request.put(`api/categories/settings/${id}`, payload)
            .then(({ data }) => {
                commit('set_settings_categories', data)
                if (typeof cb === 'function') cb(data)
            })
    },
    updateGraphicType({ commit }, { id, payload, cb }) {
        request.put(`api/graphic-types/${id}`, payload)
            .then(({ data }) => {
                commit('set_settings_categories', data)
                if (typeof cb === 'function') cb(data)
            })
    },
    deleteCategory({ commit }, { id, cb }) {
        request.delete(`api/categories/settings/${id}`)
            .then(({ data }) => {
                commit('set_settings_categories', data)
                if (typeof cb === 'function') cb(data)
            })
    },
    deleteGraphicType({ commit }, { id, cb }) {
        request.delete(`api/graphic-types/${id}`)
            .then(({ data }) => {
                commit('set_settings_categories', data)
                if (typeof cb === 'function') cb(data)
            })
    },
    fetchProduct({ commit }, { id, cb, err_cb }) {
        commit('set_loading', true)
        request.get(`api/products/${id}`)
            .then(({ data }) => {
                commit('set_active_product', data)
                if (typeof cb === 'function') cb(data)
            })
            .catch(err => {
                if (typeof err_cb === 'function') err_cb(data)
                throw err;
            })
            .finally(() => { commit('set_loading', false) })
    },
    likeActiveProduct({ commit, state }, cb) {
        request.post(`api/likes/${state.active_product.id}`)
            .then(({ data }) => {
                let product = _.cloneDeep(state.active_product);
                product.is_liked = data.is_liked;
                product.likes_count = data.likes_count;
                commit('set_active_product', product)
                if (typeof cb === 'function') cb(product, data)
            })
    },
    likeProduct({ commit, state }, { id, cb }) {
        request.post(`api/likes/${id}`)
            .then(({ data }) => {
                commit('likeProduct', { id: id, is_liked: data.is_liked, likes_count: data.likes_count });
                if (typeof cb === 'function') cb(data)
            })
    },
}

export { state, getters, mutations, actions }