import super_admin  from '@/router/middleware/super_admin'

export const admin_templates = {
    path: 'templates',
    name: 'admin-templates',
    component: () => import('@/modules/AdminArea/Templates/Templates.vue'),
    meta: {
        middleware: [ super_admin ]
    }
}

//builder for invoice templates
export const build_admin_invoice_template = {
    path: 'builders/:type/invoice-template/:id?',
    name: 'builders-admin-invoice--template',
    component: () => import('@/modules/Builders/InvoiceTemplateBuilder.vue'),
    meta: {
        middleware: [super_admin]
    }
}