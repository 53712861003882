import request from '@/services/axios_instance'
import _ from 'lodash'

const state = {
    all_users: [],
    user_conversations: [],
    group_conversations: []
}

const getters = {
    all_users: (state) => state.all_users,
    user_conversations: (state) => {
        let ids = _.cloneDeep(state.all_users).map((i) => i.id)
        return _.cloneDeep(state.user_conversations).map((convo) => {
            convo.target.is_online = ids.includes(convo.target.id)
            return convo
        })
    },
    group_conversations: (state) => state.group_conversations
}

const mutations = {
    set_user_logged_in: (state, payload) => {
        let index = state.all_users.findIndex((user) => user.id === payload.id)
        if (!~index) {
            state.all_users.push(payload)
        }
    },
    set_user_logged_out: (state, user) => {
        state.all_users = state.all_users.filter(
            (existing) => existing.id !== user.id
        )
    },
    set_all_users: (state, payload) => (state.all_users = payload),
    set_user_conversations: (state, payload) =>
        (state.user_conversations = payload),
    set_group_conversations: (state, payload) =>
        (state.group_conversations = payload),
    add_group_conversations: (state, payload) => {
        state.group_conversations.unshift(payload)
    },
    update_group_conversations: (state, payload) => {
        let index = state.group_conversations.findIndex(
            (item) => item.id === payload.id
        )
        if (~index) state.group_conversations.splice(index, 1, payload)
    },
    update_conversation_message_count: (state, convo) => {
        if (convo.type === 'private') {
            let index = state.user_conversations.findIndex((i) => i.id === convo.id)
            if (~index) state.user_conversations[index].message_count++
        } else {
            let index = state.group_conversations.findIndex((i) => i.id === convo.id)
            if (~index) state.group_conversations[index].message_count++
        }
    }
}

const actions = {
    user_logged_in({ commit }, payload) {
        commit('set_user_logged_in', payload)
    },
    user_logged_out({ commit }, payload) {
        commit('set_user_logged_out', payload)
    },
    get_conversation_list({ commit, state }) {
        request
            .get(`api/chat/conversations/list?has_msg_count=true`)
            .then(({ data }) => {
                commit('set_user_conversations', data.user_list)
                commit('set_group_conversations', data.group_list)
            })
    }
}
export { state, getters, mutations, actions }