<template>
  <v-tooltip
    :color="iconColor === 'white' ? 'primary' : iconColor"
    top
    v-bind="$attrs"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
        :class="btnClass"
        v-if="button"
        @click.stop="$emit('click:icon')"
      >
        <v-icon :color="iconColor" :class="[iconClass]">
          {{ !loading ? icon : 'mdi-spin mdi-loading' }}
        </v-icon>
      </v-btn>
      <v-icon
        v-else
        :size="iconSize"
        @click.stop="$emit('click:icon')"
        :color="iconColor"
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
        :class="[iconClass]"
      >
        {{ !loading ? icon : 'mdi-spin mdi-loading' }}
      </v-icon>
    </template>
    <div v-bind="$attrs" v-html="text"></div>
  </v-tooltip>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'HelpToolTip',
  props: {
    btnClass: { type: String, default: '' },
    button: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    iconSize: { type: [Number, String], default: 'auto' },
    iconClass: { type: String, default: '' },
    iconColor: { type: String, default: 'primary' },
    disabled: { type: Boolean, default: false },
    icon: { type: String, default: 'mdi-help-circle-outline' },
    text: { type: String, default: '' }
  }
}
</script>

<style scoped></style>
