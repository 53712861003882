<template>
  <v-sheet
    min-height="200"
    color="transparent"
    v-if="streamUrl"
    class="
      d-flex
      audio-player
      fullwidth
      fill-height
      align-center
      justify-center
      flex-column
    "
  >
    <v-img
      contain
      :src="require('@/assets/file-icons/defaults/audios.png')"
    ></v-img>
    <vue-plyr ref="plyr">
      <audio :key="streamUrl" controls playsinline>
        <source :src="streamUrl" :type="type" />
      </audio>
    </vue-plyr>
  </v-sheet>
  <v-sheet
    color="transparent"
    class="d-flex fullwidth flex-column fill-height align-center justify-center"
    v-else
  >
    <v-img :lazy-src="settings.loader" contain :src="settings.notFound"></v-img>
    <strong class="error--text mt-2">Audio url not found!</strong>
  </v-sheet>
</template>

<script>
export default {
  name: 'AudioPlayer',
  props: {
    streamUrl: { type: String },
    type: { type: String, default: 'audio/mp3' },
    downloadUrl: { type: String, default: null }
  },
  mounted() {
    // console.log(this.$refs.plyr.player)
  }
}
</script>
<style lang="css">
.audio-player .plyr {
  max-height: 60px !important;
}
</style>
