import guest from '../../middleware/guest'
import store from '../../../store/store'

export const login = {
    path: '/login',
    name: 'login',
    component: () =>
        import ('@/modules/Authentication/Login/Login.vue'),
    meta: {
        middleware: [guest]
    },
    beforeEnter: (to, from, next) => {
        if (store.getters.isAuthenticated) {
            next({ name: 'default-content' })
        }
        next()
    }
}