import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import request from '@/services/axios_instance'
import { settings } from '@/variables'
import _ from 'lodash'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        custom_loader: false,
        user: null,
        users: [],
        snackbar: { status: false, message: '' },
        show_floating_button: true,
        agreedToPrivacy: false,
        view_type: 'grid',
        maintenance_mode: false
    },
    getters: {
        maintenance_mode: (state) => state.maintenance_mode,
        custom_loader: (state) => state.custom_loader,
        isAuthenticated: (state) => !!state.user,
        users: (state) => state.users,
        usersBaseOnViewer: (state) => {
            if (state.user && state.user.is_client) {
                return state.users.filter((i) => !i.is_client || i.id === state.user.id)
            }
            return state.users
        },
        user: (state) => state.user,
        user_roles: (state) => {
            let roles = []
            if (state.user) {
                if (state.user.is_company_owner) roles.push('owner')
                if (state.user.is_client) roles.push('client')
                else roles.push('member')
                if (state.user.is_admin) roles.push('admin')
                if (state.user.is_manager) roles.push('manager')
                if (state.user.is_super_admin) roles.push('super-admin')
            }
            return roles
        },
        user_has_role: (state) => (role) => {
            let roles = []
            if (state.user) {
                if (state.user.is_company_owner) roles.push('owner')
                if (state.user.is_client) roles.push('client')
                else roles.push('member')
                if (state.user.is_admin) roles.push('admin')
                if (state.user.is_manager) roles.push('manager')
                if (state.user.is_super_admin) roles.push('super-admin')
            }
            return roles.includes(role)
        },
        company_managers: (state) => {
            return _.cloneDeep(state.users).filter((i) => i.is_manager)
        },
        company_admins: (state) => {
            return _.cloneDeep(state.users).filter((i) => i.is_admin)
        },
        company_admins_managers: (state) => {
            return _.cloneDeep(state.users).filter((i) => i.is_admin || i.is_manager)
        },
        company_ordinary_members: (state) => {
            return _.cloneDeep(state.users).filter(
                (i) => !i.is_admin && !i.is_manager && !is_client
            )
        },
        company_non_client_members: (state) => {
            return _.cloneDeep(state.users).filter((i) => !i.is_client)
        },
        company_clients: (state) => {
            return _.cloneDeep(state.users).filter((i) => i.is_client)
        },
        snackbar: (state) => state.snackbar,
        show_floating_button: (state) => state.show_floating_button,
        view_type: (state) => {
            return state.view_type || 'list'
        }
    },
    mutations: {
        initialiseStore: (state) => {
            if (localStorage.getItem('agreedToPrivacy')) {
                state.agreedToPrivacy = true
            }
            state.view_type = localStorage.getItem('bzk_prefer_view') || 'list'
        },
        agreePrivacyPolicy: (state) => {
            localStorage.setItem('agreedToPrivacy', true)
            state.agreedToPrivacy = true
        },
        set_user: (state, payload) => (state.user = payload),
        set_users: (state, payload) => (state.users = payload),
        add_user: (state, payload) => state.users.push(payload),
        remove_user: (state) => (state.user = null),
        update_in_users: (state, payload) => {
            let index = state.users.findIndex((i) => i.id === payload.id)
            if (~index) state.users.splice(index, 1, payload)
        },
        remove_from_users: (state, id) => {
            let index = state.users.findIndex((i) => i.id === id)
            if (~index) state.users.splice(index, 1)
        },
        set_login_user: (state, payload) => {
            state.user = payload
            localStorage.setItem('user', settings.encrypt(payload))
        },
        set_user_image: (state, payload) => {
            state.user.image_url = payload
            let index = state.users.findIndex((i) => i.id === state.user.id)
            if (~index) state.users[index].image_url = payload
            localStorage.setItem('user', settings.encrypt(state.user))
        },
        set_user_company: (state, payload) => {
            state.user.company = payload
            localStorage.setItem('user', settings.encrypt(state.user))
        },
        open_snackbar: (state, payload) => (state.snackbar = payload),
        set_loader: (state, payload) => (state.custom_loader = payload),
        set_floating_button: (state, payload) =>
            (state.show_floating_button = payload),
        set_view_type: (state, payload) => {
            let view = ['list', 'grid'].includes(payload) ? payload : 'list'
            state.view_type = view
            localStorage.setItem('bzk_prefer_view', view)
        },
        setMaintenanceMode: (state, payload) => (state.maintenance_mode = payload)
    },
    actions: {
        login({ commit }, user) {
            commit('set_user', user)
        },
        logout({ commit }) {
            commit('remove_user')
        },
        fetchConfigurations({ commit }) {
            request.get(`api/configurations`).then(({ data }) => {
                commit('setMaintenanceMode', data.maintenance_mode, { root: true })
                commit('subscriptionv2/set_MainPlans', data.main_plans, { root: true })
                commit('subscriptionv2/set_MainModules', data.main_modules, {
                    root: true
                })
                commit('app/set_allowedModules', data.allowed_modules, { root: true })
                commit('app/set_allowedConnects', data.allowed_connects, { root: true })
                commit('dashitems/set_allowedDashcards', data.allowed_dashcards, {
                    root: true
                })
                commit('dashitems/set_allowedDashtiles', data.allowed_dashtiles, {
                    root: true
                })
                commit(
                    'cart/set_main_store_companies',
                    data.offers_main_stores_companies, { root: true }
                )
            })
        },
        fetchUser({ commit }) {
            request
                .get(`api/user`)
                .then(({ data }) => {
                    commit('set_user', data)
                    localStorage.setItem('user', settings.encrypt(data))
                })
                .catch((error) => {
                    localStorage.removeItem('token')
                    localStorage.removeItem('user')
                    localStorage.removeItem('session-eXt-eQt128')
                    throw error
                })
        },
        fetchUsers({ commit }) {
            request.get(`api/company/members?for=store`).then(({ data }) => {
                commit('set_users', data)
            })
        }
    },
    modules,
    strict: process.env.NODE_ENV !== 'production'
})