import _ from 'lodash'
import request from '@/services/axios_instance'

const state = {
    conversations: [],
    active_conversations: [],
    selected_conversation: null,
    max_active_conversation: 1,
    fetching: false,
    submitting: false,
    loading_messages: false,
    loading_more_messages: false
}

const getters = {
    conversations: (state) => state.conversations,
    group_conversations: (state) =>
        state.conversations.filter((i) => i.type === 'group'),
    private_conversations: (state) =>
        state.conversations.filter((i) => i.type === 'private'),
    team_conversations: (state) =>
        state.conversations.filter((i) => i.type === 'team'),
    client_conversations: (state) =>
        state.conversations.filter((i) => i.type === 'client'),
    both_conversations: (state) =>
        state.conversations.filter(
            (i) => i.type === 'private' || i.type === 'group'
        ),
    active_conversations: (state) => state.active_conversations,
    selected_conversation: (state) => {
        return state.selected_conversation
    },
    max_active_conversation: (state) => state.max_active_conversation,
    fetching: (state) => state.fetching,
    submitting: (state) => state.submitting,
    loading_messages: (state) => state.loading_messages,
    loading_more_messages: (state) => state.loading_more_messages,
    convoMessages: (state) => (id) => {
        const convo = state.conversations.find((i) => i.id === id)
        return convo ? convo.messages : []
    }
}

const mutations = {
    set_max_active_conversation: (state, payload) =>
        (state.max_active_conversation = payload),
    update_conversation: (state, { index, data }) => {
        state.conversations.splice(index, 1, data)
        if (
            state.selected_conversation &&
            state.selected_conversation.id === data.id
        ) {
            state.selected_conversation = data
        }
    },
    delete_conversation: (state, conversation_id) => {
        let index = state.conversations.findIndex((i) => i.id === conversation_id)
        if (~index) state.conversations.splice(index, 1)
    },
    set_selected_conversation: (state, payload) =>
        (state.selected_conversation = payload),
    set_conversations: (state, payload) => (state.conversations = payload),
    add_conversation: (state, payload) => state.conversations.push(payload),
    leave_conversation: (state, id) => {
        let index = state.conversations.findIndex((i) => i.id === id)
        if (~index) {
            state.conversations.splice(index, 1)
        }
    },
    set_fetching: (state, payload) => (state.fetching = payload),
    set_submitting: (state, payload) => (state.submitting = payload),
    set_loading_messages: (state, payload) => (state.loading_messages = payload),
    set_loading_more_messages: (state, payload) =>
        (state.loading_more_message = payload),
    closeAllActiveConversations: (state) => (state.active_conversations = []),
    removeFromActiveConversations: (state, conversation) => {
        let id = conversation.hasOwnProperty('id') ? conversation.id : conversation
        let index = state.active_conversations.findIndex((i) => i.id === id)
        if (~index) state.active_conversations.splice(index, 1)
    },
    addToActiveConversations: (state, conversation) => {
        if (state.active_conversations.length === state.max_active_conversation) {
            state.active_conversations.splice(0, 1)
        }
        state.active_conversations.push(conversation)
    },
    toggleActiveConversations: (state, conversation) => {
        let id = conversation.hasOwnProperty('id') ? conversation.id : conversation
        let index = state.active_conversations.findIndex((i) => i.id === id)
        if (~index) {
            state.active_conversations.splice(index, 1)
        } else {
            mutations.addToActiveConversations(state, conversation)
        }
    },
    insertOrUpdateConversation: (state, conversation) => {
        let id = conversation.hasOwnProperty('id') ? conversation.id : conversation
        let index = state.active_conversations.findIndex((i) => i.id === id)
        if (~index) {
            state.active_conversations.splice(index, 1, conversation)
        } else {
            mutations.addToActiveConversations(state, conversation)
        }
    },
    update_or_insert_conversations: (state, conversations) => {
        conversations.forEach((conversation) => {
            let id = conversation.hasOwnProperty('id') ?
                conversation.id :
                conversation
            let index = state.active_conversations.findIndex((i) => i.id === id)
            if (~index) {
                state.active_conversations.splice(index, 1, conversation)
            } else {
                state.active_conversations.push(conversation)
            }
        })
    },
    insertMessageToConversation: (state, { id, message }) => {
        let index = state.conversations.findIndex((i) => i.id === id)
        if (~index) {
            let copied = _.cloneDeep(state.conversations[index])
            copied.messages.push(message)
            state.conversations.splice(index, 1, copied)
        }
        if (state.selected_conversation && state.selected_conversation.id === id) {
            state.selected_conversation.messages.push(message)
        }
    },
    increment_conversation_unread_count: (state, id) => {
        let index = state.conversations.findIndex((i) => i.id === id)
        if (~index) {
            let copied = _.cloneDeep(state.conversations[index])
            copied.unread_messages_count = copied.unread_messages_count + 1
            state.conversations.splice(index, 1, copied)
            if (
                state.selected_conversation &&
                state.selected_conversation.id === id
            ) {
                state.selected_conversation = copied
            }
        }
    },
    set_read_conversation: (state, id) => {
        let index = state.conversations.findIndex((i) => i.id === id)
        if (~index) {
            let copied = _.cloneDeep(state.conversations[index])
            copied.unread_messages_count = 0
            state.conversations.splice(index, 1, copied)
            if (
                state.selected_conversation &&
                state.selected_conversation.id === id
            ) {
                state.selected_conversation = copied
            }
        }
    },
    update_conversation_unread_count: (state, { id, count }) => {
        let index = state.conversations.findIndex((i) => i.id === id)
        if (~index) {
            let copied = _.cloneDeep(state.conversations[index])
            copied.unread_messages_count = count
            state.conversations.splice(index, 1, copied)
            if (
                state.selected_conversation &&
                state.selected_conversation.id === id
            ) {
                state.selected_conversation = copied
            }
        }
    },
    removeMessageFromConversation: (state, { id, message_id }) => {
        let index = state.conversations.findIndex((i) => i.id === id)
        if (~index) {
            let copied = _.cloneDeep(state.conversations[index])
            copied.messages = copied.messages.filter((i) => i.id !== message_id)
            state.conversations.splice(index, 1, copied)
            if (
                state.selected_conversation &&
                state.selected_conversation.id === id
            ) {
                state.selected_conversation = copied
            }
        }
    }
}

const actions = {
    setActiveConversation: ({ commit, dispatch }, conversation) => {
        commit('set_selected_conversation', conversation)
        if (conversation) {
            dispatch('readConversation', conversation.id)
        }
    },
    fetchConversationByProject: ({ commit }, { id, cb }) => {
        commit('set_fetching', true)
        request
            .get(`api/conversations/project/${id}`)
            .then(({ data }) => {
                commit('update_or_insert_conversations', data)
                commit('closeAllActiveConversations')
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .finally(() => commit('set_fetching', false))
    },
    fetchConversationById: ({ commit }, { conversation_id, cb }) => {
        commit('set_fetching', true)
        request
            .get(`api/conversations/${conversation_id}`)
            .then(({ data }) => {
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .finally(() => commit('set_fetching', false))
    },
    fetchConversations: ({ commit }) => {
        commit('set_fetching', true)
        request
            .get(`api/conversations`)
            .then(({ data }) => {
                commit('set_conversations', data)
            })
            .finally(() => commit('set_fetching', false))
    },
    fetchConversationUnreadCount: ({ commit }, conversation_id) => {
        commit('set_fetching', true)
        request
            .get(`api/conversations/${conversation_id}`)
            .then(({ data }) => {
                commit('update_conversation_unread_count', {
                    id: conversation_id,
                    count: data.unread_messages_count
                })
            })
            .finally(() => commit('set_fetching', false))
    },
    fetchConversationMessages: ({ commit, state }, conversation_id) => {
        commit('set_loading_messages', true)
        request
            .get(`api/conversations/${conversation_id}/messages`)
            .then(({ data }) => {
                let index = state.conversations.findIndex(
                    (i) => i.id === conversation_id
                )
                if (~index) {
                    let modified = _.cloneDeep(state.conversations[index])
                    modified.messages = data.data.reverse()
                    modified.next_page_url = data.next_page_url
                    commit('update_conversation', { index: index, data: modified })
                }
            })
            .finally(() => commit('set_loading_messages', false))
    },
    fetchConversationMoreMessages: ({ commit, state }, conversation_id) => {
        commit('set_loading_more_messages', true)
        let index = state.conversations.findIndex((i) => i.id === conversation_id)
        if (~index && state.conversations[index].next_page_url) {
            request
                .get(state.conversations[index].next_page_url)
                .then(({ data }) => {
                    let modified = _.cloneDeep(state.conversations[index])
                    modified.messages.unshift(...data.data.reverse())
                    modified.next_page_url = data.next_page_url
                    commit('update_conversation', { index: index, data: modified })
                })
                .finally(() => commit('set_loading_more_messages', false))
        }
    },
    sendMessage: ({ commit }, { id, payload, cb }) => {
        commit('set_submitting', true)
        request
            .post(`api/conversations/${id}/message`, payload, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then(({ data }) => {
                commit('insertMessageToConversation', { id: id, message: data.data })
                if (typeof cb === 'function') {
                    cb(data.data)
                }
            })
            .finally(() => commit('set_submitting', false))
    },
    updateMessage: ({ commit }, payload) => {
        //commit('set_submitting', true)
        // request
        //     .put(``)
        //     .then(({ data }) => {})
        //     .finally(() => commit('set_submitting', false))
    },
    deleteConversationMessage: ({ commit }, { conversation_id, message_id, cb }) => {
        commit('set_submitting', true)
        request
            .delete(`api/conversations/${conversation_id}/message/${message_id}`)
            .then(({ data }) => {
                commit('removeMessageFromConversation', {
                    id: conversation_id,
                    message_id: message_id
                })
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .finally(() => commit('set_submitting', false))
    },
    leaveConversation: ({ commit }, { conversation_id, cb }) => {
        commit('set_submitting', true)
        request
            .post(`api/conversations/${conversation_id}/leave`, {})
            .then(({ data }) => {
                // console.log(data)
                commit('removeFromActiveConversations', conversation_id)
                commit('leave_conversation', conversation_id)
                if (typeof cb === 'function') cb(data.data)
            })
            .finally(() => commit('set_submitting', false))
    },
    readConversation: ({ commit }, conversation_id) => {
        request
            .post(`api/conversations/${conversation_id}/message/read`, {})
            .then(({ data }) => {
                commit('set_read_conversation', conversation_id)
            })
    },
    addToConversation: ({ commit, state }, { conversation_id, user_ids, cb }) => {
        commit('set_submitting', true)
        request
            .post(`api/conversations/${conversation_id}/participants/add`, {
                user_ids: user_ids
            })
            .then(({ data }) => {
                let index = state.conversations.findIndex(
                    (i) => i.id === conversation_id
                )
                if (~index) {
                    let modified = _.cloneDeep(state.conversations[index])
                    modified.participants = data.data.participants
                    commit('update_conversation', { index: index, data: modified })
                }
                if (typeof cb === 'function') cb(data.data)
            })
            .finally(() => commit('set_submitting', false))
    },
    removeFromConversation: ({ commit, state }, { conversation_id, user_ids, cb }) => {
        commit('set_submitting', true)
        request
            .post(`api/conversations/${conversation_id}/participants/remove`, {
                user_ids: user_ids
            })
            .then(({ data }) => {
                let index = state.conversations.findIndex(
                    (i) => i.id === conversation_id
                )
                if (~index) {
                    let modified = _.cloneDeep(state.conversations[index])
                    modified.participants = data.data.participants
                    commit('update_conversation', { index: index, data: modified })
                }
                if (typeof cb === 'function') cb(data.data)
            })
            .finally(() => commit('set_submitting', false))
    },
    updateConversation: ({ commit, state }, { conversation_id, payload, cb }) => {
        commit('set_submitting', true)
        request
            .put(`api/conversations/${conversation_id}`, payload)
            .then(({ data }) => {
                let index = state.conversations.findIndex(
                    (i) => i.id === conversation_id
                )
                if (~index) {
                    commit('update_conversation', { index: index, data: data.data })
                    commit('insertOrUpdateConversation', data.data)
                }
                if (typeof cb === 'function') cb(data.data)
            })
            .finally(() => commit('set_submitting', false))
    },
    createConversation: ({ commit }, { payload, cb }) => {
        commit('set_submitting', true)
        request
            .post(`api/conversations`, payload)
            .then(({ data }) => {
                commit('add_conversation', data.data)
                if (typeof cb === 'function') cb(data.data)
            })
            .finally(() => commit('set_submitting', false))
    }
}

export { state, getters, mutations, actions }