import auth from '../middleware/auth'
import is_module_enabled from "../middleware/is_module_enabled";

export const clients = {
    path: 'clients',
    name: 'clients',
    component: () => import('@/modules/Clients/Clients.vue'),
    meta: {
        middleware: [is_module_enabled, auth]
    }
}
