<template>
  <v-bottom-sheet v-model="bottom_sheet" style="z-index: 9999" persistent>
    <v-sheet class="text-center pa-1" :min-height="mdAndUp ? 150 : 250">
      <v-card flat>
        <v-row no-gutters dense align="center" justify="end">
          <v-icon
            color="primary"
            class="fab mr-5 mt-2"
            @click="bottom_sheet = !bottom_sheet"
          >
            mdi-close-circle-outline
          </v-icon>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" md="8" sm="12" class="mx-auto">
            <v-row
              align="center"
              justify="center"
              :class="mdAndUp ? 'flex-nowrap' : 'flex-wrap'"
            >
              <v-avatar slot="icon" tile :size="60">
                <v-icon color="red" :size="50">
                  mdi-clock-alert-outline
                </v-icon>
              </v-avatar>
              <v-col cols="7">
                <p class="subtitle-2 primary--text text-left">
                  {{ timer_message }}
                </p>
              </v-col>
              <div class="d-flex align-center justify-center flex-nowrap">
                <v-btn
                  v-if="hasActionButtons"
                  :loading="confirming"
                  class="mx-1 px-5"
                  depressed
                  height="50"
                  color="primary"
                  @click="confirmTimer"
                >
                  <v-icon left>mdi-clock-check-outline</v-icon>
                  Confirm Timer
                </v-btn>
                <v-btn
                  v-if="hasActionButtons"
                  :disabled="confirming"
                  class="mx-1 px-5"
                  depressed
                  height="50"
                  color="warning"
                  @click="bottom_sheet = !bottom_sheet"
                >
                  <v-icon left>mdi-clock-alert-outline</v-icon>
                  Ignore
                </v-btn>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import request from '@/services/axios_instance'
export default {
  name: 'TimerConfirmationBottomSheet',
  data: () => ({
    user_not_moved_mouse: 0,
    user_notified_for_idling: false,
    bottom_sheet: false,
    timer_is_started: false,
    hasActionButtons: true,
    confirming: false,
    notif_stay: 5 * 1000, // 5 seconds for display of notification
    idleMaxAllowed: 30 * 60 * 1000, //30mins in milleseconds
    timer_message:
      'This is a random check for timers. Please confirm to continue your timer, otherwise we will cut your timer in the next 15 minutes',
    idle_timer_message:
      'You were idle for more than 30 minutes. Please confirm to continue, otherwise we will cut your timer in the next 15 minutes'
  }),
  watch: {
    user_not_moved_mouse(val) {
      if (val > this.idleMaxAllowed) {
        //if user is idle for 30 mins
        if (!this.user_notified_for_idling && this.timer_is_started) {
          this.notifyTimer()
          let notification = new Notification(this.idle_timer_message)
        }
      }
    }
  },
  mounted() {
    document.addEventListener('mousemove', this.reset_timer)
    setInterval(() => {
      this.user_not_moved_mouse += 1000
    }, 1000)
  },
  created() {
    this.$event.$on('timer-need-confirmation', (payload) => {
      this.bottom_sheet = true
      this.hasActionButtons = true
      if (payload.message) this.timer_message = payload.message
    })
    this.$event.$on('timer-force-off', (payload) => {
      this.bottom_sheet = true
      this.hasActionButtons = false
      if (payload.message) this.timer_message = payload.message
    })
    this.$event.$on('timer-status', (status) => {
      this.timer_is_started = status
    })
  },
  methods: {
    reset_timer() {
      this.user_not_moved_mouse = 0
      this.user_notified_for_idling = false
    },
    confirmTimer() {
      this.confirming = true
      request
        .post(`api/timer/status/confirm`)
        .then(({ data }) => {
          this.bottom_sheet = false
          this.appSnackbar(data.message)
        })
        .finally(() => (this.confirming = false))
    },
    notifyTimer() {
      request.post(`api/timer/status/notify`).then(({ data }) => {
        this.bottom_sheet = true
        this.hasActionButtons = true
        this.user_notified_for_idling = true
      })
    }
  }
}
</script>

<style></style>
