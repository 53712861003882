<template>
  <transition name="fade">
    <div class="custom-loader" v-show="should_show">
      <div class="loader-content">
        <div class="center-wrapper">
          <v-progress-circular
            style="margin-left: calc(50% - 70px)"
            :size="100"
            :width="7"
            color="primary"
            indeterminate
          >
            <v-avatar size="60" tile>
              <v-img
                contain
                :lazy-src="settings.loader"
                :src="settings.squareLogo"
              ></v-img>
            </v-avatar>
          </v-progress-circular>
          <div class="headline mt-3 text-center primary--text">
            Processing... Please wait...
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  computed: {
    should_show() {
      return this.$store.getters.custom_loader
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-loader {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  overflow: hidden;
  pointer-events: none !important;
  -webkit-user-select: none !important; /* Chrome all / Safari all */
  -moz-user-select: none !important; /* Firefox all */
  -ms-user-select: none !important; /* IE 10+ */
  user-select: none !important; /* Likely future */
  background: rgba(245, 245, 245, 0.301);

  .loader-content {
    display: grid;
    height: 100%;

    .center-wrapper {
      margin: auto;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 1;
}
</style>
