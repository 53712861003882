<template>
  <CustomDialog
    title="Confirmation Required"
    :button1-text="cancelButtonText"
    :button2-text="confirmButtonText"
    :open.sync="open"
    @button1="cancel_clicked"
    @button2="confirm_clicked"
  >
    <template v-slot:cancel-icon><span></span></template>
    <template v-slot:content>
      <div class="d-block text-center">
        <v-icon slot="icon" size="100" color="red accent-4"> mdi-alert </v-icon>
        <div
          class="fw-500 text-center primary--text my-1 fs-20"
          v-html="htmlContent"
        ></div>
      </div>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'

export default {
  name: 'ConfirmationRequiredDialog',
  components: {
    CustomDialog
  },
  data: () => ({
    htmlContent: 'Are you sure you want to proceed on this action?',
    cancelButtonText: 'Cancel',
    confirmButtonText: 'Confirm',
    open: false,
    yesCallback: undefined,
    noCallback: undefined
  }),
  created() {
    this.$event.$on(
      'open_confirmation',
      (message, yescb, nocb, confirmLabel) => {
        this.htmlContent = message || this.htmlContent
        this.yesCallback = yescb
        this.noCallback = nocb
        this.confirmButtonText = confirmLabel || this.confirmButtonText
        this.open = true
      }
    )
  },
  methods: {
    close_dialog() {
      this.open = false
      this.yescb = undefined
      this.nocb = undefined
    },
    cancel_clicked() {
      if (typeof this.noCallback === 'function') {
        this.noCallback()
      }
      this.$nextTick(() => this.close_dialog())
    },
    confirm_clicked() {
      if (typeof this.yesCallback === 'function') {
        this.yesCallback()
      }
      this.$nextTick(() => this.close_dialog())
    }
  }
}
</script>
