<template>
  <v-bottom-sheet v-model="open" persistent style="z-index: 9999">
    <v-card flat tile dense min-height="150" v-if="alarm">
      <v-card-title class="text-center primary--text">
        <span class="mx-auto fs-25 fw-900">{{ alarm.title | ucwords }}</span>
      </v-card-title>
      <v-card-text class="d-flex align-center justify-center">
        <v-row
          dense
          align="center"
          :class="mdAndUp ? 'flex-nowrap' : 'flex-wrap'"
          class="fill-height"
          justify="center"
        >
          <div class="d-flex align-center justify-center">
            <v-icon color="success" class="ma-2" size="80">
              mdi-alarm-multiple
            </v-icon>
            <div class="primary--text flex-column d-flex">
              <strong>{{ alarm.title | ucwords }}</strong>
              <strong class="secondary--text">
                {{ alarm.alarm_on | format('LLL') }} | {{ alarm.timezone }}
              </strong>
              <Editor :editable="false" :value="alarm.description"></Editor>
            </div>
          </div>
          <div class="d-flex align-center justify-center">
            <v-btn
              :loading="submitting"
              :disabled="submitting"
              color="primary"
              class="ma-1 text-none"
              @click="stopAndDelete"
            >
              Stop and Delete Alarm
            </v-btn>
            <v-btn
              :loading="submitting"
              :disabled="submitting"
              color="primary"
              class="ma-1 text-none"
              @click="stop"
            >
              Stop Alarm
            </v-btn>
          </div>
        </v-row>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import playAlert from 'alert-sound-notify'
import request from '@/services/axios_instance'
import Editor from '../Editor/Editor.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
  components: { Editor },
  name: 'AlarmBottomSheet',
  data: () => ({
    open: false,
    submitting: false,
    alarm: null,
    soundList: [
      {
        name: 'door_bell',
        play: false,
        audio: require('@/assets/sounds/door-bell.mp3')
      },
      {
        name: 'orchestral_emergency_alarm',
        play: false,
        audio: require('@/assets/sounds/orchestral-emergency-alarm.mp3')
      },
      {
        name: 'phone_ring',
        play: false,
        audio: require('@/assets/sounds/phone-ring.mp3')
      },
      {
        name: 'phone_ring_2',
        play: false,
        audio: require('@/assets/sounds/phone-ring-2.mp3')
      },
      {
        name: 'sci_fi',
        play: false,
        audio: require('@/assets/sounds/sci-fi.mp3')
      },
      {
        name: 'urgent_simple_tone',
        play: false,
        audio: require('@/assets/sounds/urgent-simple-tone.mp3')
      },
      {
        name: 'wrong_answer',
        play: false,
        audio: require('@/assets/sounds/wrong-answer.mp3')
      }
    ]
  }),
  watch: {
    alarm: {
      handler: function (val) {
        this.open = val ? true : false
      },
      deep: true,
      immediate: true
    },
    open: {
      handler: function (val) {
        if (val && this.alarm_audio && this.alarm_audio != 'none') {
          playAlert.player.loop(this.alarm_repeat)
          playAlert(this.alarm_audio)
        } else if (!val && playAlert.player) {
          playAlert.player.pause()
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('notifications', ['alarm_audio', 'alarm_repeat'])
  },
  created() {
    this.init_alarm_config()
  },
  mounted() {
    this.$event.$on('alarmed', (alarm) => {
      this.alarm = alarm
    })
  },
  methods: {
    ...mapMutations('notifications', ['init_alarm_config']),
    stopAndDelete() {
      this.submitting = true
      request
        .delete(`api/alarms/${this.alarm.id}?confirmed`)
        .then(({ data }) => {
          this.$event.$emit('alarm-deleted', { id: this.alarm.id })
          this.open = false
        })
        .finally(() => {
          this.submitting = false
          this.alarm = null
        })
    },
    stop() {
      this.submitting = true
      request
        .put(`api/alarms/${this.alarm.id}/confirmed`)
        .then(({ data }) => {
          this.$event.$emit('alarm-updated', data)
          this.open = false
          this.alarm = null
        })
        .finally(() => (this.submitting = false))
    }
  }
}
</script>

<style></style>
