import is_module_enabled from "../../router/middleware/is_module_enabled";

export default {
    path: 'reports/:view?',
    name: 'project-preview-reports',
    component: () => import('./Reporting.vue'),
    props: true,
    meta: {
        middleware: [is_module_enabled]
    }
}
