import auth from '../middleware/auth'
import is_module_enabled from '../middleware/is_module_enabled'

export const chat = {
    path: 'chat/:conversation_id?',
    name: 'chat',
    component: () =>
        import ('@/modules/Chat/Chat.vue'),
    meta: {
        middleware: [auth, is_module_enabled]
    }
}