import request from '@/services/axios_instance'
import httpBuildQuery from 'http-build-query'
import * as moment from 'moment'

const state = {
    events: [],
    weekendsVisible: true,
    categories: [],
    projects: [],
    services: [],
    participants: [],
    fetching: false
}

const getters = {
    fetching: (state) => state.fetching,
    events: (state) => state.events,
    categories: (state) => state.categories,
    projects: (state) => state.projects,
    services: (state) => state.services,
    participants: (state) => state.participants,
    weekendsVisible: (state) => state.weekendsVisible
}

const mutations = {
    set_events(state, payload) {
        state.events = payload
    },
    set_fetching(state, payload) {
        state.fetching = payload
    },
    create_events(state, payload) {
        state.events.push(...payload)
    },
    create_event(state, event) {
        state.events.push(event)
    },
    replace_event(state, updatedEvent) {
        let index = state.events.findIndex(
            (event) => event.id.toString() === updatedEvent.id.toString()
        )
        if (~index) state.events.splice(index, 1, updatedEvent)
    },
    delete_event(state, eventId) {
        let index = state.events.findIndex(
            (event) => event.id.toString() === eventId.toString()
        )
        if (~index) {
            state.events.splice(index, 1)
        } else {
            console.warn(`Unable to delete event (id ${eventId})`)
        }
    },
    set_weekends_enabled(state, enabled) {
        state.weekendsVisible = enabled
    },
    set_categories(state, payload) {
        state.categories = payload
    },
    add_categories(state, payload) {
        state.categories.push(...payload)
    },
    delete_categories(state, id) {
        let index = state.categories.findIndex(
            (cat) => cat.id.toString() === id.toString()
        )
        if (~index) {
            state.categories.splice(index, 1)
        }
    },
    update_categories(state, payload) {
        let index = state.categories.findIndex(
            (cat) => cat.id.toString() === payload.id.toString()
        )
        if (~index) {
            state.categories.splice(index, 1, payload)
        }
    },
    set_projects(state, payload) {
        state.projects = payload
    },
    set_services(state, payload) {
        state.services = payload
    },
    set_participants(state, payload) {
        state.participants = payload
    }
}
const actions = {
    createEvent({ commit }, event) {
        request.post(`api/events`, event).then(({ data }) => {
            commit('create_events', data)
        })
    },
    updateByDragEvent({ commit }, updatedEvent) {
        let payload = {
            id: updatedEvent.id,
            timezone: moment.tz.guess() || 'UTC',
            allDay: updatedEvent.allDay,
            start: moment(updatedEvent.start).format('YYYY-MM-DD HH:mm:ss'),
            end: moment(updatedEvent.end).format('YYYY-MM-DD HH:mm:ss'),
            recurrence_affected: updatedEvent.recurrence_affected || 'none'
        }
        request.put(`api/events/${payload.id}/drag`, payload).then(({ data }) => {
            commit('replace_event', data)
        })
    },
    updateByModalEvent({ commit }, updatedEvent) {
        request
            .put(`api/events/${updatedEvent.id}`, updatedEvent)
            .then(({ data }) => {
                commit('replace_event', data)
            })
    },
    deleteEvent({ commit }, { id, action, cb }) {
        let query = action ? `?action=${action}` : ''
        request.delete(`api/events/${id}${query}`).then(() => {
            commit('delete_event', id)
            if (typeof cb === 'function') {
                cb(data)
            }
        })
    },
    leaveEvent({ commit }, { id, action, cb }) {
        let query = action ? `?action=${action}` : ''
        request.delete(`api/events/${id}/participants${query}`).then(({ data }) => {
            commit('delete_event', id)
            if (typeof cb === 'function') {
                cb(data)
            }
        })
    },
    setWeekendsVisible({ commit }, enabled) {
        return commit('set_weekends_enabled', enabled)
    },
    filterEvents({ commit }, filters) {
        commit('set_fetching', true)
        let build_query = httpBuildQuery(filters)
        request
            .get(`api/events/filters?${build_query}`)
            .then(({ data }) => {
                commit('set_events', data)
            })
            .finally(() => commit('set_fetching', false))
    },
    fetchCategories({ commit }) {
        commit('set_fetching', true)
        request
            .get(`api/calendars/event-types`)
            .then(({ data }) => {
                commit('set_categories', data)
            })
            .finally(() => commit('set_fetching', false))
    },
    fetchProjectList({ commit }) {
        commit('set_fetching', true)
        request
            .get(`api/projects?list=true&all=true`)
            .then(({ data }) => {
                commit('set_projects', data)
            })
            .finally(() => commit('set_fetching', false))
    },
    fetchServiceList({ commit }) {
        commit('set_fetching', true)
        request
            .get(`api/services-list/list`)
            .then(({ data }) => {
                commit('set_services', data)
            })
            .finally(() => commit('set_fetching', false))
    },
    manageParticipants({ commit }, { payload, cb }) {
        request
            .post(`api/events/${payload.id}/participants`, payload)
            .then(({ data }) => {
                commit('replace_event', data)
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
    },
    saveEventTypes({ commit }, payload) {
        request.post(`api/calendars/event-types`, payload).then(({ data }) => {
            commit('add_categories', data)
        })
    },
    deleteEventType({ commit }, id) {
        request.delete(`api/calendars/event-types/${id}`).then(({ data }) => {
            commit('delete_categories', id)
        })
    },
    updateEventType({ commit }, payload) {
        request
            .put(`api/calendars/event-types/${payload.id}`, payload)
            .then(({ data }) => {
                commit('update_categories', data)
            })
    },
    toggleRecurrenceStatus({ commit }, { id, action, cb }) {
        request.put(`api/events/${id}/${action}`, {}).then(({ data }) => {
            if (typeof cb === 'function') {
                cb(data)
            }
        })
    }
}

export { state, getters, mutations, actions }