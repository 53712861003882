import request from '@/services/axios_instance'

export const actions = {
    fetchProjectInfo({ commit }, id) {
        commit('set_loading', true)
        request
            .get(`api/projects/${id}/info`)
            .then(({ data }) => {
                if (data) {
                    commit('set_id', data.id)
                    commit('set_title', data.title)
                    commit('set_project_name', data.project_name)
                    commit('set_description', data.description)
                    commit('set_type', data.type)
                    commit('set_created_at', data.created_at)
                    commit('set_started_at', data.started_at)
                    commit('set_end_at', data.end_at)
                    commit('set_company_id', data.company_id)
                    commit('set_company', data.company)
                    commit('set_creator_id', data.creator_id)
                    commit('set_creator', data.creator)
                    commit('set_client_company_id', data.client_company_id)
                    commit('set_client_company', data.client_company)
                    commit('set_props', data.props)
                    commit('set_service_id', data.service_id)
                    commit('set_service', data.service)
                    commit('set_managers', data.managers)
                    commit('set_members', data.members)
                    commit('set_client', data.client)
                    commit('set_team_chat_room', data.team_chat_room)
                    commit('set_client_chat_room', data.client_chat_room)
                }
            })
            .finally(() => commit('set_loading', false))
    },
    // fetchProjectMembers({ state, commit }) {
    //     request.get(`api/projects/${state.project.id}/members`)
    //         .then(({ data }) => {
    //             if (data) {
    //                 commit('set_members', data.data);
    //                 commit('set_members_next_url', data.next_page_url);
    //             }
    //         }).finally(() => commit('set_loading', false))
    // },
    // fetchMoreProjectMembers({ state, commit }) {
    //     request.get(state.members_next_url)
    //         .then(({ data }) => {
    //             if (data) {
    //                 commit('append_members', data.data);
    //                 commit('set_members_next_url', data.next_page_url);
    //             }
    //         }).finally(() => commit('set_loading', false))
    // },
    fetchProjectMilestones({ state, commit }) {
        commit('set_loading', true)
        request
            .get(`api/projects/${state.project.id}/milestones`)
            .then(({ data }) => {
                if (data) {
                    commit('set_milestones', data.data)
                    commit('set_milestones_next_url', data.next_page_url)
                }
            })
            .finally(() => commit('set_loading', false))
    },
    fetchMoreProjectMilestones({ state, commit }) {
        commit('set_loading', true)
        request
            .get(state.milestones_next_url)
            .then(({ data }) => {
                if (data) {
                    commit('append_milestones', data.data)
                    commit('set_milestones_next_url', data.next_page_url)
                }
            })
            .finally(() => commit('set_loading', false))
    },
    fetchProjectTasks({ state, commit }) {
        commit('set_loading', true)
        request
            .get(`api/projects/${state.project.id}/tasks`)
            .then(({ data }) => {
                if (data) {
                    commit('set_tasks', data.data)
                    commit('set_tasks_next_url', data.next_page_url)
                }
            })
            .finally(() => commit('set_loading', false))
    },
    fetchMoreProjectTasks({ state, commit }) {
        commit('set_loading', true)
        request
            .get(state.tasks_next_url)
            .then(({ data }) => {
                if (data) {
                    commit('append_tasks', data.data)
                    commit('set_tasks_next_url', data.next_page_url)
                }
            })
            .finally(() => commit('set_loading', false))
    },
    fetchProjectTimelines({ state, commit }, { filter, projectId }) {
        commit('set_loading', true)
        let id = projectId || state.project.id
        request
            .get(`api/projects/${id}/timeline?filter=${filter}`)
            .then(({ data }) => {
                if (data) {
                    commit('set_timelines', data.data)
                    commit('set_timelines_next_url', data.next_page_url)
                }
            })
            .finally(() => commit('set_loading', false))
    },
    fetchMoreProjectTimelines({ state, commit }) {
        commit('set_loading', true)
        request
            .get(state.timelines_next_url)
            .then(({ data }) => {
                if (data) {
                    commit('append_timelines', data.data)
                    commit('set_timelines_next_url', data.next_page_url)
                }
            })
            .finally(() => commit('set_loading', false))
    },
    fetchProjectTasksStatistic({ state, commit }) {
        commit('set_loading', true)
        request
            .get(`api/projects/${state.project.id}/tasks/statistics`)
            .then(({ data }) => {
                if (data) {
                    commit('set_tasks_statistic', data)
                }
            })
            .finally(() => commit('set_loading', false))
    },
    fetchProjectEventsStatistic({ state, commit }) {
        commit('set_loading', true)
        request
            .get(`api/projects/${state.project.id}/events/statistics`)
            .then(({ data }) => {
                if (data) {
                    commit('set_events_statistic', data)
                }
            })
            .finally(() => commit('set_loading', false))
    },
    fetchProjectTimelineStatistic({ state, commit }) {
        commit('set_loading', true)
        request
            .get(`api/projects/${state.project.id}/timeline/statistics`)
            .then(({ data }) => {
                if (data) {
                    commit('set_timeline_statistic', data)
                }
            })
            .finally(() => commit('set_loading', false))
    }
}