<template>
  <vue-plyr ref="plyr" v-if="streamUrl">
    <div
      class="fullwidth fill-height"
      v-if="isYouTube(streamUrl) && youtubeID"
      data-plyr-provider="youtube"
      :data-plyr-embed-id="youtubeID"
    ></div>
    <div
      class="fullwidth fill-height"
      v-else-if="isVimeo(streamUrl) && vimeoID"
      data-plyr-provider="vimeo"
      :data-plyr-embed-id="vimeoID"
    ></div>
    <div v-else class="plyr__video-embed">
      <iframe
        :key="streamUrl"
        referrerpolicy="unsafe-url"
        :src="streamUrl"
        allowfullscreen
        loading="eager"
        class="fullwidth"
        @load="onLoad"
        allowtransparency
        allow="autoplay"
      ></iframe>
    </div>
  </vue-plyr>
</template>

<script>
export default {
  name: 'WebPlayer',
  props: {
    id: { type: [String, Number], default: 'xxx' },
    streamUrl: { type: String },
    downloadUrl: { type: String, default: null }
  },
  computed: {
    youtubeID() {
      return this.streamUrl ? this.youtubeParser(this.streamUrl) : ''
    },
    vimeoID() {
      return this.streamUrl ? this.vimeoParser(this.streamUrl) : ''
    }
  },
  mounted() {
    //
  },
  methods: {
    onLoad() {
      console.log(e)
    }
  }
}
</script>

<style lang="scss">
.plyr {
  width: 100% !important;
}
</style>
