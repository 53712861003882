import Vue from 'vue'
import store from '@/store/store'
import request from '@/services/axios_instance'
import { settings } from '@/variables';
(function() {
    let storage_user = localStorage.getItem('user')
    if (storage_user) {
        store.dispatch('login', settings.decrypt(storage_user))
        store.dispatch('fetchUser')
        store.dispatch('fetchUsers')
    }
})()

async function set_to_localStorage(data) {
    localStorage.setItem('token', data.token)
    localStorage.setItem('user', settings.encrypt(data.user))
    await store.dispatch('login', data.user)
}
export const auth = {
    user() {
        return store.getters.user
    },
    logout(success, error) {
        store.commit('set_loader', true)
        request
            .post(`api/logout`)
            .then(({ data }) => {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('session-eXt-eQt128')
                if (typeof success === 'function') success(data)
            })
            .catch((err) => {
                if (typeof error === 'function') error(err)
                throw err
            })
            .finally(() => store.commit('set_loader', false))
    },
    login({ email, password, redirect_url }, success_cb, err_cb) {
        store.commit('set_loader', true)
        request
            .post(`api/login`, { email, password, redirect_url })
            .then(({ data }) => {
                set_to_localStorage(data)
                if (typeof success_cb === 'function') success_cb(data)
            })
            .catch((err) => {
                if (typeof err_cb === 'function') err_cb(err)
                throw err
            })
            .finally(() => store.commit('set_loader', false))
    },
    register(payload, cb, err_cb) {
        store.commit('set_loader', true)
        request
            .post(`api/register`, payload)
            .then(({ data }) => {
                store.commit('open_snackbar', { status: true, message: data.message })
                set_to_localStorage(data)
                if (typeof cb === 'function') cb(data)
            })
            .catch((err) => {
                if (typeof err_cb === 'function') err_cb(err)
                throw err
            })
            .finally(() => store.commit('set_loader', false))
    },
    forgotPassword(payload, cb, err_cb) {
        store.commit('set_loader', true)
        request
            .post(`api/password/email`, payload)
            .then(({ data }) => {
                if (typeof cb === 'function') cb(data)
            })
            .catch((err) => {
                if (typeof err_cb === 'function') err_cb(err)
                throw err
            })
            .finally(() => store.commit('set_loader', false))
    },
    setPassword(payload, cb, err_cb) {
        store.commit('set_loader', true)
        request
            .post(`api/password/reset`, payload)
            .then(({ data }) => {
                if (typeof cb === 'function') cb(data)
            })
            .catch((err) => {
                if (typeof err_cb === 'function') err_cb(err)
                throw err
            })
            .finally(() => store.commit('set_loader', false))
    }
}

const Authentication = {
    install: (Vue) => {
        Vue.prototype.$auth = auth
    }
}

Vue.use(Authentication)

export default Authentication