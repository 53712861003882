import auth from '../middleware/auth'
import is_module_enabled from '../middleware/is_module_enabled'
export const support = {
  path: 'support',
  name: 'support',
  component: () => import('@/modules/Support/Index.vue'),
  meta: {
    middleware: [auth, is_module_enabled]
  }
}
export const support_subs = {
  path: 'resources/',
  component: () => import('@/modules/Support/Support.vue'),
  meta: {
    middleware: [auth, is_module_enabled]
  },
  children: [
    {
      path: 'tickets/:id?',
      name: 'support-tickets',
      component: () => import('@/modules/Support/subpages/General.vue')
    },
    {
      path: 'updates-and-fixes/:id?',
      name: 'support-updates-and-fixes',
      component: () => import('@/modules/Support/subpages/RecentUpdates.vue')
    },
    {
      path: 'faqs/:id?',
      name: 'support-faqs',
      component: () => import('@/modules/Support/subpages/FAQs.vue')
    },
    {
      path: 'securities/:tab',
      name: 'support-securities',
      component: () => import('@/modules/Support/subpages/Security.vue')
    },
    {
      path: 'management/:id?',
      name: 'support-management',
      component: () => import('@/modules/Support/subpages/TicketManagement.vue')
    },
    {
      path: 'system-status',
      name: 'support-system-status',
      component: () => import('@/modules/Support/subpages/SystemStatus.vue')
    }
  ]
}
