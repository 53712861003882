import Vue from 'vue'
import VueEcho from 'vue-echo-laravel';
import Pusher from 'pusher-js'
import { settings } from '@/variables'

Pusher.logToConsole = settings.pusher.logging
 
Vue.use(VueEcho, {
    broadcaster: 'pusher',
    key: settings.pusher.key,
    encrypted: true,
    cluster: settings.pusher.cluster,
    forceTLS: settings.pusher.forceTLS,
    authEndpoint: settings.apiHostBaseURL + '/api/broadcasting/auth',
    auth: {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    }
});


// let pusher = null

// const broadcast = {
//     authenticate() {
//         pusher = new Pusher(settings.pusher.key, {
//             cluster: settings.pusher.cluster,
//             forceTLS: settings.pusher.forceTLS,
//             authEndpoint: settings.apiHostBaseURL + '/api/broadcasting/auth',
//             auth: {
//                 headers: {
//                     Authorization: 'Bearer ' + localStorage.getItem('token'),
//                     'Access-Control-Allow-Origin': '*'
//                 }
//             }

//         })
//     },
//     subscribe(channel_name) {
//         return pusher.subscribe(channel_name)
//     },
//     unsubscribe(channel) {
//         pusher.unsubscribe(channel)
//     }
// }

// const pusher_auth = {
//     install: Vue => {
//         Vue.prototype.$pusher = broadcast
//     }
// }

// Vue.use(pusher_auth)

// export default pusher_auth