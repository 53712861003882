import moment from 'moment'

export const initial_state = () => ({
    createMode: true,
    invoice: {
        id: null,
        title: null,
        billable_to_type: 'App\\User',
        billable_to_id: null,
        billable_from_type: 'App\\User',
        billable_from_id: null,
        project_id: null,
        service_id: null,
        template_id: null,
        order_id: null,
        parent_id: null,
        type: 'one-time',
        status: 'pending',
        due_date: moment().endOf('month').format('YYYY-MM-DD'),
        due_date_recurring: 1,
        due_date_normalize: moment().endOf('month').format('LL'),
        billing_date: moment().startOf('month').format('YYYY-MM-DD'),
        billing_date_recurring: 1,
        billing_date_normalize: moment().startOf('month').format('LL'),
        total_amount: 0,
        notes: null,
        terms: null,
        currency: 'USD',
        currency_symbol: '$',
        tax: {
            type: 'fixed',
            value: null,
            show: false
        },
        discount: {
            type: 'fixed',
            value: null,
            show: false
        },
        shipping_fee: {
            type: 'fixed',
            value: null,
            show: false
        },
        props: {
            send_email: 'no'
        },
        is_recurring: 0,
        items: [{
            description: null,
            quantity: 1,
            units: 'hour',
            tax: null,
            price_per_unit: 0,
            total: 0,
            props: null
        }],
        colors: null,
        save_colors: true
    },
    projects: [],
    services: [],
    templates: [],
    orders: [],
    tasks: [],
    tasks_next_url: null,
    users: [],
    leads: [],
    currencies: [
        { code: 'USD', symbol: '$' },
        { code: 'CAD', symbol: 'CAN$' },
        { code: 'EUR', symbol: '€' },
        { code: 'PHP', symbol: '₱' },
    ],
    invoice_view: null,
    invoice_copy: null,
    invoice_edit: null,
    is_editting: false,
    unit_list: [
        { name: 'Hour', value: 'hour' },
        { name: 'Piece', value: 'piece' },
        { name: 'Kilogram', value: 'kilogram' },
        { name: 'Gram', value: 'gram' },
        { name: 'Meter', value: 'meter' },
    ],
    preview: null,
    preview_state: 0,
    weekly_list: [
        { name: 'Every Sunday', value: "0" },
        { name: 'Every Monday', value: "1" },
        { name: 'Every Tuesday', value: "2" },
        { name: 'Every Wednesday', value: "3" },
        { name: 'Every Thursday', value: "4" },
        { name: 'Every Friday', value: "5" },
        { name: 'Every Saturday', value: "6" }
    ],
    invoice_save_taxes: [],
    invoice_logo: null,
    invoice_company: null
})