import auth from '../middleware/auth'
import is_module_enabled from '../middleware/is_module_enabled'

export const campaigns = {
    path: 'campaigns/:type?',
    name: 'campaigns',
    component: () =>
        import ('@/modules/Campaign/Campaign.vue'),
    meta: {
        middleware: [auth, is_module_enabled]
    }
}

export const campaign_email_generator = {
    path: 'campaigns/email/generator/:id?',
    name: 'campaign-emails-generator',
    component: () =>
        import ('@/modules/Campaign/subpages/EmailCampaignMaker.vue'),
    props: true,
    meta: {
        middleware: [auth]
    }
}
export const campaign_sms_generator = {
    path: 'campaigns/smsmms/generator/:id?',
    name: 'campaign-smsmms-generator',
    component: () =>
        import ('@/modules/Campaign/subpages/SMSMMSCampaignMaker.vue'),
    props: true,
    meta: {
        middleware: [auth]
    }
}

export const campaign_emails = {
    path: 'lead-campaigns/emails/:id',
    name: 'campaign-emails',
    component: () =>
        import ('@/modules/Campaign/subpages/CampaignEmails.vue'),
    props: true,
    meta: {
        middleware: [auth]
    }
}
export const email_accessories_list = {
    path: 'lead-campaigns/email-accessories/:type',
    name: 'email-accessories',
    component: () =>
        import ('@/modules/Campaign/subpages/EmailAccessories.vue'),
    props: true,
    meta: {
        middleware: [auth]
    }
}

export const campaign_smsmms = {
    path: 'lead-campaigns/sms/:id',
    name: 'campaign-smsmms',
    component: () =>
        import ('@/modules/Campaign/subpages/CampaignSMSMMS.vue'),
    props: true,
    meta: {
        middleware: [auth]
    }
}

export const campaign_smsmms_settings = {
    path: 'lead-campaigns/sms-mms/setting',
    name: 'smsmms-settings',
    component: () =>
        import ('@/modules/Campaign/subpages/SMSMMSConfiguration.vue'),
    props: true,
    meta: {
        middleware: [auth]
    }
}
export const campaign_email_settings = {
    path: 'lead-campaigns/email/setting',
    name: 'email-settings',
    component: () =>
        import ('@/modules/Campaign/subpages/EmailConfiguration.vue'),
    props: true,
    meta: {
        middleware: [auth]
    }
}