import _ from 'lodash';

export const mutations = {
    init_dashStore: (state) => {
        state.grid_count = localStorage.getItem('dashboardGridCount') || 3;
    },
    set_fetching: (state, value) => {
        state.is_fetching = value
    },
    init_userCards: (state) => {
        let usercards = localStorage.getItem('user_cards') || '';
        state.user_cards = usercards.split(',');
    },
    init_forSelection: (state, roles) => {
        if (roles.length < 1) return [];
        let forSelection = _.cloneDeep(state.dashcards).filter(card => _.intersection(roles, card.access).length > 0);
        state.for_selections = forSelection.map(item => {
            item.selected = state.user_cards.includes(item.slug);
            return item;
        });
    },
    toggle_cardSelected: (state, slug) => {
        let index = state.for_selections.findIndex(i => i.slug === slug);
        if (~index) {
            state.for_selections[index].selected = !state.for_selections[index].selected;
        }
    },
    toggle_allCardSelected: (state, select_all) => {
        state.for_selections = _.cloneDeep(state.for_selections).map(i => {
            i.selected = select_all
            return i;
        })
    },
    set_gridCount: (state, payload) => {
        state.grid_count = payload;
        localStorage.setItem('dashboardGridCount', payload)
    },
    set_column: (state, { index, value }) => {
        state.dashcards[index].column = value;
    },
    set_tile: (state, payload) => {
        if (payload.hasOwnProperty('slug')) {
            let index = state.dashtiles.findIndex(i => i.slug === payload.slug);
            if (~index) {
                state.dashtiles[index].value = payload.value
            }
        }
    },
    set_cardData: (state, payload) => {
        if (payload.hasOwnProperty('slug')) {
            let index = state.dashcards.findIndex(i => i.slug === payload.slug);
            if (~index) {
                state.dashcards[index].data = payload.data
            }
        }
    },
    save_userCards: (state, payload) => {
        let slugs = payload.map(i => i.slug)
        state.user_cards = slugs
        localStorage.setItem('user_cards', slugs.join(','))
    },
    removeCard: (state, slug) => {
        let index = state.user_cards.findIndex(i => i === slug);
        if (~index) {
            state.user_cards.splice(index, 1);
            localStorage.setItem('user_cards', state.user_cards.join(','))
        }
    },
    toggle_userCard: (state, payload) => {
        if (payload.hasOwnProperty('slug')) {
            let index = state.user_cards.findIndex(i => i === payload.slug);
            if (~index) {
                state.user_cards.splice(index, 1)
            } else {
                state.user_cards.push(payload.slug)
            }
        }
    },
    set_myCards: (state, payload) => {
        state.user_cards = payload.map(i => i.slug)
        localStorage.setItem('user_cards', state.user_cards.join(','))
    },
    set_allowedDashcards: (state, payload) => (state.allowed_dashcards = payload),
    set_allowedDashtiles: (state, payload) => (state.allowed_dashtiles = payload)
}