import auth from '../middleware/auth'
import is_module_enabled from "../middleware/is_module_enabled";

export const leads = {
    path: 'leads',
    name: 'leads',
    component: () => import('@/modules/Leads/Leads.vue'),
    meta: {
        middleware: [auth, is_module_enabled]
    }
}
export const lead_groups = {
    path: 'lead-groups/:group?',
    name: 'lead_groups',
    component: () => import('@/modules/Leads/LeadGroup.vue'),
    meta: {
        middleware: [auth, is_module_enabled]
    }
}
