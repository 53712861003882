import _cloneDeep from 'lodash/cloneDeep'
import request from '@/services/axios_instance'
import { settings } from '@/variables'

const original = {
    language: 'english',
    theme: 'default',
    date_format: 'Y-M-D',
    timeline_display_limits: 15,
    general_page_limits: 12,
    messages_page_limits: 10,
    currency: { currency_code: 'USD', symbol: '$', text: 'US Dollar' },
    check_user_timer_every: 1,
    check_user_timer_enable: false,
    auto_stop_user_timer_enable: false,
    auto_stop_user_timer_when_reach: 24,
    sender_email: null,
    timezone: 'UTC'
}

const state = {
    fetching: false,
    ..._cloneDeep(original),
    original: _cloneDeep(original),
    logo: null,
    name: null,
    email: null,
    domain: null,
    tag_line: null,
    short_description: null,
    long_description: null,
    address: null,
    contact: null,
    accessibleModules: [],
    companySubscriptions: null
}

const getters = {
    fetching: (state) => state.fetching,
    name: (state) => state.name,
    email: (state) => state.email,
    domain: (state) => state.domain,
    tag_line: (state) => state.tag_line,
    short_description: (state) => state.short_description,
    long_description: (state) => state.long_description,
    address: (state) => state.address,
    contact: (state) => state.contact,
    accessibleModules: (state) => state.accessibleModules,
    companySubscriptions: (state) => state.companySubscriptions,
    current: (state) => _cloneDeep(state),
    original: (state) => _cloneDeep(original),
    logo: (state) => state.logo,
    timezone: (state) => state.timezone,
    language: (state) => state.language,
    theme: (state) => state.theme,
    date_format: (state) => state.date_format,
    timeline_display_limits: (state) => state.timeline_display_limits,
    general_page_limits: (state) => state.general_page_limits,
    messages_page_limits: (state) => state.messages_page_limits,
    currency: (state) => state.currency,
    sender_email: (state) => state.sender_email,
    check_user_timer_every: (state) => state.check_user_timer_every,
    check_user_timer_enable: (state) => state.check_user_timer_enable,
    auto_stop_user_timer_enable: (state) => state.auto_stop_user_timer_enable,
    auto_stop_user_timer_when_reach: (state) =>
        state.auto_stop_user_timer_when_reach
}

const mutations = {
    set_fetching: (state, payload) => (state.fetching = payload),
    set_original: (state, payload) => (state.original = payload),
    set_language: (state, payload) => (state.language = payload),
    set_theme: (state, payload) => (state.theme = payload),
    set_sender_email: (state, payload) => (state.sender_email = payload),
    set_date_format: (state, payload) => (state.date_format = payload),
    set_timeline_display_limits: (state, payload) =>
        (state.timeline_display_limits = payload),
    set_general_page_limits: (state, payload) =>
        (state.general_page_limits = payload),
    set_messages_page_limits: (state, payload) =>
        (state.messages_page_limits = payload),
    set_currency: (state, payload) => (state.currency = payload),
    set_accessibleModules: (state, payload) =>
        (state.accessibleModules = payload),
    set_companySubscriptions: (state, payload) =>
        (state.companySubscriptions = payload),
    set_logo: (state, payload) => (state.logo = payload),
    set_timezone: (state, payload) => (state.timezone = payload),
    set_check_user_timer_every: (state, payload) =>
        (state.check_user_timer_enable = payload),
    set_check_user_timer_enable: (state, payload) =>
        (state.check_user_timer_enable = payload),
    set_auto_stop_user_timer_enable: (state, payload) =>
        (state.auto_stop_user_timer_enable = payload),
    set_auto_stop_user_timer_when_reach: (state, payload) =>
        (state.auto_stop_user_timer_when_reach = payload),
    set_setting: (state, { key, value }) => {
        state[key] = value
    }
}

const actions = {
    updateSettings({ commit }, { companyId, payload, cb }) {
        request
            .post(`api/company/${companyId}/settings`, payload)
            .then(({ data }) => {
                commit('set_language', data.language)
                commit('set_theme', data.theme)
                commit('set_timezone', data.timezone)
                commit('set_date_format', data.date_format)
                commit('set_sender_email', data.sender_email)
                commit('set_timeline_display_limits', data.timeline_display_limits)
                commit('set_general_page_limits', data.general_page_limits)
                commit('set_messages_page_limits', data.messages_page_limits)
                commit('set_currency', data.currency)
                commit('set_check_user_timer_every', data.check_user_timer_enable)
                commit('set_check_user_timer_enable', data.check_user_timer_enable)
                commit(
                    'set_auto_stop_user_timer_enable',
                    data.auto_stop_user_timer_enable
                )
                commit(
                    'set_auto_stop_user_timer_when_reach',
                    data.auto_stop_user_timer_when_reach
                )
                if (typeof cb === 'function') cb(data)
            })
    },
    fetchSettings({ commit, state }, company_id) {
        commit('set_fetching', true)
        request
            .get(`api/company/${company_id}/settings`)
            .then(({ data }) => {
                for (const key in data) {
                    if (key !== 'settings' && state.hasOwnProperty(key)) {
                        // console.log(key)
                        commit('set_setting', { key: key, value: data[key] })
                    }
                }
                for (const key2 in data.settings) {
                    if (state.hasOwnProperty(key2)) {
                        // console.log(key2)
                        commit('set_setting', { key: key2, value: data.settings[key2] })
                    }
                }
                commit('set_logo', data.company_logo)
            })
            .finally(() => commit('set_fetching', false))
    },
    fetchModulesAndSubscriptions({ commit }, company_id) {
        commit('set_fetching', true)
        request
            .get(`api/company/${company_id}/modules-and-subscriptions`)
            .then(({ data }) => {
                commit('set_accessibleModules', data.accessible_modules)
                commit('set_companySubscriptions', data.company_subscriptions)
            })
            .finally(() => commit('set_fetching', false))
    }
}

export { state, getters, mutations, actions }