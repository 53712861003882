import request from '@/services/axios_instance'
import moment from 'moment-timezone'

const state = {
    fetching_platform_balance: false,
    platform_balance: {
        connect_reserved: 0.0,
        pending: 0.0,
        available: 0.0
    },
    balance_both: 0.0,
    balance_pending: 0.0,
    balance_available: 0.0
}

const getters = {
    fetching_platform_balance: (state) => state.fetching_platform_balance,
    platform_balance_available: (state) =>
        Number(state.platform_balance.available),
    platform_balance_connect_reserved: (state) =>
        Number(state.platform_balance.connect_reserved),
    platform_balance_pending: (state) => Number(state.platform_balance.pending),
    balance_both: (state) => state.balance_both,
    balance_pending: (state) => state.balance_pending,
    balance_available: (state) => state.balance_available
}

const mutations = {
    setBalanceBoth: (state, payload) => (state.balance_both = payload),
    setBalancePending: (state, payload) => (state.balance_pending = payload),
    setBalanceAvailable: (state, payload) => (state.balance_available = payload),
    setPlatformBalance: (state, payload) => (state.platform_balance = payload),
    setFetchingPlatformBalance: (state, payload) =>
        (state.fetching_platform_balance = payload)
}

const actions = {
    getWallet({ commit }) {
        request.get(`api/wallet`).then(({ data }) => {
            commit('setBalanceBoth', data.data.balance_both)
            commit('setBalancePending', data.data.balance_pending)
            commit('setBalanceAvailable', data.data.balance_available)
        })
    },
    getPlatformBalance({ commit }) {
        commit('setFetchingPlatformBalance', true)
        request
            .get(`api/stripe/platform/balance`)
            .then(({ data }) => {
                commit('setPlatformBalance', data)
            })
            .finally(() => commit('setFetchingPlatformBalance', false))
    }
}

export { state, getters, mutations, actions }