import auth from '../middleware/auth'
// import is_module_enabled from '../middleware/is_module_enabled'
export const courses = {
    path: 'courses',
    name: 'courses',
    component: () =>
        import ('@/modules/Courses/Index.vue'),
    meta: {
        middleware: [auth]
    }
}
export const courses_maincategory = {
    path: 'courses/:slug',
    name: 'courses-category',
    component: () =>
        import ('@/modules/Courses/MainCategory.vue'),
    meta: {
        middleware: [auth]
    }
}
export const courses_subcategory = {
    path: 'courses/:mainslug/:subslug',
    name: 'courses-subcategory',
    component: () =>
        import ('@/modules/Courses/SubCategory.vue'),
    meta: {
        middleware: [auth]
    }
}

export const courses_series = {
    path: 'view-course/:slug',
    name: 'courses-view',
    component: () =>
        import ('@/modules/Courses/ViewCourse.vue'),
    meta: {
        middleware: [auth]
    }
}

export const series_create_update = {
    path: 'course/series/:id?',
    name: 'create-update-series',
    component: () =>
        import ('@/modules/Courses/CreateUpdateSeries.vue'),
    meta: {
        middleware: [auth]
    }
}

export const manage_course_categories = {
    path: 'course/categories',
    name: 'manage-course-categories',
    component: () =>
        import ('@/modules/Courses/ManageCategories.vue'),
    meta: {
        middleware: [auth]
    }
}