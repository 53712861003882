
import is_module_enabled from "../../router/middleware/is_module_enabled";

export default {
    path: 'messages/:conversation_id?',
    name: 'project-chats',
    component: () => import('./Messages.vue'),
    props: true,
    meta: {
        middleware: [is_module_enabled]
    }
}
