import auth from '../middleware/auth'
import is_module_enabled from "../middleware/is_module_enabled";

export const reports = {
    path: 'reports',
    name: 'reports',
    component: () => import('@/modules/Reports/Reports.vue'),
    meta: {
        middleware: [auth, is_module_enabled]
    }
}
