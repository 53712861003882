export default function auth({ next, store }) {
    if (store.getters.maintenance_mode) {
        next({ name: 'maintenance_mode' })
        return
    }
    const authenticated = !!localStorage.getItem('token')

    if (!store.getters.user || !authenticated) {
        return next({ name: 'login' })
    }

    return next()
}