import request from '@/services/axios_instance'

const state = {
    fetching: false,
    numbers: [],
    country_codes: [],
    pricing: null,
    selectedPhoneNumber: null,
    phone_types: [
        { text: 'Local', value: 'local' },
        { text: 'Toll Free', value: 'toll_free' },
        { text: 'Mobile', value: 'mobile' }
    ],
    myLocation: null,
    ourNumbers: []
}

const getters = {
    myLocation: (state) => state.myLocation,
    ourNumbers: (state) => state.ourNumbers,
    phone_types: (state) => state.phone_types,
    fetching: (state) => state.fetching,
    numbers: (state) => state.numbers,
    country_codes: (state) => state.country_codes,
    pricing: (state) => state.pricing,
    selectedPhoneNumber: (state) => state.selectedPhoneNumber
}

const mutations = {
    set_fetching: (state, payload) => (state.fetching = payload),
    set_numbers: (state, payload) => (state.numbers = payload),
    set_pricing: (state, payload) => (state.pricing = payload),
    set_selectedPhoneNumber: (state, payload) =>
        (state.selectedPhoneNumber = payload),
    set_selectedPrice: (state, payload) => (state.selectedPrice = payload),
    set_country_codes: (state, payload) => (state.country_codes = payload),
    set_myLocation: (state, payload) => (state.myLocation = payload),
    set_ourNumbers: (state, payload) => (state.ourNumbers = payload)
}

const actions = {
    fetchOurNumbers({ commit }, cb) {
        request.get(`api/twilio/our-numbers`).then(({ data }) => {
            commit('set_ourNumbers', data)
        })
    },
    fetchLocation({ commit }, cb) {
        fetch('https://geolocation-db.com/json/')
            .then((res) => res.json())
            .then((response) => {
                commit('set_myLocation', response)
                if (typeof cb === 'function') cb(response)
            })
    },
    fetchCountryCodes({ commit }) {
        request.get(`api/twilio/country-codes`).then(({ data }) => {
            commit(
                'set_country_codes',
                data.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
            )
        })
    },
    fetchPricing({ commit }, country) {
        if (country) {
            request
                .get(`api/twilio/phone-number-pricing/${country}`)
                .then(({ data }) => {
                    commit('set_pricing', data)
                })
        } else commit('set_pricing', null)
    },
    fetchNumbers({ commit }, filters) {
        commit('set_fetching', true)
        request
            .get(
                `api/twilio/search-phone-number/${filters.country}/${filters.type}?page=1`
            )
            .then(({ data }) => {
                commit('set_numbers', data.available_phone_numbers)
            })
            .finally(() => commit('set_fetching', false))
    }
}
export { state, getters, mutations, actions }