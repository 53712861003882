import _ from 'lodash'

export const getters = {
  project: (state) => state.project,
  title: (state) => state.project.title,
  project_name: (state) => state.project.project_name,
  description: (state) => state.project.description,
  type: (state) => state.project.type,
  created_at: (state) => state.project.created_at,
  started_at: (state) => state.project.started_at,
  end_at: (state) => state.project.end_at,
  company_id: (state) => state.project.company_id,
  company: (state) => state.project.company,
  creator_id: (state) => state.project.creator_id,
  creator: (state) => state.project.creator,
  client_company_id: (state) => state.project.client_company_id,
  client_company: (state) => state.project.client_company,
  props: (state) => state.project.props,
  service_id: (state) => state.project.service_id,
  service: (state) => state.project.service,
  managers: (state) => state.project.managers,
  members: (state) => state.project.members,
  project_members: (state) => {
    let items = []
    if (state.project.managers.length) items.push(...state.project.managers)
    if (state.project.members.length) items.push(...state.project.members)
    if (state.project.client) items.push(state.project.client)
    return items
  },
  client: (state) => state.project.client,
  team_chat_room: (state) => state.project.team_chat_room,
  client_chat_room: (state) => state.project.client_chat_room,
  timelines: (state) => state.project.timelines,
  tasks: (state) => state.project.tasks,
  milestones: (state) => state.project.milestones,
  defaultState: (state) => state.defaultState,
  tasks_next_url: (state) => state.tasks_next_url,
  milestones_next_url: (state) => state.milestones_next_url,
  timelines_next_url: (state) => state.timelines_next_url,
  tasks_statistic: (state) => state.tasks_statistic,
  events_statistic: (state) => state.events_statistic,
  timeline_statistic: (state) => state.timeline_statistic,
  cards: (state) => state.cards.filter((i) => i.active),
  loading: (state) => state.loading
}
