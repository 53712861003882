import Vue from 'vue'
import HelpToolTip from '@/common/HelpToolTip.vue'
import Avatar from '@/common/Avatar.vue'
import Avatars from '@/common/Avatars.vue'
import Media from '@/common/Media.vue'
import Empty from '@/common/Empty.vue'
import Menu from '@/common/Menus/Menu.vue'
import OverlayLoader from '@/common/Loader/OverlayLoader.vue'
import PageLoader from '@/common/Loader/PageLoader.vue'
import KirbyDateTimePicker from '@/common/Pickers/KirbyDateTimePicker.vue'
import Attachment from '@/common/Attachment.vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import VueFriendlyIframe from 'vue-friendly-iframe'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import DatetimePicker from 'vuetify-datetime-picker'
import PinchZoom from 'vue-pinch-zoom'
import VueCarousel from 'vue-carousel'
import VuetifyMoney from 'vuetify-money'
import VueClazyLoad from 'vue-clazy-load'
import VueLazyload from 'vue-lazyload'
import VueLazyLoadVideo from 'vue-lazyload-video'
import moment from 'moment'
import VuePapaParse from 'vue-papa-parse'
import VueApexCharts from 'vue-apexcharts'
import VuePlyr from 'vue-plyr'
import VueSplit from 'vue-split-panel'

import 'vue-plyr/dist/vue-plyr.css'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import 'file-icon-vectors/dist/file-icon-square-o.min.css'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

import contenteditable from 'vue-contenteditable/dist/contenteditable.umd'

Vue.use(contenteditable)

Vue.use(VueSplit)

Vue.prototype.moment = moment

Vue.use(VuePlyr)

Vue.use(VueLazyLoadVideo, {
    poster: require('@/assets/file-icons/defaults/loader.svg')
})

Vue.use(VuePapaParse)

Vue.use(VueCarousel)

Vue.use(VueClazyLoad)

Vue.use(VueLazyload, {
    preLoad: 1,
    error: require('@/assets/file-icons/defaults/404.png'),
    loading: require('@/assets/file-icons/defaults/loader.svg').default,
    attempt: 3,
    observer: true,
    throttleWait: 100
})

Vue.use(VueApexCharts)

Vue.use(VuetifyMoney)

Vue.use(DatetimePicker)

Vue.use(VueFriendlyIframe)

Vue.component('vck-datetime-picker', VueCtkDateTimePicker)

Vue.component('vue-phone-number-input', VuePhoneNumberInput)

Vue.component('Avatar', Avatar)

Vue.component('Avatars', Avatars)

Vue.component('HelpToolTip', HelpToolTip)

Vue.component('Media', Media)

Vue.component('Empty', Empty)

Vue.component('cMenu', Menu)

Vue.component('Attachment', Attachment)

Vue.component('pinch-zoom', PinchZoom)

Vue.component('apexchart', VueApexCharts)

Vue.component('KirbyDateTimePicker', KirbyDateTimePicker)

Vue.component('OverlayLoader', OverlayLoader)

Vue.component('PageLoader', PageLoader)