<template>
  <CustomDialog
    v-model="dialog"
    title="Insert Image"
    button1-text="Cancel"
    @button1="dialog = false"
    button2-text="Insert Image"
    @button2="insertImage"
    :main-btn-disabled="!canInsert"
  >
    <template v-slot:content>
      <v-card flat>
        <v-tabs fixed-tabs dense v-model="tab" centered>
          <v-tab v-if="!forceAttachment" class="text-none" href="#url">
            Image URL
          </v-tab>
          <v-tab class="text-none" href="#attachment">Upload Image</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="url">
            <v-card flat>
              <v-card-text
                class="d-flex align-center justify-center flex-column"
              >
                <v-avatar class="fullwidth" tile width="100%" height="200">
                  <v-img
                    :lazy-src="settings.loader"
                    v-if="url"
                    contain
                    :src="url"
                  ></v-img>
                  <span class="fullwidth fw-900 fs-20 primary--text" v-else>
                    Paste image URL below
                  </span>
                </v-avatar>
                <label class="align-self-start primary--text mb-3 subtitle-2">
                  Paste your Image URL:
                </label>
                <v-text-field
                  placeholder="https://"
                  v-model.trim="url"
                  type="url"
                  outlined
                  dense
                  class="align-self-start fullwidth"
                  hide-details
                  color="#657186"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="attachment">
            <v-card flat>
              <v-file-input
                ref="fileInput"
                accept="image/*"
                class="d-none"
                @change="handleFileChange"
              ></v-file-input>
              <v-card-text>
                <v-sheet
                  class="border-dotted align-center justify-center d-flex fullwidth rounded-5 border-color--primary border-2"
                  height="250"
                >
                  <v-img
                    max-height="240"
                    contain
                    :src="attachment.url_inline"
                    v-if="attachment"
                  >
                    <v-btn
                      class="text-none ma-1"
                      color="primary"
                      depressed
                      small
                      :loading="uploading"
                      @click="$refs.fileInput.$refs.input.click()"
                    >
                      Replace Image
                    </v-btn>
                  </v-img>
                  <v-btn
                    v-else
                    depressed
                    color="primary"
                    large
                    :loading="uploading"
                    @click="$refs.fileInput.$refs.input.click()"
                    class="text-none px-5"
                    outlined
                  >
                    Upload Image
                  </v-btn>
                </v-sheet>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        {{ autoBind }}
      </v-card>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import request from '@/services/axios_instance'
export default {
  components: {
    CustomDialog
  },
  props: {
    value: { type: Boolean, default: false },
    autoBind: { type: Boolean, default: true }, //auto bind to Uploader
    resultMode: { type: String, default: 'url' } //or attachment
  },
  watch: {
    value: {
      handler: function (val) {
        this.dialog = val
        if (val) {
          this.attachment = null
          this.url = null
        }
      },
      immediate: true
    },
    resultMode: {
      handler: function (val) {
        if (this.resultMode === 'attachment') {
          this.tab = 'attachment'
          this.forceAttachment = true
        } else {
          this.tab = 'url'
          this.forceAttachment = false
        }
      },
      immediate: true
    },
    dialog: {
      handler: function (val) {
        this.$emit('input', val)
      },
      immediate: true
    }
  },
  data: () => ({
    dialog: false,
    forceAttachment: false,
    attachment: null,
    url: null,
    tab: 'url',
    uploading: false
  }),
  computed: {
    apiToUse() {
      return this.autoBind
        ? `api/file/image-upload`
        : `api2/attachments/dropzone`
    },
    hasValidAttachment() {
      return this.attachment && this.attachment.hasOwnProperty('url_inline')
        ? true
        : false
    },
    hasValidImageURL() {
      return !!(
        this.url &&
        (this.url.match(/\.(jpeg|jpg|gif|svg|webp|png)$/) != null ||
          this.url.includes(';base64,'))
      )
    },
    canInsert() {
      if (this.tab === 'url') {
        return this.hasValidImageURL
      } else {
        return this.hasValidAttachment
      }
    },
    finalUrl() {
      if (this.resultMode === 'url') {
        if (this.tab === 'url') {
          return this.url
        } else {
          return this.attachment ? this.attachment.url_inline : null
        }
      }
      return null
    }
  },
  methods: {
    insertImage() {
      if (this.resultMode === 'url') {
        this.$emit('file', this.finalUrl)
      } else {
        this.$emit('file', this.attachment)
      }
    },
    handleFileChange(file) {
      if (file) {
        if (!file.type.includes('image/')) {
          this.appSnackbar('File is not a supported image type', 'error')
          return
        }
        if (file.size > this.settings.fileSizeMaxUploadLimit) {
          this.appSnackbar(
            `Image file size max limit is ${this.settings.fileSizeMaxUploadInMB}MB`,
            'error'
          )
          return
        }
        this.uploading = true
        var formData = new FormData()
        formData.append('file', file)
        request
          .post(this.apiToUse, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(({ data }) => {
            this.attachment = data
          })
          .finally(() => (this.uploading = false))
      }
    }
  }
}
</script>
