import request from '@/services/axios_instance'
import http_build_query from 'js-http-build-query'
import _ from 'lodash'

const state = {
    handlers: [],
    tickets: [],
    tags: [],
    unassigned_tickets: [],
    next_page_url: null,
    filters_tags: [],
    filters_status: [],
    filters_search: null,
    fetching: false,
    fetching_more: false,
    submitting: false,
    activeTicket: null,
    general_tags: [
        'Bugs',
        'Feature Requests',
        'Integration Requests',
        'Suggestions',
        'Questions'
    ],
    ticket_statuses: ['Open', 'In Progress', 'Fixed', 'Archived', 'Closed']
}

const getters = {
    handlers: (state) =>
        state.handlers.map((i) => {
            i.assigned = i.hasOwnProperty('assigned') ? i.assigned : []
            return i
        }),
    ticket_statuses: (state) => state.ticket_statuses,
    generalTags: (state) => {
        return _.cloneDeep(state.tags).filter((i) =>
            state.general_tags.includes(i.name)
        )
    },
    unassigned_tickets: (state) => state.unassigned_tickets,
    activeTicket: (state) => state.activeTicket,
    tickets: (state) => state.tickets,
    filters_search: (state) => state.filters_search,
    filters_status: (state) => state.filters_status,
    filters_tags: (state) => state.filters_tags,
    hasMoreTickets: (state) => (state.next_page_url ? true : false),
    filtered_tickets: (state) => {
        return state.tickets.filter((i) => {
            if (!!state.filters_search) {
                return true
            }
            if (
                state.filters_status.length &&
                !state.filters_status.includes(i.status)
            ) {
                return false
            }
            const tag_ids = i.tags.map((i) => i.id)
            return state.filters_tags.some((v) => tag_ids.includes(v))
        })
    },
    tags: (state) => state.tags,
    fetching: (state) => state.fetching,
    fetching_more: (state) => state.fetching_more,
    submitting: (state) => state.submitting
}

const mutations = {
    set_handlers: (state, payload) => (state.handlers = payload),
    set_filters_default: (state) => {
        state.filters_status = []
        state.filters_tags = []
    },
    set_filters_search: (state, payload) => (state.filters_search = payload),
    set_filters_tags: (state, payload) => (state.filters_tags = payload),
    toggle_filters_tags: (state, tag) => {
        let id = tag.hasOwnProperty('id') ? tag.id : tag
        let index = state.filters_tags.findIndex((i) => i == id)
        if (~index) {
            state.filters_tags.splice(index, 1)
        } else {
            state.filters_tags.push(id)
        }
    },
    toggle_filters_status: (state, status) => {
        let index = state.filters_status.findIndex(
            (i) => i.toLowerCase() == status.toLowerCase()
        )
        if (~index) {
            state.filters_status.splice(index, 1)
        } else {
            state.filters_status.push(status)
        }
    },
    set_next_page_url: (state, payload) => (state.next_page_url = payload),
    set_fetching: (state, status) => (state.fetching = status),
    set_fetching_more: (state, status) => (state.fetching_more = status),
    set_submitting: (state, status) => (state.submitting = status),
    set_activeTicket: (state, payload) => (state.activeTicket = payload),
    set_tickets: (state, payload) => (state.tickets = payload),
    add_tickets: (state, payload) => state.tickets.push(...payload),
    add_ticket: (state, payload) => {
        state.tickets.unshift(payload)
        state.activeTicket = payload
    },
    remove_ticket: (state, item) => {
        if (!item) return
        let id = item.hasOwnProperty('id') ? item.id : item
        let index = state.tickets.findIndex((i) => i.id === id)
        if (~index) {
            state.tickets.splice(index, 1)
        }
    },
    update_ticket: (state, item) => {
        if (!item) return
        const id = item.hasOwnProperty('id') ? item.id : item
        const index = state.tickets.findIndex((i) => i.id == id)
        if (~index) {
            state.tickets.splice(index, 1, item)
        }
    },
    set_tags: (state, payload) => (state.tags = payload),
    add_tag: (state, payload) => {
        state.tags.push(payload)
    },
    delete_tag: (state, id) => {
        let index = state.tags.findIndex((i) => i.id === id)
        if (~index) {
            state.tags.splice(index, 1)
        }
    },
    set_unassigned_tickets: (state, payload) =>
        (state.unassigned_tickets = payload),
    set_assigned_tickets_to: (state, { id, data }) => {
        let index = state.handlers.findIndex((i) => (i.id = id))
        if (~index) {
            let copy = state.handlers[index]
            copy.assigned = data
            state.handlers.splice(index, 1, copy)
        }
    }
}

const actions = {
    fetchHandlers({ commit }) {
        request.get(`api/supports/handlers`).then(({ data }) => {
            commit('set_handlers', data)
        })
    },
    updateHandlers({ commit }, { payload, cb }) {
        request.put(`api/supports/handlers`, payload).then(({ data }) => {
            commit('set_handlers', data)
            if (typeof cb === 'function') {
                cb(data)
            }
        })
    },
    fetchTags({ commit }, autoSelect = false) {
        commit('set_fetching', true)
        request
            .get(`api/supports/tags`)
            .then(({ data }) => {
                commit('set_tags', data)
                if (autoSelect) {
                    commit(
                        'set_filters_tags',
                        data.map((i) => i.id)
                    )
                }
            })
            .finally(() => commit('set_fetching', false))
    },
    deleteTag({ commit }, { id, cb }) {
        commit('set_submitting', true)
        request
            .delete(`api/supports/tags/${id}`)
            .then(({ data }) => {
                commit('delete_tag', id)
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .finally(() => commit('set_submitting', false))
    },
    addTag({ commit }, { payload, cb }) {
        commit('set_submitting', true)
        request
            .post(`api/supports/tags`, payload)
            .then(({ data }) => {
                commit('add_tag', data)
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .finally(() => commit('set_submitting', false))
    },
    searchTickets({ commit, state }, keyword) {
        commit('set_fetching', true)
        request
            .get(`api/supports?search=${keyword}`)
            .then(({ data }) => {
                commit('set_tickets', data.data)
                commit('set_next_page_url', data.next_page_url)
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .finally(() => commit('set_fetching', false))
    },
    fetchTicketBySlug({ commit }, slug) {
        request.get(`api/supports/${slug}`).then(({ data }) => {
            commit('set_activeTicket', data.data)
        })
    },
    fetchTickets({ commit, state }, cb) {
        commit('set_fetching', true)
        let filters = {
            tags: state.filters_tags.join(','),
            status: state.filters_status.join(',')
        }
        request
            .get(`api/supports?${http_build_query(filters)}`)
            .then(({ data }) => {
                commit('set_tickets', data.data)
                commit('set_next_page_url', data.next_page_url)
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .finally(() => commit('set_fetching', false))
    },
    fetchMoreTickets({ commit, state }, cb) {
        if (state.next_page_url) {
            commit('set_fetching_more', true)
            request
                .get(state.next_page_url)
                .then(({ data }) => {
                    commit('add_tickets', data.data)
                    commit('set_next_page_url', data.next_page_url)
                    if (typeof cb === 'function') {
                        cb(data)
                    }
                })
                .finally(() => commit('set_fetching_more', false))
        }
    },
    submitTicket({ commit }, { payload, cb }) {
        commit('set_submitting', true)
        request
            .post(`api/supports`, payload)
            .then(({ data }) => {
                commit('add_ticket', data.data)
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .finally(() => commit('set_submitting', false))
    },
    updateTicket({ commit, state }, { id, payload, cb }) {
        commit('set_submitting', true)
        request
            .put(`api/supports/${id}`, payload)
            .then(({ data }) => {
                commit('update_ticket', data.data)
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .finally(() => commit('set_', false))
    },
    deleteTicket({ commit }, { id, cb }) {
        commit('set_submitting', true)
        request
            .delete(`api/supports/${id}`)
            .then(({ data }) => {
                commit('remove_ticket', id)
                if (typeof cb === 'function') {
                    cb(data)
                }
            })
            .finally(() => commit('set_submitting', false))
    },
    updateTicketTags({ commit }, { id, payload, cb }) {
        request.put(`api/supports/${id}/tags`, payload).then(({ data }) => {
            commit('update_ticket', data.data)
            commit('set_activeTicket', data.data)
            if (typeof cb === 'function') {
                cb(data.data)
            }
        })
    },
    updateTicketStatus({ commit }, { id, status, cb }) {
        request.put(`api/supports/${id}/status`, { status }).then(({ data }) => {
            commit('update_ticket', data.data)
            commit('set_activeTicket', data.data)
            if (typeof cb === 'function') {
                cb(data.data)
            }
        })
    },
    assignTicketTo({ commit }, { id, user_id, cb }) {
        request.put(`api/supports/${id}/assign`, { user_id }).then(({ data }) => {
            commit('update_ticket', data.data)
            commit('set_activeTicket', data.data)
            if (typeof cb === 'function') {
                cb(data.data)
            }
        })
    },
    unassignTicketFrom({ commit }, { id, user_id, cb }) {
        request.put(`api/supports/${id}/unassign`, { user_id }).then(({ data }) => {
            commit('update_ticket', data.data)
            commit('set_activeTicket', data.data)
            if (typeof cb === 'function') {
                cb(data.data)
            }
        })
    }
}
export { state, getters, mutations, actions }