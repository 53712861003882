export const mutations = {
    set_activeSidebar: (state, payload) => (state.activeSidebar = payload),
    set_leftSidebar: (state, payload) => (state.leftSidebar = payload),
    set_connectSidebar: (state, payload) => {
        state.connectSidebar = payload;
        state.cardSidebar = false;
        state.rightSidebar = false;
        state.messageSidebar = false;
        state.notificationSidebar = false;
    },
    set_cardSidebar: (state, payload) => {
        state.cardSidebar = payload;
        state.connectSidebar = false;
        state.rightSidebar = false;
        state.messageSidebar = false;
        state.notificationSidebar = false;
    },
    set_rightSidebar: (state, payload) => {
        state.rightSidebar = payload;
        state.connectSidebar = false;
        state.cardSidebar = false;
        state.messageSidebar = false;
        state.notificationSidebar = false;
    },
    set_notificationSidebar: (state, payload) => {
        state.notificationSidebar = payload;
        state.connectSidebar = false;
        state.cardSidebar = false;
        state.messageSidebar = false;
        state.rightSidebar = false;
    },
    set_messageSidebar: (state, payload) => {
        state.messageSidebar = payload;
        state.connectSidebar = false;
        state.cardSidebar = false;
        state.notificationSidebar = false;
        state.rightSidebar = false;
    },
    set_collapseWidth: (state, payload) => (state.collapseWidth = payload),
    set_miniWidth: (state, payload) => (state.miniWidth = payload),
    set_type: (state, payload) => (state.type = payload),
    set_clipped: (state, payload) => (state.clipped = payload),
    set_floating: (state, payload) => (state.floating = payload),
    set_mini: (state, payload) => (state.mini = payload),
    set_hasFooter: (state, payload) => (state.hasFooter = payload),
    set_allowedConnects: (state, payload) => (state.allowed_connects = payload),
    set_allowedModules: (state, payload) => (state.allowed_modules = payload),
    toggleExpandConnect:  (state, connect) => {
        let index = state.connects.findIndex(i => i.id === connect.id)
        if(~index)
            state.connects[index].show = !connect.show 
    }
}