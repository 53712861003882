<template>
  <v-menu
    transition="slide-x-transition"
    bottom
    v-model="menu"
    offset-y
    :close-on-click="false"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        dense
        clearable
        @click:close="datetime = null"
        clear-icon="mdi-close-circle-outline"
        readonly
        outlined
        :placeholder="placeholder"
        :value="datetime_formatted"
        color="primary"
        hide-details="auto"
        v-bind="{ ...attrs, ...$attrs }"
        v-on="on"
      >
      </v-text-field>
    </template>
    <v-card>
      <vck-datetime-picker
        error-hint
        color="#223a6b"
        inline
        no-keyboard
        time-format="H:mm A"
        :disabled="disabled"
        :minute-interval="minuteInterval"
        no-button
        :output-format="valueFormat"
        :only-date="type === 'date'"
        v-model="datetime"
        :max-date="customMaxDate"
        :min-date="customMinDate"
        class="kirby-datetime-picker"
      >
      </vck-datetime-picker>
      <v-card-actions class="d-flex align-center justify-end">
        <v-btn @click="done" color="primary" class="text-none" small>
          Done
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import moment from 'moment'
export default {
  name: 'KirbyDateTimePicker',
  props: {
    value: { type: String },
    minuteInterval: { type: Number, default: 1 },
    type: { type: String, default: 'datetime' },
    required: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    valueFormat: { type: String, default: 'YYYY-MM-DD HH:mm:ss' },
    minDate: { type: String },
    maxDate: { type: String }
  },
  data: () => ({
    menu: false,
    datetime: null,
    customMinDate: null,
    customMaxDate: null
  }),
  computed: {
    display_format() {
      return this.type === 'date' ? 'LL' : 'LLL'
    },
    placeholder() {
      return this.type === 'date' ? 'Select date' : 'Select date and time'
    },
    datetime_formatted() {
      return this.datetime
        ? moment(this.datetime).format(this.display_format)
        : null
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.datetime = val
      },
      immediate: true
    },
    minDate: {
      handler: function (val) {
        this.customMinDate = val
      },
      immediate: true
    },
    maxDate: {
      handler: function (val) {
        this.customMaxDate = val
      },
      immediate: true
    },
    datetime: {
      handler: function (val) {
        if (this.type === 'date') {
          let formatted = val
            ? moment(val, this.valueFormat).format('YYYY-MM-DD 00:00:00')
            : null
          this.$emit('input', formatted)
        } else this.$emit('input', val)
      },
      immediate: true
    }
  },
  methods: {
    done() {
      this.menu = false
      this.$emit('input', this.datetime)
    }
  }
}
</script>

<style lang="scss">
.kirby-active-time {
  background: $blue;
  color: white !important;
}
</style>
