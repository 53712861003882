var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',_vm._b({staticClass:"__editor rounded-10",class:{ 'has-error': _vm.hasError, editable: _vm.editable },attrs:{"flat":""}},'v-card',_vm.$attrs,false),[(['top', 'both'].includes(_vm.toolPosition) && _vm.editable && _vm.editor)?_c('v-card-title',{staticClass:"py-1"},[_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"menubar__button",attrs:{"tile":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-cog")])],1)]}}],null,false,2091637432)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.toolPositions),function(item,i){return _c('v-list-item',{key:i,staticClass:"px-3",class:{
            'light--text primary': item.value === _vm.toolPosition
          },on:{"click":function($event){_vm.toolPosition = item.value}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),1)],1),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"menubar__button",attrs:{"tile":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-format-size")])],1)]}}],null,false,1219715256)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.fontSizes),function(item,i){return _c('v-list-item',{key:i,staticClass:"px-3",class:{
            'light--text primary': _vm.editor.isActive('heading', {
              level: item.level
            })
          },on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: item.level }).run()}}},[_c('v-list-item-title',[_c('span',{style:(("font-size:" + (item.fSize)))},[_vm._v(_vm._s(item.label))])])],1)}),1)],1),_vm._l((_vm.commandList),function(command){return [_c('HelpToolTip',{key:command.icon,attrs:{"icon-class":"mx-1","text":command.label,"icon":command.icon,"disabled":command.hasOwnProperty('disabled') ? command.disabled() : false,"icon-color":command.active() ? 'primary' : 'secondary'},on:{"click:icon":function($event){return command.method()}}})]}),(!_vm.disabledTable)?[_vm._l((_vm.tableList),function(command){return [(command.active())?_c('HelpToolTip',{key:command.icon,attrs:{"icon-class":"mx-1","text":command.label,"icon":command.icon,"disabled":!command.active(),"icon-color":"secondary"},on:{"click:icon":function($event){return command.method()}}}):_vm._e()]})]:_vm._e()],2):_vm._e(),(_vm.editor)?_c('v-card-text',{staticClass:"ma-0 pa-0 d-flex align-start flex-column fullwidth justify-start",on:{"click":function($event){$event.stopPropagation();_vm.editor.chain().focus()}}},[_c('EditorContent',{staticClass:"editor-wrapper",style:(("min-height:" + _vm.minHeight)),attrs:{"editor":_vm.editor}})],1):_vm._e(),(['bottom', 'both'].includes(_vm.toolPosition) && _vm.editor && _vm.editable)?_c('v-card-title',{staticClass:"py-1"},[_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"menubar__button",attrs:{"tile":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-cog")])],1)]}}],null,false,2091637432)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.toolPositions),function(item,i){return _c('v-list-item',{key:i,staticClass:"px-3",class:{
            'light--text primary': item.value === _vm.toolPosition
          },on:{"click":function($event){_vm.toolPosition = item.value}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),1)],1),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"menubar__button",attrs:{"tile":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-format-size")])],1)]}}],null,false,1219715256)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.fontSizes),function(item,i){return _c('v-list-item',{key:i,staticClass:"px-3",class:{
            'light--text primary': _vm.editor.isActive('heading', {
              level: item.level
            })
          },on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: item.level }).run()}}},[_c('v-list-item-title',[_c('span',{style:(("font-size:" + (item.fSize)))},[_vm._v(_vm._s(item.label))])])],1)}),1)],1),_vm._l((_vm.commandList),function(command){return [_c('HelpToolTip',{key:command.icon,attrs:{"icon-class":"mx-1","text":command.label,"icon":command.icon,"disabled":command.hasOwnProperty('disabled') ? command.disabled() : false,"icon-color":command.active() ? 'primary' : 'secondary'},on:{"click:icon":function($event){return command.method()}}})]}),(!_vm.disabledTable)?[_vm._l((_vm.tableList),function(command){return [(command.active())?_c('HelpToolTip',{key:command.icon,attrs:{"icon-class":"mx-1","text":command.label,"icon":command.icon,"disabled":!command.active(),"icon-color":"secondary"},on:{"click:icon":function($event){return command.method()}}}):_vm._e()]})]:_vm._e()],2):_vm._e(),(_vm.hasError && _vm.required)?_c('v-card-actions',{staticClass:"error--text fs-12 fw-500"},[_vm._v(" This field is required ")]):_vm._e(),_c('ImgModal',{attrs:{"result-mode":"url"},on:{"file":_vm.handleImageUploaded},model:{value:(_vm.image_modal),callback:function ($$v) {_vm.image_modal=$$v},expression:"image_modal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }