<template>
  <v-avatar
    tile
    :height="height"
    :width="width"
    v-if="image_exist"
    v-bind="$attrs" 
  >
    <v-img
      :src="image_url"
      contain
      class="cursor-pointer"
      @error="image_exist = false"
    />
  </v-avatar>
  <v-avatar
    :height="height"
    :width="width"
    v-else
    tile
    v-bind="$attrs" 
  >
    <span
      class="fiv-icon fiv-sqo fiv-size-xl v-icon"
      v-if="attachment.file_ext"
      :class="[`fiv-icon-` + attachment.file_ext]"
    ></span>
    <v-icon color="error" v-else x-large>mdi-image-off-outline</v-icon>
  </v-avatar>
</template>

<style lang="scss" scoped>
.default-img-wrapper {
  display: grid;
  height: 100%;

  .fiv-icon {
    margin: auto;
    color: #fff;
  }
}
</style>
<script>
export default {
  inheritAttrs: false,
  name: "Media",
  props: {
    attachment: Object,
    height: { type: [Number, String], default: "50px" },
    width: { type: [Number, String], default: "50px" },
  },
  computed: {
    image_exist: {
      get() {
        return (
          this.attachment &&
          this.attachment.thumbnail &&
          this.attachment.thumbnail.trim() !== ""
        );
      },
      set(val) {
        //
      },
    },
    image_url() {
      if (
        this.attachment &&
        this.attachment.thumbnail === "https://www.google.com/favicon.ico"
      ) {
        return "https://www.iconfinder.com/data/icons/social-attachment-2210/24/Google-512.png";
      }
      return this.attachment ? this.attachment.thumbnail : false;
    },
  },
};
</script>
