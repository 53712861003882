import auth from '../middleware/auth'
import is_module_enabled from "../middleware/is_module_enabled";

export const bugs = {
    path: 'bugs',
    name: 'bugs',
    component: () => import('@/modules/Bugs/Bugs.vue'),
    meta: {
        middleware: [auth, is_module_enabled]
    }
}
