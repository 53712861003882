import { settings } from '@/variables'
import getVideoId from 'get-video-id'

export const global_utils = {
    methods: {
        myTz() {
            return Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC'
        },
        scrollToBottom(e) {
            this.$nextTick(() => {
                if (typeof e !== 'undefined') {
                    e.scrollTop = e.scrollHeight - e.getBoundingClientRect().height
                }
            })
        },
        addHost(urlString) {
            if (!urlString || typeof urlString === 'undefined') {
                return settings.apiHostBaseURL
            }
            if (!(
                    urlString.indexOf('http://') === 0 ||
                    urlString.indexOf('https://') === 0
                )) {
                return settings.apiHostBaseURL + urlString
            }
            return urlString
        },
        altImage(item) {
            if (
                item &&
                item.hasOwnProperty('collection_name') &&
                item.collection_name === 'project.files.videos'
            ) {
                item.thumb_url = require('@/assets/temp/no-video-preview.png')
                item.public_url = require('@/assets/temp/no-video-preview.png')
            } else {
                item.thumb_url = require('@/assets/temp/no-image.jpg')
                item.public_url = require('@/assets/temp/no-image.jpg')
            }
        },
        generate_random(qty, min, max) {
            const items = []
            for (let i = 0; i < qty; i++) {
                items.push(parseInt((Math.random() * (max - min) + min).toFixed(0)))
            }
            return items
        },
        youtubeParser(url) {
            const result = getVideoId(url)
            return result.service === 'youtube' ? result.id : null
        },
        vimeoParser(url) {
            const result = getVideoId(url)
            return result.service === 'vimeo' ? result.id : null
        },
        slugify(text, separator) {
            text = text.toString().toLowerCase().trim()
            const sets = [
                { to: 'a', from: '[ÀÁÂÃÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]' },
                { to: 'ae', from: '[Ä]' },
                { to: 'c', from: '[ÇĆĈČ]' },
                { to: 'd', from: '[ÐĎĐÞ]' },
                { to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]' },
                { to: 'g', from: '[ĜĞĢǴ]' },
                { to: 'h', from: '[ĤḦ]' },
                { to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]' },
                { to: 'j', from: '[Ĵ]' },
                { to: 'ij', from: '[Ĳ]' },
                { to: 'k', from: '[Ķ]' },
                { to: 'l', from: '[ĹĻĽŁ]' },
                { to: 'm', from: '[Ḿ]' },
                { to: 'n', from: '[ÑŃŅŇ]' },
                { to: 'o', from: '[ÒÓÔÕØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]' },
                { to: 'oe', from: '[ŒÖ]' },
                { to: 'p', from: '[ṕ]' },
                { to: 'r', from: '[ŔŖŘ]' },
                { to: 's', from: '[ŚŜŞŠ]' },
                { to: 'ss', from: '[ß]' },
                { to: 't', from: '[ŢŤ]' },
                { to: 'u', from: '[ÙÚÛŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]' },
                { to: 'ue', from: '[Ü]' },
                { to: 'w', from: '[ẂŴẀẄ]' },
                { to: 'x', from: '[ẍ]' },
                { to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
                { to: 'z', from: '[ŹŻŽ]' },
                { to: '-', from: "[·/_,:;']" }
            ]
            sets.forEach((set) => {
                text = text.replace(new RegExp(set.from, 'gi'), set.to)
            })
            text = text
                .toString()
                .toLowerCase()
                .replace(/\s+/g, '-') // Replace spaces with -
                .replace(/&/g, '-and-') // Replace & with 'and'
                .replace(/[^\w\-]+/g, '') // Remove all non-word chars
                .replace(/\--+/g, '-') // Replace multiple - with single -
                .replace(/^-+/, '') // Trim - from start of text
                .replace(/-+$/, '') // Trim - from end of text

            if (typeof separator !== 'undefined' && separator !== '-') {
                text = text.replace(/-/g, separator)
            }
            return text
        },
        validateEmail(email) {
            const regExp =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return regExp.test(String(email).toLowerCase())
        },
        validateUrl(url) {
            let pattern = new RegExp(
                    '^(https?:\\/\\/)?' + // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                    '(\\#[-a-z\\d_]*)?$',
                    'i'
                ) // fragment locator
            return !!pattern.test(url)
        },
        getOrdinalNum(number) {
            return (
                number +
                (number > 0 ?
                    ['th', 'st', 'nd', 'rd'][
                        (number > 3 && number < 21) || number % 10 > 3 ? 0 : number % 10
                    ] :
                    '')
            )
        },
        weekdays() {
            return [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday'
            ]
        },
        calendarMonths() {
            return [
                'January',
                'February',
                'March',
                'April',
                'May',
                'Jun',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ]
        },
        snakeCaseToNormal(text) {
            return text.split('_').join(' ')
        },
        makeid(length) {
            let result = []
            let characters =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            let charactersLength = characters.length
            for (let i = 0; i < length; i++) {
                result.push(
                    characters.charAt(Math.floor(Math.random() * charactersLength))
                )
            }
            return result.join('')
        },
        joinHumanReadable(value, connector = 'and') {
            if (!value || !value.length) {
                return ''
            }
            if (value.length === 1) {
                return value[0]
            }
            var lastStr = value.pop()
            return value.join(', ') + ' ' + connector + ' ' + lastStr
        }
    }
}