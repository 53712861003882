import { Node, mergeAttributes } from '@tiptap/core'

export default Node.create({
    name: 'ExternalVideo',

    group() {
        return 'block'
    },
    draggable: true,
    addOptions() {
        return {
            crossorigin: 'anonymous',
            allowFullscreen: true,
            HTMLAttributes: {
                class: 'yt-iframe d-block',
                style: 'text-align:center;max-height:400px;'
            }
        }
    },
    addAttributes: function() {
        return {
            src: {
                default: null
            },
            frameborder: {
                default: 0
            },
            width: {
                default: 300
            },
            height: {
                default: 400
            }
        }
    },
    parseHTML: function() {
        return [{
            tag: 'iframe'
        }]
    },
    renderHTML({ HTMLAttributes }) {
        return ['div', this.options.HTMLAttributes, ['iframe', HTMLAttributes]]
    },
    addCommands() {
        return {
            setExternalVideo:
                (options) =>
                ({ tr, dispatch }) => {
                    const { selection } = tr
                    const node = this.type.create(options)
                    const position = selection.$cursor ?
                        selection.$cursor.pos :
                        selection.$to.pos
                    const transaction = tr.insert(position, node)
                    dispatch(transaction)
                    return true
                }
        }
    }
})