export const initial_state = () => ({
    app_modules: [],
    plans: [],
    payment_method: null,
    main_selected: null,
    addons_selected: [],
    is_company_subscribed: false,
    is_company_exempted: false,
    is_company_on_trial: false,
    current_main_subscription: null,
    current_addons_subscriptions: [],
    smsmms_credits: {
        balance: 0.00,
        last_recharge_date: null,
        last_recharge_amount: null
    },
    email_credits: {
        balance: 0.00,
        last_recharge_date: null,
        last_recharge_amount: null
    }
})