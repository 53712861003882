<template>
  <v-app class="buzzooka" id="app">
    <CustomLoader></CustomLoader>

    <router-view></router-view>

    <AttachmentSoloViewer
      :attachment="attachment"
      v-model="attachment_dialog"
    ></AttachmentSoloViewer>

    <snackbar></snackbar>
    <AlarmBottomSheet></AlarmBottomSheet>
    <TimerConfirmationBottomSheet></TimerConfirmationBottomSheet>
    <ConfirmationRequiredDialog></ConfirmationRequiredDialog>
  </v-app>
</template>
<script>
import snackbar from './common/snackbar/snackbar.vue'
import CustomLoader from './common/CustomLoader.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AttachmentSoloViewer from './common/Viewer/AttachmentSoloViewer'
import DeleteDialog from '@/common/DeleteDialog'
import ConfirmationRequiredDialog from './common/Dialogs/ConfirmationRequiredDialog.vue'
import TimerConfirmationBottomSheet from './common/Global/TimerConfirmationBottomSheet.vue'
import AlarmBottomSheet from './common/Global/AlarmBottomSheet.vue'

export default {
  name: 'App',
  components: {
    DeleteDialog,
    AttachmentSoloViewer,
    snackbar,
    CustomLoader,
    ConfirmationRequiredDialog,
    TimerConfirmationBottomSheet,
    AlarmBottomSheet
  },
  data: () => ({
    attachment: null,
    attachment_dialog: false
  }),
  computed: {
    ...mapGetters(['user', 'users'])
  },
  created() {
    document.title = this.settings.SiteName
    this.initialiseStore()
    this.init_dashStore()
    if (this.user) {
      this.$store.dispatch('configs/fetchSettings', this.user.company_id)
    }
    if (this.user && !this.users.length) {
      this.$store.dispatch('fetchUsers')
    }
    this.listenOnEvents()
  },
  mounted() {
    if (this.user) {
      this.getCurrentSubscriptions()
    }
  },
  methods: {
    ...mapMutations(['initialiseStore']),
    ...mapMutations('dashitems', ['init_dashStore']),
    ...mapActions('onlineUsers', ['get_conversation_list']),
    ...mapActions('subscription', ['getCurrentSubscriptions']),
    listenOnEvents() {
      this.$event.$on('view-attachment', (attachment) => {
        this.attachment = attachment
        this.attachment_dialog = true
      })
      this.$event.$on('user-logout', () => {
        this.confirm_logout_dialog = true
        this.appConfirmation(
          `Logout from ${this.settings.SiteName}?`,
          () => {
            this.$auth.logout(
              (response) => {
                window.location = this.settings.BaseURL + '/login'
              },
              (error) => {
                this.appSnackbar(error, 'error')
              }
            )
          },
          null,
          'Yes logout'
        )
      })
    }
  }
}
</script>
<style lang="scss">
.buzzooka.v-application {
  .v-text-field {
    border-radius: 10px;
  }
  .primary--text {
    color: #223a6b !important;
  }
  .light--text {
    color: #fff !important;
  }
  .darken--text {
    color: #03104b !important;
  }
  .v-btn--disabled.theme--dark {
    color: grey !important;
    pointer-events: none;
    cursor: not-allowed;
  }
  .v-dialog__content {
    z-index: 1001 !important;
  }
  .v-overlay.v-overlay--active {
    z-index: 1000 !important;
  }
  .above-overlay {
    z-index: 1001;
  }
  .v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
  }
  .radio-primary--text {
    label {
      color: $blue !important;
      font-weight: 500;
    }
  }
  .checkbox-primary--text {
    label {
      color: $blue !important;
      font-weight: 500;
    }
  }
}
.text-cap {
  text-transform: capitalize;
}

.textarea-cap::first-letter {
  text-transform: uppercase;
}
</style>
