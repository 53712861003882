<template>
  <v-overlay absolute :opacity="0.5" :value="show">
    <v-progress-circular color="primary" indeterminate size="100">
      <v-avatar tile size="50">
        <v-img
          contain
          :lazy-src="settings.loader"
          :src="settings.squareLogo"
        ></v-img>
      </v-avatar>
    </v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'OverlayLoader',
  props: {
    value: { type: Boolean, default: true }
  },
  data: () => ({
    show: false
  }),
  watch: {
    show(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.show = val
    }
  }
}
</script>

<style></style>
