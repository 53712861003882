import auth from '../middleware/auth'

export const template_builder = {
    path: 'template-design',
    name: 'template_design',
    component: () => import('@/modules/OffersOrders/TemplateDesign.vue'),
    meta: {
        middleware: [auth]
    }
}
