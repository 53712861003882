export const initial_state = () => ({
    activeSidebar: {
        title: 'Dashboard',
        icon_name: 'mdi-view-dashboard', //svg string
        icon_type: 'mdi', //svg
        route: {
            name: 'default-content', //null if has children
            params: {},
            query: {}
        },
        children: null
    },
    leftSidebar: true,
    rightSidebar: false,
    connectSidebar: false,
    cardSidebar: false,
    messageSidebar: false,
    notificationSidebar: false,
    collapseWidth: 240,
    miniWidth: 70,
    type: 'Permanent',
    clipped: true,
    floating: false,
    mini: true,
    hasFooter: false,
    sidebars: [{
            title: 'Dashboard',
            icon_name: 'mdi-view-dashboard', //svg string
            icon_type: 'mdi', //svg
            route: {
                name: 'default-content', //null if has children
                params: {},
                query: {}
            },
            module: 'dashboard',
            access: ['super-admin', 'owner', 'admin', 'manager', 'client', 'member'],
            children: null
        },
        {
            title: 'Services',
            icon_name: 'mdi-room-service',
            icon_type: 'mdi',
            route: {
                name: 'services-list',
                params: {},
                query: {}
            },
            module: null,
            subscribedOn: [],
            access: ['super-admin', 'owner', 'admin', 'manager', 'member'],
            children: null
        },
        {
            title: 'Projects',
            icon_name: 'mdi-briefcase',
            icon_type: 'mdi',
            route: null,
            module: null,
            access: ['super-admin', 'owner', 'admin', 'manager', 'client', 'member'],
            children: [{
                    title: 'Business Projects',
                    icon_name: 'mdi-briefcase-account',
                    icon_type: 'mdi',
                    route: {
                        name: 'projects',
                        params: { type: 'business' },
                        query: {}
                    },
                    module: 'projects'
                },
                {
                    title: 'Personal Projects',
                    icon_name: 'mdi-briefcase-download',
                    icon_type: 'mdi',
                    route: {
                        name: 'projects',
                        params: { type: 'personal' },
                        query: {}
                    },
                    module: 'projects'
                }
            ]
        },
        {
            title: 'Campaigns',
            icon_name: 'mdi-google-classroom',
            icon_type: 'mdi',
            route: null,
            module: null,
            access: ['super-admin', 'owner', 'admin', 'manager', 'member'],
            children: [{
                    title: 'Email Campaign',
                    icon_name: 'mdi-email-variant',
                    icon_type: 'mdi',
                    route: {
                        name: 'campaigns',
                        params: { type: 'email' },
                        query: {}
                    },
                    module: 'email_campaigns'
                },
                {
                    title: 'SMS/MMS Campaign',
                    icon_name: 'mdi-cellphone-message',
                    icon_type: 'mdi',
                    route: {
                        name: 'campaigns',
                        params: { type: 'sms' },
                        query: {}
                    },
                    module: 'sms_mms_campaigns'
                }
            ]
        },
        {
            title: 'Clients',
            icon_name: 'mdi-shield-account',
            icon_type: 'mdi',
            route: {
                name: 'clients',
                params: {},
                query: {}
            },
            module: 'clients',
            access: ['super-admin', 'owner', 'admin', 'manager'],
            children: null
        },
        {
            title: 'Leads',
            icon_name: 'mdi-target-account',
            icon_type: 'mdi',
            route: {
                name: 'leads',
                params: {},
                query: {}
            },
            module: 'leads',
            access: ['super-admin', 'owner', 'admin', 'manager', 'member'],
            children: null
        },
        {
            title: 'Chat',
            icon_name: 'mdi-chat',
            icon_type: 'mdi',
            route: {
                name: 'chat',
                params: {},
                query: {}
            },
            module: 'chat',
            access: ['super-admin', 'owner', 'admin', 'manager', 'client', 'member'],
            children: null
        },
        {
            title: 'Calendar',
            icon_name: 'mdi-calendar-month',
            icon_type: 'mdi',
            route: {
                name: 'calendar',
                params: {},
                query: {}
            },
            module: 'calendars',
            access: ['super-admin', 'owner', 'admin', 'manager', 'client', 'member'],
            children: null
        },
        {
            title: 'Offers',
            icon_name: 'mdi-shopping',
            icon_type: 'mdi',
            route: {
                name: 'offers-home',
                params: {},
                query: {}
            },
            module: 'offers',
            access: ['super-admin', 'owner', 'admin', 'manager', 'client', 'member'],
            children: null
        },
        {
            title: 'Templates',
            icon_name: 'mdi-clipboard-text-multiple',
            icon_type: 'mdi',
            route: {
                name: 'templates',
                params: {},
                query: {}
            },
            module: 'templates',
            access: ['super-admin', 'owner', 'admin', 'manager', 'member'],
            children: null
        },
        {
            title: 'Forms',
            icon_name: 'mdi-clipboard-list',
            icon_type: 'mdi',
            route: {
                name: 'forms',
                params: {},
                query: {}
            },
            access: ['super-admin', 'owner', 'admin', 'manager', 'member'],
            children: null
        },
        {
            title: 'Invoices',
            icon_name: 'mdi-cash-register',
            icon_type: 'mdi',
            route: {
                name: 'invoice',
                params: {},
                query: {}
            },
            module: 'invoices',
            access: ['super-admin', 'owner', 'admin', 'manager', 'client', 'member'],
            children: null
        },
        {
            title: 'Timers',
            icon_name: 'mdi-av-timer',
            icon_type: 'mdi',
            route: null,
            module: null,
            access: ['super-admin', 'owner', 'admin', 'manager', 'member'],
            children: [{
                    title: 'Task Timers',
                    icon_name: 'mdi-folder-clock-outline',
                    icon_type: 'mdi',
                    route: {
                        name: 'taskTimer',
                        params: {},
                        query: {}
                    },
                    module: 'task_timers'
                },
                {
                    title: 'Global Timers',
                    icon_name: 'mdi-account-clock',
                    icon_type: 'mdi',
                    route: {
                        name: 'globalTimer',
                        params: {},
                        query: {}
                    },
                    module: 'user_timers'
                }
            ]
        },
        {
            title: 'Team',
            icon_name: 'mdi-account-group',
            icon_type: 'mdi',
            route: {
                name: 'team',
                params: {},
                query: {}
            },
            module: 'team',
            access: ['super-admin', 'owner', 'admin', 'manager', 'member'],
            children: null
        },
        {
            title: 'Support',
            icon_name: 'mdi-headset-dock',
            icon_type: 'mdi',
            route: {
                name: 'support',
                params: {},
                query: {}
            },
            module: 'support',
            access: ['super-admin', 'owner', 'admin', 'manager', 'client', 'member'],
            children: null
        },
        {
            title: 'Training',
            icon_name: 'mdi-book-education-outline',
            icon_type: 'mdi',
            route: {
                name: 'courses',
                params: {},
                query: {}
            },
            module: 'courses',
            access: ['super-admin', 'owner', 'admin', 'manager', 'client', 'member'],
            children: null
        }
    ],
    user_navigations: [{
            title: 'Profile',
            module: null,
            icon_name: 'mdi-account-box', //svg string
            icon_type: 'mdi', //svg
            route: {
                name: 'profile', //null if has children
                params: { username: '' },
                query: {}
            },
            access: ['owner', 'admin', 'manager', 'member', 'client'],
            children: null
        },
        {
            title: 'Wallet',
            icon_name: 'mdi-wallet', //svg string
            icon_type: 'mdi', //svg
            route: {
                name: 'wallet-history', //null if has children
                params: {},
                query: {}
            },
            access: ['owner', 'admin', 'manager', 'member', 'client'],
            children: null,
            module: null
        },
        {
            title: 'Settings',
            icon_name: 'mdi-cog', //svg string
            icon_type: 'mdi', //svg
            route: {
                name: 'settings', //null if has children
                params: {},
                query: {}
            },
            module: 'settings',
            access: ['admin', 'manager'],
            children: null
        },
        {
            title: 'Tasks',
            icon_name: 'mdi-clipboard-flow-outline', //svg string
            icon_type: 'mdi', //svg
            route: {
                name: 'my-tasks', //null if has children
                params: {},
                query: {}
            },
            module: null,
            access: ['owner', 'admin', 'manager', 'member', 'client'],
            children: null
        },
        {
            title: 'Notes',
            icon_name: 'mdi-notebook-outline', //svg string
            icon_type: 'mdi', //svg
            route: {
                name: 'notes', //null if has children
                params: {},
                query: {}
            },
            module: 'notes',
            access: ['owner', 'admin', 'manager', 'member', 'client'],
            children: null
        },
        {
            title: 'Admin Area',
            icon_name: 'mdi-progress-wrench', //svg string
            icon_type: 'mdi', //svg
            route: {
                name: 'admin-dashboard', //null if has children
                params: {},
                query: {}
            },
            module: null,
            access: ['super-admin'],
            children: null
        },
        {
            title: 'Logout',
            module: null,
            icon_name: 'mdi-logout', //svg string
            icon_type: 'mdi', //svg
            route: null,
            access: ['owner', 'admin', 'manager', 'member', 'client'],
            children: null
        }
    ],
    connects: [{
            id: 'google_drive',
            logo: require('@/assets/appIcons/gdrive.png'),
            longLogo: require('@/assets/logo/gdrive.png'),
            name: 'Google Drive',
            available: true,
            connected: false,
            onDevelopment: false,
            btnLabel: 'Connect with Google Drive',
            description: '',
            show: false
        },
        {
            id: 'stripe',
            logo: require('@/assets/appIcons/stripe-icon.svg').default,
            longLogo: require('@/assets/logo/stripe-logo-white-stripe-icon.png'),
            name: 'Stripe',
            available: true,
            connected: false,
            onDevelopment: false,
            btnLabel: 'Connect with Stripe',
            description: '',
            show: false
        },
        {
            id: 'mobilemonkey',
            logo: require('@/assets/appIcons/mobilemonkey-square.png'),
            longLogo: require('@/assets/logo/mobilemonkey.png'),
            name: 'MobileMonkey',
            available: true,
            connected: false,
            onDevelopment: false,
            btnLabel: 'Setup MobileMonkey',
            description: '',
            show: false
        },
        {
            id: 'livechat',
            logo: require('@/assets/appIcons/livechat-square.png'),
            longLogo: require('@/assets/logo/livechat.png'),
            name: 'LiveChat',
            available: true,
            connected: false,
            onDevelopment: true,
            btnLabel: 'Connect with LiveChat',
            description: '',
            show: false
        },
        {
            id: 'canva',
            logo: require('@/assets/appIcons/canva-favicon.png'),
            longLogo: require('@/assets/logo/canva.png'),
            name: 'Canva',
            available: true,
            connected: false,
            onDevelopment: true,
            btnLabel: 'Connect with Canva',
            description: '',
            show: false
        },
        {
            id: 'paypal',
            logo: require('@/assets/appIcons/paypal-favicon.png'),
            longLogo: require('@/assets/logo/paypal-png-logo.png'),
            name: 'Paypal',
            available: false,
            connected: false,
            onDevelopment: true,
            btnLabel: 'Connect with Paypal',
            description: '',
            show: false
        },
        {
            id: 'dropbox',
            logo: require('@/assets/appIcons/dropbox-icon.svg').default,
            longLogo: require('@/assets/logo/dropbox-logo-white-80.png'),
            name: 'Dropbox',
            available: false,
            connected: false,
            onDevelopment: false,
            btnLabel: 'Connect with Dropbox',
            description: '',
            show: false
        },
        {
            id: 'google_meet',
            logo: require('@/assets/appIcons/google-meet-icon.svg').default,
            longLogo: require('@/assets/logo/google-meet-seeklogo.com.svg').default,
            name: 'Google Meet',
            available: false,
            connected: false,
            onDevelopment: false,
            btnLabel: 'Connect with Google Meet',
            description: '',
            show: false
        },
        {
            id: 'zoom',
            logo: require('@/assets/appIcons/zoom-logo-icon.svg').default,
            longLogo: require('@/assets/logo/zoom-white-logo.png'),
            name: 'Zoom',
            available: false,
            connected: false,
            onDevelopment: false,
            btnLabel: 'Connect with Zoom',
            description: '',
            show: false
        },
        {
            id: 'seo_profiler',
            logo: require('@/assets/appIcons/seoprofiler-icon.png'),
            longLogo: require('@/assets/logo/seoprofiler-logo-black-shine.png'),
            name: 'SEOprofiler',
            available: false,
            connected: false,
            onDevelopment: false,
            btnLabel: 'Connect with SEOprofiler',
            description: '',
            show: false
        },
        {
            id: 'semrush',
            logo: require('@/assets/appIcons/semrush-favicon.png'),
            longLogo: require('@/assets/logo/SEMrush-Logo.png'),
            name: 'SEMrush',
            available: false,
            connected: false,
            onDevelopment: false,
            btnLabel: 'Connect with SEMrush',
            description: '',
            show: false
        },
        {
            id: 'brightlocal',
            logo: require('@/assets/appIcons/brightlocal-favicon.png'),
            longLogo: require('@/assets/logo/brightlocal_logo.svg').default,
            name: 'BrightLocal',
            available: false,
            connected: false,
            onDevelopment: false,
            btnLabel: 'Connect with BrightLocal',
            description: '',
            show: false
        },
        {
            id: 'google_calendar',
            logo: require('@/assets/appIcons/google-calendar-icon.png'),
            longLogo: require('@/assets/logo/google-calendar.png'),
            name: 'Google Calendar',
            available: true,
            connected: false,
            onDevelopment: false,
            btnLabel: 'Connect with Google Calendar',
            description: '',
            show: false
        },
        {
            id: 'twilio',
            logo: require('@/assets/appIcons/logo-twilio-mark red.png'),
            longLogo: require('@/assets/appIcons/logo-twilio-red.png'),
            name: 'Twilio',
            available: true,
            connected: false,
            onDevelopment: false,
            btnLabel: 'Setup Twilio',
            description: '',
            show: false
        },
        {
            id: 'mailgun',
            logo: require('@/assets/mailers/mailgun-square.png'),
            longLogo: require('@/assets/mailers/mailgun.png'),
            name: 'Mailgun',
            available: true,
            connected: false,
            onDevelopment: false,
            btnLabel: 'Setup Mailgun',
            description: '',
            show: false
        },
        {
            id: 'mailchimp',
            logo: require('@/assets/mailers/mailchimp-square.png'),
            longLogo: require('@/assets/mailers/mailchimp.png'),
            name: 'MailChimp',
            available: true,
            connected: false,
            onDevelopment: false,
            btnLabel: 'Setup Mailchimp',
            description: '',
            show: false
        },
        {
            id: 'mailtrap',
            logo: require('@/assets/mailers/mailtrap-square.png'),
            longLogo: require('@/assets/mailers/mailtrap.png'),
            name: 'Mailtrap',
            available: true,
            connected: false,
            onDevelopment: false,
            btnLabel: 'Setup Mailtrap',
            description: '',
            show: false
        },
        {
            id: 'postmark',
            logo: require('@/assets/mailers/postmark-square.png'),
            longLogo: require('@/assets/mailers/postmark.svg').default,
            name: 'Postmark',
            available: true,
            connected: false,
            onDevelopment: false,
            btnLabel: 'Setup Postmark',
            description: '',
            show: false
        },
        {
            id: 'sendgrid',
            logo: require('@/assets/mailers/sendgrid-square.png'),
            longLogo: require('@/assets/mailers/sendgrid.svg').default,
            name: 'Twilio SendGrid Email',
            available: true,
            connected: false,
            onDevelopment: false,
            btnLabel: 'Setup Mailgun',
            description: '',
            show: false
        },
        {
            id: 'smtp2go',
            logo: require('@/assets/mailers/smtp2go-square.jpg'),
            longLogo: require('@/assets/mailers/smtp2go.svg').default,
            name: 'SMTP2GO',
            available: true,
            connected: false,
            onDevelopment: false,
            btnLabel: 'Setup SMTP2GO',
            description: '',
            show: false
        }
    ],
    allowed_connects: [],
    allowed_modules: []
})